// Uncomment line 2 after logging is sorted out
// import { LoggingWinston } from "@google-cloud/logging-winston";

const { createLogger, format, transports } = require("winston");

/**
 *  https://cloud.google.com/logging/docs/setup/nodejs#using_winston
 * https://github.com/winstonjs/winston#creating-your-own-logger
 *
 * */
const logger = createLogger({
  level: "info",
  format: format.json(),
  transports: [
    // console logging
    new transports.Console(),
    // Add Stackdriver Logging, Logs will be written to: "projects/YOUR_PROJECT_ID/logs/winston_log"
    // new LoggingWinston(),
  ],
});
export default logger;
