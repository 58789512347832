import { Note } from "../types/note";
import logger from "../utils/logger";
import { databaseRef } from "./firebase";

export default class NoteStore {
  path: string;

  constructor() {
    this.path = "note";
  }

  async saveNote(note: Note): Promise<boolean> {
    let isSaved = false;
    try {
      await databaseRef.child(`${this.path}/${note.sessionId}`).update(note);

      isSaved = true;
    } catch (err) {
      logger.error(err.message);
    }
    return isSaved;
  }

  async getNote(sessionId: string): Promise<Note | null> {
    const noteDoc = await databaseRef
      .child(`${this.path}/${sessionId}`)
      .once("value");
    if (noteDoc.exists()) {
      return noteDoc.val() as Note;
    }
    return null;
  }

  async getAllNotes(): Promise<Note[]> {
    let notes: Note[] = [];
    try {
      const noteDoc = await databaseRef.child(`${this.path}`).once("value");
      if (noteDoc.exists()) {
        notes = noteDoc.val() as Array<Note>;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return notes;
  }

  async getProviderNotes(hashedProviderId: string): Promise<Note[]> {
    let notes: Note[] = [];
    try {
      const noteDoc = await databaseRef
        .child(`${this.path}`)
        .orderByChild("providerId")
        .equalTo(hashedProviderId)
        .once("value");
      if (noteDoc.exists()) {
        notes = noteDoc.val() as Array<Note>;
      }
    } catch (err) {
      logger.console.error(err.message);
    }

    return notes;
  }
}
