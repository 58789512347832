import { validate } from "email-validator";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { convertToTz, getCurrentDateTime } from "../../utils/date-helper";
import medconLogo from "../../assets/images/medcon-logo.png";
import sideImage1 from "../../assets/images/sideImage1.png";
import { firebaseAuth } from "../../store/firebase";
import PatientStore from "../../store/patient";
import { Provider } from "../../types/provider";
import { TimeZone } from "../../types/enum";
import { getUserUniqueId } from "../../utils/hash";
import {
  initializePendo,
  initializeFullStory,
} from "../../utils/web-analytics";
import { BackButton } from "../elements/BackButton";
import { InputComponent } from "../elements/InputComponent";
import { Loader } from "../elements/Loader";
import { OnBoardingTopText } from "../elements/OnBoardingTopText";
import { PrimaryButtonComponent } from "../elements/PrimaryButtonComponent";
import { TextInput } from "../elements/TextInput";
import { Validation } from "../elements/Validation";
import { OnBoardingScaffold } from "../layout/OnBoardingScaffold";

declare const window: any;
declare const document: any;

function parsePatientDate(dateStr: string) {
  let parsedDate: moment.Moment | null = null;
  parsedDate = moment(dateStr, "MM/DD/YYYY", true);
  if (!parsedDate.isValid()) {
    parsedDate = moment(dateStr, "M/D/YYYY", true);
  }
  return parsedDate;
}

export const PatientSignIn = () => {
  const history = useHistory();

  const [patientEmail, setPatientEmail] = useState("");
  const [patientDob, setPatientDob] = useState("");
  const [dateFormat] = useState("MM/DD/YYYY");

  const [emailValidation, showEmailValidation] = useState(false);
  const [dobValidation, showDobValidation] = useState(false);
  const [loader, showLoader] = useState(false);

  const clearValidation = () => {
    showEmailValidation(false);
    showDobValidation(false);
    showLoader(true);
  };

  const handleClick = async () => {
    clearValidation();

    if (!patientEmail || !validate(patientEmail.trim())) {
      showEmailValidation(true);
      showLoader(false);
      return;
    }

    const parsedDate = parsePatientDate(patientDob);
    if (!patientDob || !parsedDate.isValid()) {
      showDobValidation(true);
      showLoader(false);
      return;
    }

    const showError = () => {
      history.push(`/patient/failed`, {
        message: [
          "Please make sure you are attempting to log in on your date of appointment.",
          "If issues persist, first consult your therapist, then, if needed, contact Tech Support.",
          "Allow for 24-48 hours response time for Tech Support inquiries",
        ].join(" "),
        allowBack: true,
      });
    };

    const sanitizedEmail = patientEmail.trim().toLowerCase();
    const [pwd] = moment(patientDob, dateFormat).toISOString().split("T");
    const parsedEmail = `${pwd}_${sanitizedEmail}`;
    firebaseAuth
      .signInWithEmailAndPassword(parsedEmail, pwd)
      .then(() => {
        const patientStore = new PatientStore();
        patientStore
          .getPatientAndProviders(sanitizedEmail, patientDob)
          .then(async (patientAndProviders: any | null) => {
            if (patientAndProviders) {
              const [patient, providers] = patientAndProviders;
              if (providers && patient) {
                const providersWithAppointments: any[] = [];
                providers.forEach((provider: Provider) => {
                  if (provider.appointments) {
                    const providerAppointments: any[] = [];
                    Object.values(provider.appointments).forEach((value) => {
                      Object.values(value).forEach((data) => {
                        // client can login only 4 hours before and up to 1 hour after
                        const appointmentDate = convertToTz(
                          data.appointmentDate,
                          TimeZone.EST
                        );
                        const currentDate = getCurrentDateTime(TimeZone.EST);
                        const difference = currentDate.diff(
                          appointmentDate,
                          "hours"
                        );
                        if (
                          !Number.isNaN(difference) &&
                          difference >= -4 &&
                          difference <= 1 &&
                          data.clientEmail.toLowerCase() === sanitizedEmail
                        ) {
                          providerAppointments.push(data);
                        }
                      });
                    });

                    if (providerAppointments.length >= 1) {
                      providersWithAppointments.push(provider);
                    }
                  }
                });

                if (providersWithAppointments.length >= 1) {
                  const uniqueId = getUserUniqueId();
                  const displayName = `${patient.firstName} ${patient.lastName}`;
                  initializePendo({
                    accountUniqueId: uniqueId,
                    visitorUniqueId: patient!.id,
                    email: patient!.email,
                    displayName,
                  });

                  initializeFullStory({
                    visitorUniqueId: patient!.id,
                    email: patient!.email,
                    displayName,
                  });

                  history.push(`/patient/pay`, {
                    patient,
                    providers: providersWithAppointments,
                  });
                } else {
                  showError();
                }
              } else {
                showError();
              }
            } else {
              showError();
            }
          });
      })
      .catch(() => {
        showError();
      });
  };

  const validateDateValue = (e: any) => {
    const event = e || window.event;
    const charCode =
      typeof event.which === "undefined" ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);
    if (/\d/.test(charStr) || charStr === "/") {
      return true;
    }
    e.preventDefault();
    return false;
  };

  return (
    <>
      <OnBoardingScaffold
        topItem={
          <OnBoardingTopText
            prefixText="Please"
            logo={medconLogo}
            segment="Login"
            botMessage="Kindly enter your information to"
            newLine="access your scheduled appointment."
          />
        }
        sideImage={sideImage1}
      >
        <form
          onSubmit={async (e: any) => {
            e.preventDefault();
          }}
        >
          <InputComponent
            title="EMAIL"
            input={
              <TextInput
                onChange={(e: any) => {
                  setPatientEmail(e.target.value);
                }}
                data-ybug-sensitive
                type="text"
                placeholder="email@example.com"
                onKeyUp={(e: any) => {
                  if (e.keyCode === 13) {
                    document.getElementById("dobInput").focus();
                  }
                }}
              />
            }
            validation={
              <Validation
                text="Please enter valid email address"
                visible={emailValidation}
              />
            }
          />
          <InputComponent
            title={`DATE OF BIRTH (${dateFormat})`}
            input={
              <TextInput
                type="text"
                data-ybug-sensitive
                placeholder={dateFormat}
                onChange={(e: any) => {
                  setPatientDob(e.target.value);
                }}
                maxLength={10}
                onKeyPress={(e: any) => {
                  if (e && e.length === 10) {
                    e.preventDefault();
                    return false;
                  }
                  const isValid = validateDateValue(e);
                  return isValid;
                }}
                onKeyUp={(e: any) => {
                  if (e.keyCode === 13) {
                    handleClick();
                  }
                }}
                id="dobInput"
              />
            }
            validation={
              <Validation
                text="Please enter a date of birth in the format mm/dd/yyyy"
                visible={dobValidation}
              />
            }
          />
          <PrimaryButtonComponent onClick={handleClick} buttonText="Continue" />
          <BackButton />
        </form>
      </OnBoardingScaffold>
      <Loader visible={loader} text="Please wait ... " />
    </>
  );
};
