import React from "react";
import { StyledBlackTd, StyledSpecialFontTd } from "../styles/tableRowStyle";

export const PaymentsReportBody = (props: {
  data: {
    client: string;
    service: string;
    location: string;
    timeStamp: string;
    copay: string;
  };
}) => (
  <tr>
    <StyledBlackTd>{props.data.service}</StyledBlackTd>
    <StyledBlackTd>{props.data.location}</StyledBlackTd>
    <StyledSpecialFontTd className="specialFont">
      {props.data.timeStamp}
    </StyledSpecialFontTd>
    <td>
      <StyledBlackTd>{props.data.copay}</StyledBlackTd>
    </td>
  </tr>
);
