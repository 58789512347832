import { GoogleSquareFilled } from "@ant-design/icons";
import { Avatar, Col, Input, Modal, notification, Row } from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import appSettings from "../../../app-settings";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import blogImage1 from "../../../assets/images/blogImage1.png";
import { MiddleIcon } from "../../../assets/svgs/MiddleIcon";
import { Logo } from "../../../assets/svgs/Logo";
import { theme } from "../../../assets/theme";
import PaymentStore from "../../../store/payment";
import WaitingRoomStore from "../../../store/waiting-room";
import BlogEntry from "../../../types/blog";
import { Payment } from "../../../types/payment";
import {
  WaitingRoom as TWaitingRoom,
  WaitingRoomParticipant,
  WaitingRoomSource,
  WaitingRoomStatus,
} from "../../../types/session";
import { PrimaryButtonComponent } from "../../elements/PrimaryButtonComponent";
import Spinner from "../../elements/Spinner";
import Iframe from "./Iframe";
import WaitingRoomUtils from "./waitingRoomUtils";
import {
  databaseRef,
  firebaseAuth,
} from "../../../store/firebase";
import {
  getMeetingAuthenticationTokenFunction,
} from "../../../store/callableFunctions";
import { ApptValue } from "../../../types/appt-value";
import { AppointmentType } from "../../../types/enum";
import { notificationAudio } from "../../../utils/notification-sound";
import MeetingStore from "../../../store/meeting";
import { Meeting } from "../../../types/meeting";
import CountdownTimer from "./CountdownTimer";

const StyledLeftContainer = styled.section`
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  background-color: ${theme.secondaryColor_asset};
  color: #fff;
  padding-bottom: 100px;
  /* border: 1px solid red; */

  @media ${device.laptop} {
    padding-bottom: 0px;
  }
`;

const StyledRightContainer = styled.section`
  /* display: none; */
  background-color: #fff;
  background-size: cover;
  /* min-height: 100vh; */
  padding: 10px;
  padding-bottom: 20px;
  width: 100%;

  @media ${device.tablet} {
    /*  padding: 30px; */
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 20px;
    width: 100%;
  }

  @media ${device.laptop} {
    display: block;
    /*  min-width: 37.5%; */
    width: 100%;
  }
`;

const StyledCategoryButtonSection = styled.section`
  min-height: 70%;
  padding-top: 10px;
  width: 90%;
  margin: 0 auto;
  /*   border: 1px solid purple; */

  @media ${device.laptop} {
    width: 85%;
    margin: 0;
    margin-left: auto;
  }

  @media ${device.desktopL} {
    padding-top: 20px;
  }
`;

const StyledTopDiv = styled.section`
  /*  min-height: 30%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  width: 85%;
  margin: 0 auto;

  @media ${device.laptop} {
    margin: 0;
    margin-left: auto;
  }
`;

const StyledVideoDiv = styled.section`
  height: 30vh;
  margin-top: 30px;
  width: 100%auto;
  background-color: #122309;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
`;

const StyledHeaderText = styled.h1`
  font-family: ${fontFamily.heading};
  font-weight: 500;
  font-size: 36px;
  color: #fff;
  margin-top: 50px;

  @media ${device.laptop} {
    font-size: 2.4vw;
  }
`;

const StyledNotificationDiv = styled.section`
  width: 100%;
  @media ${device.laptop} {
    width: 88%;
  }

  @media ${device.laptopL} {
    width: 76%;
  }
`;

const StyledHeaderNotification = styled.div`
  width: 100%;
  background-color: #fff0e1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  padding: 35px 0px 35px 0px;
  position: relative;

  @media ${device.tablet} {
    justify-content: flex-start;
  }
`;

const StyledfooterNotification = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-family: ${fontFamily.body};
  padding: 20px;
  border-radius: 0px 0px 8px 8px;

  & h1 {
    font-size: 26.32px;
    font-weight: 600;
    margin: 0px;
  }

  & p {
    font-size: 16px;
    color: ${theme.mutedColor};
    margin-bottom: 40px;
  }

  @media ${device.tablet} {
    padding-top: 4vw;
  }

  @media ${device.laptop} {
    padding-top: 3vw;
  }
`;

const StyledDemacator = styled.div`
  position: absolute;
  bottom: -14%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled(Input)`
  width: 60%;
  margin-left: 20px;
  font-size: 18px !important;
  font-family: ${fontFamily.manrope};
  padding: 10px;
  border-radius: 8px;

  &:focus,
  &:hover {
    border: 1px solid ${theme.primaryColor};
    outline: none;
    box-shadow: none;
  }

  @media ${device.tablet} {
    width: 60%;
  }

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledBlogContainer = styled.div`
  width: 100%;
  box-shadow: 0px 10.886px 21.772px rgba(138, 149, 158, 0.2);
  border-radius: 5.44299px;
  padding: 23px;
  padding-top: 10px;

  & > h1 {
    margin-bottom: 40px;
  }

  & h1 {
    font-size: 18px;
    font-family: ${fontFamily.manrope};
    font-weight: 600;
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.4vw;
    }
  }
`;

const StyledBlogSummary = styled.div`
  display: flex;
  margin-bottom: 30px;
  cursor: pointer;
  justify-content: space-between;
  align-items: flex-start;

  & img {
    width: 100%;
    height: auto;
    border-radius: 5.44299px;
  }

  & > div:first-child {
    flex-basis: 20%;
    margin-right: 20px;
  }

  & > div:nth-child(2) {
    flex-basis: 75%;
    & p {
      font-size: 14px;
      color: ${theme.mutedColor};
      font-family: ${fontFamily.body};
    }

    & h1 {
      font-size: 16px;
      font-family: ${fontFamily.body};
      font-weight: 500;
    }
  }

  @media ${device.laptop} {
    & > div {
      & p {
        font-size: 0.9vw;
      }

      & h1 {
        font-size: 1.1vw;
      }
    }
  }
`;

const StyledFullBlog = styled.div`
  text-align: center;
  /*  overflow: auto;
    height: 90vh; */

  & > div:first-child {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
  }

  @media ${device.laptop} {
    /* & button{
            width: 30%;
        } */
  }
`;

const StyledModal = styled(Modal)`
  /*   width: 80vw; */
`;

const StyledFiX = styled(FiX)`
  color: #ff6666;
`;


const StyledCancel = styled.div`
  border: 1px solid #ff6666;
  width: fit-content;
  padding: 7px;
  place-items: center;
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;

  @media ${device.laptop} {
    font-size: 1.4vw;
  }
`;

const StyledBlogArticle = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
  text-align: left;
  font-family: ${fontFamily.body};

  & img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  & h1 {
    margin-top: 20px;
    font-size: 34px;
    font-weight: 500;
  }

  & p {
    font-size: 14px;
    font-weight: 400px;
  }

  @media ${device.laptop} {
    width: 80%;
    & h1 {
      font-size: 2.3vw;
    }

    & p {
      font-size: 1vw;
    }
  }
`;

const StyledImage = styled.div`
  margin-left: 0px;
  @media ${device.tablet} {
    margin-left: 30px;
  }
`;
const StyledFooter = styled.footer`
  width: 100%;
  font-family: ${fontFamily.body};

  margin-top: 50px;
  & > div:first-child {
    display: flex;

    & h1 {
      color: ${theme.primaryColor};
      margin-left: 20px;

      font-weight: 600;
      font-size: 18px;
    }
  }

  & p {
    font-weight: 400;
    font-size: 13px;
    color: #afc4cd;
    margin-top: 10px;
  }

  @media ${device.laptop} {
    width: 88%;
    & p {
      font-size: 0.9vw;
    }
  }

  @media ${device.laptopL} {
    width: 76%;
  }
`;

const StyledVideoButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 40%;
`;

interface BlogElementProps extends BlogEntry {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Blog = (props: BlogElementProps) => (
  <StyledBlogSummary onClick={props.onClick}>
    <div>
      <img src={blogImage1} alt="" />
    </div>
    <div>
      <h1>{props.title}</h1>
      <p>{moment(props.pubDate).fromNow()}</p>
    </div>
  </StyledBlogSummary>
);

export const WaitingRoom = () => {
  const history = useHistory();
  const browserLocation = useLocation();
  const parsedQueryParams = queryString.parse(browserLocation.search || "");
  const [paymentId] = useState(
    (parsedQueryParams.pwd ||
      ((browserLocation.state || {}) as any).paymentId) as string
  );
  const [meetingId] = useState(parsedQueryParams.m as string);
  const [callToken] = useState(parsedQueryParams.ct as string);
  const [token, setToken] = useState((parsedQueryParams.token as string) || "");
  const [payment, setPayment] = useState({} as Payment);
  const [meeting, setMeeting] = useState({} as Meeting);
  const [currentWaitRoom, setCurrentWaitRoom] = useState({} as TWaitingRoom);
  const [webLinkToLoad, setWebLinkToLoad] = useState("");
  const [buttonWidth, setButtonWidth] = useState("100%");
  const [videoButtonWidth, setVideoButtonWith] = useState("50%");
  const [appointment, setAppointment] = useState(
    {} as Pick<ApptValue, "appointmentDate" | "appointmentType">
  );

  const isAuthenticated = () => firebaseAuth && firebaseAuth.currentUser;

  const triggerFailedRedirect = (message?: string) => {
    const pwdParamsLink = parsedQueryParams.pwd
      ? `?pwd=${parsedQueryParams.pwd}`
      : "";
    const meetingIdParamsLink = meetingId ? `?m=${meetingId}` : "";

    history.push(`/patient/failed${pwdParamsLink || meetingIdParamsLink}`, {
      message:
        message || `Cannot connect you to the session. Bad connection link.`,
    });
  };

  useEffect(() => {
    if (meetingId) {
      const getMeetingAuthenticationToken = getMeetingAuthenticationTokenFunction();
      getMeetingAuthenticationToken({ meetingId }).then((response: any) => {
        setToken(response.data);
      });
    }
  }, [meetingId]);

  useEffect(() => {
    if (!paymentId && !meetingId) {
      history.push("/patient/signin");
    }
  }, [paymentId, meetingId]);

  useEffect(() => {
    if (token) {
      firebaseAuth
        .signInWithCustomToken(token)
        .then(() => {})
        .catch(() => {
          history.push("/patient/signin");
        });
    }
  }, [token]);

  useEffect(() => {
    if (isAuthenticated()) {
      const fetchPayment = async (paymentIdToFetch: string) => {
        const paymentFound = await new PaymentStore().fetchPayment(
          paymentIdToFetch
        );
        if (paymentFound && paymentFound.id) {
          setPayment(paymentFound);
        } else {
          triggerFailedRedirect();
        }
      };
      if (paymentId) {
        fetchPayment(paymentId);
      }
    }
  }, [paymentId, token]);

  useEffect(() => {
    if (token) {
      const fetchMeeting = async (meetingIdToFetch: string) => {
        const meetingFound = await new MeetingStore().getMeetingById(
          meetingIdToFetch
        );

        if (!meetingFound || meetingFound.callToken !== callToken) {
          return triggerFailedRedirect();
        }
        return setMeeting(meetingFound);
      };

      if (meetingId) {
        fetchMeeting(meetingId);
      }
    }
  }, [meetingId, token]);

  useEffect(() => {
    const enterWaitingRoom = async ({
      payment: _payment,
      meeting: _meeting,
    }: {
      payment?: Payment;
      meeting?: Meeting;
    }) => {
      const {
        result,
        message,
        appointment: appt,
        waitingRoom,
      } = await new WaitingRoomStore({
        payment: _payment,
        meeting: _meeting,
      }).enterWaitingRoom(
        parsedQueryParams.pwd || meetingId
          ? WaitingRoomSource.DIRECT_LINK
          : WaitingRoomSource.IN_APP
      );
      if (!result) {
        triggerFailedRedirect(message);
      }

      if (waitingRoom) {
        if (appt) {
          setAppointment(appt);
        } else if (_meeting) {
          setAppointment({
            appointmentDate: _meeting.startTime,
            appointmentType: AppointmentType.TeleConsult,
          });
        }
        setCurrentWaitRoom(waitingRoom);
      }
    };

    if (payment && payment.id) {
      enterWaitingRoom({ payment });
    }

    if (meeting && meeting.id) {
      enterWaitingRoom({ meeting });
    }
  }, [payment, meeting]);

  const joinSession = () => {
    if (currentWaitRoom.status === WaitingRoomStatus.ACCEPTED) {
      if (
        currentWaitRoom.waitingRoomParticipant === WaitingRoomParticipant.GROUP
      ) {
        history.push(`/patient/meeting`, {
          meetingDetails: {
            meeting,
            participant: {
              name: currentWaitRoom.patientName,
              id: currentWaitRoom.patientId,
            },
          },
        });
      } else {
        history.push(`/patient/session`, {
          sessionDetails: {
            paymentId: currentWaitRoom.paymentId,
            participant: {
              name: currentWaitRoom.patientName,
              id: currentWaitRoom.patientId,
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!currentWaitRoom.id) {
      return;
    }

    const waitingRoomRef = databaseRef.child(
      `waiting_room/${currentWaitRoom.providerId}/${currentWaitRoom.path}`
    );
    waitingRoomRef.on("value", (snapshot) => {
      const updatedWaitingRoom = snapshot.val() as TWaitingRoom;
      if (updatedWaitingRoom.status === WaitingRoomStatus.ACCEPTED) {
        notificationAudio.play();
        if (webLinkToLoad) {
          return notification.open({
            message: `${payment.providerName} is ready.`,
            description:
              "Your provider is ready for your session. Please click on join session to begin.",
            duration: 3,
          });
        }
        setCurrentWaitRoom(updatedWaitingRoom);
      } else if (updatedWaitingRoom.status === WaitingRoomStatus.REJECTED) {
        history.push(
          `/patient/failed${
            parsedQueryParams.pwd ? `?pwd=${parsedQueryParams.pwd}` : ""
          }`,
          {
            message:
              "Unable to join session. Your request to enter was cancelled.",
          }
        );
      }
      return null;
    });
  }, [currentWaitRoom.id]);

  const [blogEntries, setBlogEntries] = useState([] as BlogEntry[]);
  const [blog, setBlog] = useState({
    fetching: false,
    error: null,
  } as { fetching: boolean; error: any });

  const loadBlogContent = async () => {
    try {
      const maxNumberOfPostsToDisplay = 4;
      setBlog({ ...blog, fetching: true });
      const entries = ((await WaitingRoomUtils.getLatestBlogEntries()) as any)
        .data as BlogEntry[];
      setBlogEntries(entries.splice(0, maxNumberOfPostsToDisplay));
    } catch (err) {
      setBlog({ ...blog, error: err });
    } finally {
      setBlog({ ...blog, fetching: false });
    }
  };

  useEffect(() => {
    loadBlogContent();
  }, []);

  const appointmentStartTime = WaitingRoomUtils.getRelativeAppointmentStartTime(
    new Date(appointment.appointmentDate)
  );

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      // const width = window.innerWidth * (6.3/100);
      setButtonWidth("70%");
      setVideoButtonWith("35%");
    }
  }, []);

  const handleWebViewModalCancel = () => {
    setWebLinkToLoad("");
  };

  let waitingRoomDisplay: any;
  if (
    appointment.appointmentType?.toLowerCase() ===
    AppointmentType.WalkIn.toLowerCase()
  ) {
    waitingRoomDisplay = (
      <div>
        <h1>Your therapist will be calling you in shortly</h1>
        <p>
          {appointmentStartTime.getTime() > new Date().getTime() ? (
            <span>
              Your appointment starts in{" "}
              {moment(appointmentStartTime).fromNow()}
            </span>
          ) : (
            <span>
              Your appointment is scheduled for{" "}
              {moment(appointmentStartTime).format("ha")}
            </span>
          )}
        </p>
      </div>
    );
  } else {
    waitingRoomDisplay = (
      <div>
        <>
          {currentWaitRoom.status === WaitingRoomStatus.ACCEPTED ? (
            <span>
              <h1>Let&apos;s get started.</h1>
              Your therapist will be joining you now.
            </span>
          ) : (
            <span>
              <h1>Your therapist will join you shortly.</h1>
              Your therapist will be joining you in less than 3 minutes.
            </span>
          )}
        </>
      </div>
    );
  }

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (user: any) => {
      if (!user && !meetingId) {
        history.push("/patient/signin");
      }
    });
  }, []);


  return currentWaitRoom.id ? (
    <Row>
      <Col lg={{ span: 15 }} sm={{ span: 24 }}>
        <StyledLeftContainer>
          <StyledTopDiv>
            <Logo />
            <StyledHeaderText>Welcome to the Waiting Room!</StyledHeaderText>
          </StyledTopDiv>

          <StyledCategoryButtonSection>
            <StyledNotificationDiv>
              <StyledHeaderNotification>
                <StyledImage>
                  <Avatar
                    style={{
                      backgroundColor: "#285365",
                      verticalAlign: "middle",
                    }}
                    size={70}
                  >
                    {currentWaitRoom.patientName[0]}
                  </Avatar>
                </StyledImage>
                <StyledInput value={`${currentWaitRoom.patientName}`} />

                <StyledDemacator>
                  <MiddleIcon />
                </StyledDemacator>
              </StyledHeaderNotification>
              <StyledfooterNotification>
                {waitingRoomDisplay}               
                  <>
                    {currentWaitRoom.status === WaitingRoomStatus.ACCEPTED ? (
                      <PrimaryButtonComponent
                        width={buttonWidth}
                        onClick={() => {
                          joinSession();
                        }}
                        inActive={
                          currentWaitRoom.status !== WaitingRoomStatus.ACCEPTED
                        }
                        buttonText="Click To Join Session Now"
                      />
                    ) : (
                      <CountdownTimer minutes={3} />
                    )}
                  </>
              </StyledfooterNotification>
            </StyledNotificationDiv>

            <StyledFooter>
              <p style={{ fontSize: "18px" }}>
                <a
                  href="https://goo.gl/maps/qFRMgk4hsfAHdLJE7"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#fff" }}
                >
                  <GoogleSquareFilled />
                  <span style={{ marginLeft: "0.5em" }}>
                    Write a Google review
                  </span>
                </a>
              </p>
            </StyledFooter>
          </StyledCategoryButtonSection>
        </StyledLeftContainer>
      </Col>
      <Col lg={{ span: 9 }} sm={{ span: 24 }}>
        <StyledRightContainer>
          <StyledBlogContainer>
            {blogEntries.length < 1 && blog.fetching ? (
              <Spinner message="Please wait while we get you something to read" />
            ) : (
              <>
                <h1>From Our Blog</h1>
                <Col>
                  {blogEntries.slice(0, 3).map((e) => {
                    const onClickBlog = () => {
                      setWebLinkToLoad(e.link);
                    };
                    return (
                      <Blog
                        key={e.link}
                        title={e.title}
                        pubDate={e.pubDate}
                        link={e.link}
                        description={e.description}
                        onClick={onClickBlog}
                      />
                    );
                  })}
                </Col>
              </>
            )}
          </StyledBlogContainer>
          <StyledVideoDiv>
            <StyledVideoButtonDiv>
              <PrimaryButtonComponent
                onClick={() => {
                  setWebLinkToLoad(appSettings.brandVideo);
                }}
                width={videoButtonWidth}
                inActive={false}
                buttonText="Play Video"
              />
            </StyledVideoButtonDiv>
          </StyledVideoDiv>
        </StyledRightContainer>
      </Col>
      {webLinkToLoad && (
        <StyledModal
          visible={Boolean(webLinkToLoad)}
          onCancel={handleWebViewModalCancel}
          footer={false}
          closable={false}
          width={
            window.innerWidth > 768
              ? window.innerWidth - 400
              : window.innerWidth
          }
          centered={false}
          style={{ top: "5px" }}
        >
          <StyledFullBlog>
            <div>
              <StyledCancel onClick={handleWebViewModalCancel}>
                <StyledFiX />
              </StyledCancel>
            </div>
            {appointment.appointmentType?.toLowerCase() ===
            AppointmentType.WalkIn.toLowerCase() ? (
              ""
            ) : (
              <PrimaryButtonComponent
                onClick={() => {
                  joinSession();
                }}
                inActive={currentWaitRoom.status !== WaitingRoomStatus.ACCEPTED}
                buttonText="Join Session"
              />
            )}
            <StyledBlogArticle>
              <Iframe link={webLinkToLoad} />
            </StyledBlogArticle>
          </StyledFullBlog>
        </StyledModal>
      )}
    </Row>
  ) : (
    <></>
  );
};
