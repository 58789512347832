import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import { theme } from "../../../assets/theme";

const StyledCountdownDisplay = styled.div`
  border-radius: 10px;
  padding: 13px 14px;
  background-color: ${theme.mutedColor};
  color: ${theme.black};
  font-family: ${fontFamily.manrope};
  font-weight: bold;
  border: none;
  font-size: 16px;
  cursor: pointer;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.08);
  width: 100%;

  &:hover,
  &:focus {
    background-color: ${theme.mutedColor};
    color: ${theme.black};
    outline: none;
  }

  &:hover {
    box-shadow: none;
  }

  @media ${device.laptop} {
    width: 50%;
    font-size: 1.2vw;
  }

  @media ${device.desktopL} {
    padding: 20px 17px;
  }
`;

function CountdownTimer({ minutes }: { minutes: number }) {
  const [countDown, setCountDown] = useState(`${minutes}:0`);

  let currentMinutes = 0;
  let mins = 0;

  useEffect(() => {
    function count(_minutes: number) {
      let seconds = 60;
      mins = _minutes;
      function counter() {
        currentMinutes = mins - 1;
        seconds -= 1;
        setCountDown(
          currentMinutes
            .toString()
            .concat(":")
            .concat(seconds < 10 ? "0" : "")
            .concat(String(seconds))
        );
        if (seconds > 0) {
          setTimeout(counter, 1000);
        } else if (mins > 1) {
          count(mins - 1);
        }
      }
      counter();
    }
    count(minutes);

    return () => {
      setTimeout({} as any);
    };
  }, [currentMinutes, mins]);

  return (
    <StyledCountdownDisplay>Join Session ({countDown})</StyledCountdownDisplay>
  );
}

export default CountdownTimer;
