import React from "react";
import { FiRefreshCcw } from "react-icons/fi";
import styled from "styled-components";
import { fontFamily } from "../../../assets/fontFamily";

const StyledScheduleBarComponent = styled.div`
  margin-bottom: 50px;
`;

const StyledDateDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  & p {
    margin: 0px;
  }

  & > p {
    font-family: ${fontFamily.heading};
    font-size: 18px;
    font-weight: 700;
    color: #1a1c1d;
    margin-left: 15px;

    & span {
      font-weight: 400;
    }
  }

  & > div {
    display: flex;
  }
`;

const StyledSwitch = styled.div`
  border: 1.11546px solid #cedce2;
  border-radius: 11.1546px;
  display: flex;
  align-items: center;
  font-size: 14.22px;
  background-color: #fff;
  padding: 10px 15px;
  margin-right: 20px;
  cursor: pointer;

  & p {
    margin-left: 10px;
    font-family: ${fontFamily.body};
    font-weight: 500;
    color: #5d6d74;
  }
`;

const StyledFiRefreshCcw = styled(FiRefreshCcw)`
  color: #5d6d74;
  font-size: 21px;
`;

export const ScheduleBarComponentScaffold = (props: {
  day: string;
  otherDate: string;
  displaySwitchButton?: boolean;
  hideSwitch?: boolean;
  handleSwitch?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: any;
}) => (
  <StyledScheduleBarComponent>
    <StyledDateDiv>
      <p>
        {props.day}, <span>{props.otherDate}</span>
      </p>
      {props.displaySwitchButton && (
        <div>
          {!props.hideSwitch && (
            <StyledSwitch onClick={props.handleSwitch}>
              <StyledFiRefreshCcw />
              <p>Switch to Calendar view</p>
            </StyledSwitch>
          )}
        </div>
      )}
    </StyledDateDiv>

    {props.children}
  </StyledScheduleBarComponent>
);
