import styled from "styled-components";
import { fontFamily } from "../../../assets/fontFamily";
import { device } from "../../../assets/breakbpoints";

export const StyledEligibilityCheckForm = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 51px;
  margin-top: 30px;

  & h1 {
    color: #131523;
    font-size: 16px;
    font-family: ${fontFamily.Inter};
    font-weight: 700;
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.2vw;
    }
  }
`;