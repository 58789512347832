export const theme = {
  primaryColor: "#F6A851",
  black: "#2D3743",
  secondaryColor: "#41748A",
  secondaryColor_darker: "#285365",
  secondaryColor_lighter: "#3B8BAD",
  secondaryColor_asset: "#41748A",
  grey: "#A9AEB5",
  normalText: "#414457",
  mutedColor: "#A9AEB5",
  red: "#EB5757",
};
