import React from "react";
import { useHistory } from "react-router-dom";
import sideImage1 from "../../assets/images/sideImage1.png";
import { BackButton } from "../elements/BackButton";
import { InputComponent } from "../elements/InputComponent";
import { OnBoardingTopText } from "../elements/OnBoardingTopText";
import { PrimaryButtonComponent } from "../elements/PrimaryButtonComponent";
import { SelectInput } from "../elements/SelectInput";
import { OnBoardingScaffold } from "../layout/OnBoardingScaffold";

export const SelectProvider = () => {
  const history = useHistory();
  const handleProceedToPay = () => {
    history.push("/patient/pay");
  };
  return (
    <OnBoardingScaffold
      topItem={<OnBoardingTopText prefixText="Select" segment="Provider" />}
      sideImage={sideImage1}
    >
      <InputComponent title="SELECT PROVIDER" input={<SelectInput />} />
      <PrimaryButtonComponent
        onClick={handleProceedToPay}
        buttonText="Proceed to Pay"
      />
      <BackButton />
    </OnBoardingScaffold>
  );
};
