import { useContext, createContext } from "react";
import { UserPermissionContext } from "../../types/permission";

const defaultPermission: UserPermissionContext = {
    permission: {}
}

export const PermissionContext = createContext<UserPermissionContext>(defaultPermission);

export const usePermissionContext = () => useContext(PermissionContext);
