import React from "react";

function Iframe({ link, iframeSrc }: { link: string; iframeSrc?: string }) {
  const iframe =
    iframeSrc ||
    `<iframe height="500" style="width: 100%;" scrolling="yes" src="${link}" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>`;
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: iframe || "" }} />;
}

export default Iframe;
