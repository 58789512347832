import moment from "moment";
import { getMedconPatientId } from "../utils/hash";
import { databaseRef } from "./firebase";
import { Patient } from "../types/patient";
import { Provider } from "../types/provider";
import { TimeZone } from "../types/enum";
import { Appointment } from "../types/appointment";
import { isDateValid } from "../utils/date-helper";
import logger from "../utils/logger";
import ProviderStore from "./provider";
import { ApptValue } from "../types/appt-value";
import { PatientInfo } from "../types/patientInfo";

export default class PatientStore {
  path: string;

  providerPath: string;

  appointmentPath: string;

  defaultTimeZone: string;

  constructor() {
    this.path = "patient";
    this.providerPath = "provider";
    this.appointmentPath = "appointments";
    this.defaultTimeZone = TimeZone.EST;
  }

  async getPatientByEmailAndDob(
    email: string,
    dob: string
  ): Promise<Patient | null> {
    let patient = null;
    try {
      const identifier = await getMedconPatientId(email, new Date(dob));
      const patientDoc = await databaseRef
        .child(`${this.path}/${identifier}`)
        .once("value");
      if (patientDoc.exists()) {
        patient = patientDoc.val() as Patient;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return patient;
  }

  async getPatientById(id: string): Promise<Patient> {
    let patient = {} as Patient;
    try {
      const patientDoc = await databaseRef
        .child(`${this.path}/${id}`)
        .once("value");
      if (patientDoc.exists()) {
        patient = patientDoc.val() as Patient;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return patient;
  }

  async deletePatient(id: string): Promise<Patient> {
    const patient = await this.getPatientById(id);
    try {
      await databaseRef
        .child(`${this.path}/${id}`).update({ ...patient, visStatus: "DELETED" });
    } catch (err) {
      logger.error(err.message);
    }
    return patient;
  }

  async getAllPatient(): Promise<Patient[]> {
    let patients: Patient[] = [];
    try {
      const patientDoc = await databaseRef.child(`${this.path}`).once("value");
      if (patientDoc.exists()) {
        patients = patientDoc.val() as Patient[];
      }
    } catch (err) {
      logger.error(err.message);
    }
    return patients;
  }

  async fetchPatientAppointment(
    providerId: string,
    patientId: string
  ): Promise<Appointment> {
    let response = {} as Appointment;
    try {
      const directlink = `${this.providerPath}/${providerId}/appointments/${patientId}`;
      const providerDoc = await databaseRef.child(directlink).once("value");
      if (providerDoc.exists()) {
        response = providerDoc.val() as Appointment;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return response;
  }

  async checkPatientAppointment(email: string, dob: string): Promise<string> {
    let checkAppt: string = "false";

    try {
      let appt = {} as Appointment;

      const patient = await this.getPatientByEmailAndDob(email, dob);

      if (patient !== null) {
        const providerIds = patient.providerIds as Array<string>;

        Object.values(providerIds).forEach(async (providerId) => {
          appt = await this.fetchPatientAppointment(
            providerId,
            patient.externalId
          );

          if (Object.keys(appt).length >= 1) {
            const apptDay = moment(
              new Date(appt[0].appointmentDate).toISOString().split("T")[0]
            ).format("ddd");
            const currentDay = moment(
              new Date().toISOString().slice(0, 10)
            ).format("ddd");

            if (apptDay === currentDay) {
              checkAppt = "true";
            }
          }
        });
      }
    } catch (err) {
      logger.error(err);
    }

    return checkAppt;
  }

  async getPatientAndProviders(email: string, dob: string): Promise<any> {
    let patientAndProviders: [Patient, Provider[]] | null;
    try {
      const patient = await this.getPatientByEmailAndDob(email, dob);
      if (patient) {
        if (Object.keys(patient.providerIds!).length < 1) {
          return null;
        }
        const providers: Provider[] | null = [];

        const providerIds: string[] = [];
        Object.values(patient.providerIds!).forEach((providerId) => {
          providerIds.push(providerId);
        });

        /* eslint-disable no-await-in-loop */
        for (const providerId of providerIds) {
          const path = `${this.providerPath}/${providerId}`;
          const providerDocs = await databaseRef.child(path).once("value");
          if (providerDocs.exists()) {
            const provider = providerDocs.val() as Provider;
            providers.push(provider);
          }
        }
        /* eslint-enable no-await-in-loop */
        patientAndProviders = [patient, providers];
        return patientAndProviders;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return null;
  }

  async getAppointmentByEmailAndDob(
    email: string,
    dob: string
  ): Promise<[ApptValue, Patient, Provider] | null> {
    let patientAppointment: [ApptValue, Patient, Provider] | null = null;
    try {
      const patient = await this.getPatientByEmailAndDob(email, dob);
      if (patient) {
        if (Object.keys(patient.providerIds!).length < 1) {
          return null;
        }
        const providerId = Object.values(patient.providerIds!)[0];
        const path = `${this.providerPath}/${providerId}/${this.appointmentPath}/${patient.id}`;
        const allAppointmentDocs = await databaseRef.child(path).once("value");
        if (allAppointmentDocs.exists()) {
          const appointments = allAppointmentDocs.val() as Appointment;
          const validAppointmentKeys = Object.keys(
            appointments
          ).filter((appointmentId: string) =>
            isDateValid(
              appointments[appointmentId].appointmentDateTs,
              "Day",
              "MM/DD/YYYY",
              true
            )
          );
          if (validAppointmentKeys.length < 1) {
            return null;
          }
          const [firstMatchingApptKey] = validAppointmentKeys;
          const providerDoc = await databaseRef
            .child(`${this.providerPath}/${providerId}`)
            .once("value");
          const provider = providerDoc.val() as Provider;
          patientAppointment = [
            appointments[firstMatchingApptKey],
            patient,
            provider,
          ];
        }
      }
    } catch (err) {
      logger.error(err.message);
    }
    return patientAppointment;
  }

  async getAppointmentsByPatientId(
    patientId: string
  ): Promise<Appointment []| null> {
    const appointments: Appointment [] | null = [];
    try {
      const patient = await this.getPatientById(patientId);
      if (patient) {
        if (Object.keys(patient.providerIds!).length < 1) {
          return null;
        }
        const providerIds = [];
        Object.values(patient.providerIds!).forEach(
          (providerId: string) => {
            providerIds.push(providerId);
          }
        );
        for( const providerId of providerIds){
          const path = `${this.providerPath}/${providerId}/${this.appointmentPath}/${patient.id}`;
            /* eslint-disable no-await-in-loop */
            const allAppointmentDocs = await databaseRef.child(path).once("value");
            if (allAppointmentDocs.exists()) {
              const patientAppointments = allAppointmentDocs.val() as Appointment;

              // populate providerId in the appointment array
                Object.keys(patientAppointments).forEach((apptKey) => {
                   patientAppointments[apptKey].providerId  = providerId;
                });
              
              appointments.push(patientAppointments);
            }
        }
      }
    } catch (err) {
      logger.error(err.message);
    }
    return appointments;
  }

  async getPatientInfo(providerId: string): Promise<PatientInfo[]> {
    const providerStore = new ProviderStore();
    const newAppt: PatientInfo[] = [];

    const appts: Appointment[] = await providerStore.fetchProviderAppointments(
      providerId
    );
    Object.values(appts).forEach(async (appt) => {
      Object.keys(appt).forEach(async (apptKey) => {
        const patientInfo = await this.getPatientById(appt[apptKey].patientId);

        // get all appointments that belong to current week
        if (moment(appt[apptKey].appointmentDate).isSame(new Date(), "week")) {
          const patient: Patient = {
            id: patientInfo.id,
            externalId: patientInfo.externalId,
            email: patientInfo.email,
            firstName: patientInfo.firstName,
            lastName: patientInfo.lastName,
            preferredName: patientInfo.preferredName,
            contactNumber: patientInfo.contactNumber,
            dob: patientInfo.dob,
          };
          const appointment: ApptValue = {
            id: appt[apptKey].id,
            appointmentDate: appt[apptKey].appointmentDate,
            appointmentEndDate: appt[apptKey].appointmentEndDate,
            appointmentDateTs: appt[apptKey].appointmentDateTs,
            appointmentEndDateTs: appt[apptKey].appointmentEndDateTs,
            startingDate: appt[apptKey].startingDate,
            time: appt[apptKey].time,
            timeZone: appt[apptKey].timeZone,
            recurrentRule: appt[apptKey].recurrentRule,
            patientId: appt[apptKey].patientId,
            providerId: appt[apptKey].providerId,
            providerName: appt[apptKey].providerName,
            requestedService: appt[apptKey].requestedService,
            clientPreferedName: appt[apptKey].clientPreferedName,
            clientEmail: appt[apptKey].clientEmail,
            clientPhoneNo: appt[apptKey].clientPhoneNo,
            copayAmount: appt[apptKey].copayAmount,
            currency: appt[apptKey].currency,
            location: appt[apptKey].location,
            clientFirstName: appt[apptKey].clientFirstName,
            clientLastName: appt[apptKey].clientLastName,
          };

          const patientData = { patient, appointment } as PatientInfo;

          newAppt.push(patientData);
        }
      });
    });

    return newAppt;
  }

  async getAllPatients(): Promise<Patient[]> {
    let patients: Patient[] = [];
    try {
      const sessionDoc = await databaseRef.child(`${this.path}`)
      .orderByChild("visStatus")
        .equalTo("ACTIVE").once("value");
      if (sessionDoc.exists()) {
        patients = sessionDoc.val() as Array<Patient>;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return patients;
  }

  async getPatientsByProviderId(providerId: string): Promise<Patient[]> {
    let patients: Patient[] = [];
    try {
      const sessionDoc = await databaseRef
        .child(`${this.path}`)
        .orderByChild("providerIds")
        .equalTo(providerId)
        .once("value");
      if (sessionDoc.exists()) {
        patients = sessionDoc.val() as Array<Patient>;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return patients;
  }
}
