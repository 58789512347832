import React from "react";
import { Spin, Space } from "antd";

function Spinner({ message }: { message: string }) {
  return (
    <Space size="middle">
      <Spin size="large" tip={message} />
    </Space>
  );
}

export default Spinner;
