import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const CacheManager = ({
  children = null,
  currentVersion
}) => {

  const [cacheStatus, setCacheStatus] = useState({
    loading: true,
    isLatestVersion: false
  });

  const isThereNewVersion = (metaVersion, version) => {
    if (!version) {
      return false;
    }
    const metaVersions = metaVersion.split(/\./g);
    const currentVersions = currentVersion.split(/\./g);

    while (metaVersions.length || currentVersions.length) {
      const a = Number(metaVersions.shift());
      const b = Number(currentVersions.shift());
      if (a === b) {
        // eslint-disable-next-line no-continue
        continue;
      }
      return a > b || Number.isNaN(b);
    }
    return false;
  };


  const checkCacheStatus = async () => {
    try {
      const res = await fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' });
      const { version: metaVersion } = await res.json();

      const shouldForceRefresh = isThereNewVersion(metaVersion, currentVersion);
      if (shouldForceRefresh) {
        setCacheStatus({
          loading: false,
          isLatestVersion: false
        });
      } else {
        setCacheStatus({
          loading: false,
          isLatestVersion: true
        });
      }
    } catch (error) {
        setCacheStatus({
          loading: false,
          isLatestVersion: true
        });
    }
  };


  useEffect(() => {
     checkCacheStatus();
  }, []);


  const refreshCacheAndReload = async () => {
      if (caches) {
        const cacheNames = await caches.keys();
        for (const cacheName of cacheNames) {
          caches.delete(cacheName);
        }        
        window.location.reload();
      }   
  };

    if (!cacheStatus.loading && !cacheStatus.isLatestVersion) {
      refreshCacheAndReload();
      return null;
    }
    return children;
  
}

CacheManager.propTypes = {
  children: PropTypes.element.isRequired,
  currentVersion: PropTypes.string.isRequired
};
