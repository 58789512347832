import { Divider } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { FiCreditCard, FiClock, FiUserCheck, FiEdit } from "react-icons/fi";
import SessionStore from "../../store/session";
import TSession, { SessionStatus } from "../../types/session";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import { theme } from "../../assets/theme";
import { useScreenNameContext } from "../context/screenNameContext";
import { PermissionContext } from "../context/permissionContext";
import { Table } from "./atoms/Table";
import { Loader } from "../elements/Loader";
import { formatWithCurrency } from "../../utils/hash";
import { AppointmentType, FeatureFlag, Role } from "../../types/enum";
import { FeaturesContext } from "../context/featuresContext";
import { canAccessFeature } from "../../utils/feature-helper";

declare const window: any;

const StyledStatContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;

  & > div {
    flex-basis: 48.5%;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    div {
      flex-basis: 100%;
    }

    div:nth-child(1) {
      margin-bottom: 10px;
    }
  }
`;

const StyledStatCard = styled.div`
  box-shadow: 0px 0px 1.73853px rgba(12, 26, 75, 0.24),
    0px 5.2156px 13.9083px -1.73853px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
  background: #ffffff;
  width: 100%;
  padding: 40px 0px;
`;

const StyledStatTablet = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;

  & > div:first-child {
    display: grid;
    place-items: center;
    height: 80px;
    width: 80px;
    border-radius: 14.4762px;

    & > div {
      width: 40%;

      & img {
        width: 100%;
        height: auto;
      }
    }
  }

  & > div:nth-child(2) {
    font-family: ${fontFamily.body};
    margin-left: 30px;
    & p {
      font-weight: 500;
      font-size: 18px;
      color: #a9aeb5;
      margin: 0px;
    }

    & h1 {
      font-weight: 600;
      font-size: 32px;
      color: #16192c;
    }
  }

  @media ${device.laptop} {
    & > div:first-child {
      height: 6vw;
      width: 6vw;
    }

    & > div:nth-child(2) {
      & p {
        font-size: 1.25vw;
      }

      & h1 {
        font-size: 2.3vw;
      }
    }
  }
`;

const StyledDivider = styled.div`
  width: 85%;
  margin: 0 auto;
`;

const StyledSessionCount = styled.div`
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-family: ${fontFamily.body};
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0px;
  }

  & p {
    font-size: 15px;
    margin: 0;
  }

  & h4 {
    color: ${theme.black};
    font-size: 18px;
    font-weight: 500;
    margin: 0px;
  }

  @media ${device.laptop} {
    & p {
      font-size: 1.05vw;
    }

    & h4 {
      font-size: 1.25vw;
    }
  }
`;

const StyledImageDiv = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  padding: 12px;

  @media ${device.laptop} {
    font-size: 1.5vw;
  }
`;

const StyledStatNumberDiv = styled.div`
  margin-left: 20px;
  height: 100%;
  & p {
    font-family: ${fontFamily.body};
    font-weight: 600;
    margin: 0px;
    &:first-child {
      color: ${theme.mutedColor};
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 5px;
    }

    &:nth-child(2) {
      font-size: 20px;
      font-weight: 600;
      font-family: ${fontFamily.body};
      margin-bottom: 0px;
      line-height: 17px;
    }
  }
`;

const StatCopay = (props: {
  color: string;
  title: string;
  count: number | string;
}) => (
  <StyledStatTablet>
    <StyledImageDiv style={{ backgroundColor: props.color }}>
      <FiCreditCard size={40} />
    </StyledImageDiv>
    <StyledStatNumberDiv>
      <p>{props.title}</p>
      <h1>{props.count}</h1>
    </StyledStatNumberDiv>
  </StyledStatTablet>
);

const StatSession = (props: {
  color: string;
  title: string;
  count: number | string;
}) => (
  <StyledStatTablet>
    <StyledImageDiv style={{ backgroundColor: props.color }}>
      <FiUserCheck size={40} />
    </StyledImageDiv>
    <StyledStatNumberDiv>
      <p>{props.title}</p>
      <h1>{props.count}</h1>
    </StyledStatNumberDiv>
  </StyledStatTablet>
);

const StatDuration = (props: {
  color: string;
  title: string;
  count: number | string;
}) => (
  <StyledStatTablet>
    <StyledImageDiv style={{ backgroundColor: props.color }}>
      <FiClock size={40} />
    </StyledImageDiv>
    <StyledStatNumberDiv>
      <p>{props.title}</p>
      <h1>{props.count}</h1>
    </StyledStatNumberDiv>
  </StyledStatTablet>
);

const SessionCount = (props: {
  color: string;
  count: number | string;
  title: string;
}) => (
  <StyledSessionCount>
    <p style={{ color: props.color }}>{props.title}</p>
    <h4>{props.count}</h4>
  </StyledSessionCount>
);

const title = [
  "requested service",
  "location",
  "client",
  "status",
  "timestamp",
  "copay",
  "",
];
export const Analytics = () => {
  const history = useHistory();
  const { permission } = useContext(PermissionContext);
  const { features } = useContext(FeaturesContext) as any;
  const [loader, showLoader] = useState(true);
  const [sessionData, setSessionData] = useState([] as any);
  const [reportStartDate, setReportStartDate] = useState(
    moment().startOf("month")
  );
  const [reportEndDate, setReportEndDate] = useState(moment().endOf("month").add(23, "hours"));

  const [walkInSessionsCount, setWalkInSessionsCount] = useState(0);
  const [teleConsultSessionsCount, setTeleConsultSessionsCount] = useState(0);
  const [acceptedSessionsCount, setAcceptedSessionsCount] = useState(0);
  const [inProgressSessionsCount, setInProgressSessionsCount] = useState(0);
  const [totalCopay, setTotalCopay] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);

  // TODO use USD as default currency until a company profile is set
  const currency = "USD";

  const { setCurrentPageName } = useScreenNameContext() as any;

  useEffect(() => {
    if (permission && permission.role === Role.PROVIDER) {
      history.push("/provider/overview", { permission });
    }
  }, [permission]);

  window.navigateToNote = (session: any) => {
    const link = `/provider/note/takenote/?sessionId=${session.paymentId}`;
    history.push(link);
  };

  const displayAnalytics = (analyticsStartDate: any, analyticsEndDate: any) => {
    let walkInSessions = 0;
    let acceptedSessions = 0;
    let teleConsultSessions = 0;
    let inProgresSessions = 0;
    let duration = 0;
    let totalAmount = 0;

    const sessionStore = new SessionStore();
    sessionStore
      .getSessionsByDate(
        analyticsStartDate.valueOf(),
        analyticsEndDate.valueOf()
      )
      .then((sessions: TSession[]) => {
        const parsedSessions = [] as any[];

        const featureAccessible = canAccessFeature(
          features,
          FeatureFlag.NOTETAKING
        );

        Object.values(sessions).forEach((session: TSession) => {
          const sessionDate = moment(session.sessionStartDate);

          parsedSessions.push({
            sessionTitle: session.requestedService,
            location: session.location,
            patient: session.patientName,
            status: session.sessionStatus,
            timeStamp: `${sessionDate.format(
              "MMMM D yyyy"
            )} | ${sessionDate.format("HH:mm")} `,
            amountPaid: formatWithCurrency(session.amount, session.currency),
            date: sessionDate,
            actions: featureAccessible
              ? [
                  {
                    title: "Take Note",
                    handler: window.navigateToNote,
                    param: session,
                    type: "button",
                    icon: <FiEdit />,
                  },
                ]
              : [],
          });

          if (session.appointmentType === AppointmentType.TeleConsult) {
            teleConsultSessions += 1;
          } else {
            walkInSessions += 1;
          }

          if (
            session.amount &&
            !Number.isNaN(session.amount) &&
            session.sessionStatus === SessionStatus.ENDED
          ) {
            totalAmount += session.amount;
          }

          if (session.sessionStatus === SessionStatus.ENDED) {
            acceptedSessions += 1;
            const startDate = moment(session.sessionStartDate);
            const endDate = moment(session.sessionEndDate);
            const difference = endDate.diff(startDate, "hours");
            duration += difference;
          } else {
            inProgresSessions += 1;
          }
        });

        setInProgressSessionsCount(inProgresSessions);
        setAcceptedSessionsCount(acceptedSessions);
        setWalkInSessionsCount(walkInSessions);
        setTeleConsultSessionsCount(teleConsultSessions);
        setTotalDuration(duration);
        setTotalCopay(totalAmount);
        setSessionData(parsedSessions);
        showLoader(false);
      });
  };

  useEffect(() => {
    setCurrentPageName("Analytics");
    displayAnalytics(reportStartDate, reportEndDate);
  }, []);

  const filterByDate = (startDate: any, endDate: any) => {
    let parsedEndDate;
    if(endDate){
      parsedEndDate= endDate.add(23, "hours");
    } 
    setReportStartDate(startDate);
    setReportEndDate(parsedEndDate);
    showLoader(true);
    if (startDate && parsedEndDate) {
      displayAnalytics(startDate, parsedEndDate);
    }
  };

  return (
    <>
      <StyledStatContainer>
        <div>
          <StyledStatCard>
            <StatSession
              title="Total Sessions"
              count={walkInSessionsCount + teleConsultSessionsCount}
              color="#D8F3FF"
            />
            <StyledDivider>
              <Divider />
            </StyledDivider>
            <SessionCount
              title="Walk-in-Sessions"
              count={walkInSessionsCount}
              color="#4C6FFF"
            />
            <SessionCount
              title="Teleconsult Sessions"
              count={teleConsultSessionsCount}
              color="#308CB4"
            />
            <SessionCount
              title="Completed Sessions"
              count={acceptedSessionsCount}
              color="#219653"
            />
            <SessionCount
              title="Ongoing Sessions"
              count={inProgressSessionsCount}
              color="#308CB4"
            />
          </StyledStatCard>
        </div>
        <div>
          <StyledStatCard>
            <StatCopay
              title="Total Copay"
              count={formatWithCurrency(totalCopay, currency)}
              color="#DEFFEE"
            />
          </StyledStatCard>

          <StyledStatCard>
            <StatDuration
              title="Session Durations(hr)"
              count={totalDuration}
              color="#FBF1E6"
            />
          </StyledStatCard>
        </div>
      </StyledStatContainer>
      <Table
        sessionName="Client Sessions"
        tableTitle={title}
        tableName="All Client Sessions"
        showDateFilter
        data={sessionData}
        isActive
        filterByDateHandler={filterByDate}
      />
      <Loader visible={loader} text="Loading ... " />
    </>
  );
};
