import React, { useEffect, useState } from "react";
import { Button, Input, Alert } from "antd";
import { Select } from "@chakra-ui/react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Loader } from "../../elements/Loader";
import { ClientResponse } from "../../../types/enum";
import { theme } from "../../../assets/theme";
import NoteStore from "../../../store/note";
import SessionStore from "../../../store/session";
import { Note } from "../../../types/note";
import TSession from "../../../types/session";

export const FinalStep = (props: any) => {
  const { TextArea } = Input;
  const browserLocation = useLocation();
  const parsedQueryParams = queryString.parse(browserLocation.search || "");
  const [sessionId] = useState(parsedQueryParams.sessionId as string);
  const [loader, showLoader] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFailure, setAlertFailure] = useState(false);
  const [session, setSession] = useState<TSession>();

  const noteStore = new NoteStore();

  useEffect(() => {
    if (sessionId) {
      const sessionStore = new SessionStore(null, sessionId);
      sessionStore.getSession().then((result: TSession) => {
        setSession(result);
      });
    }
  }, [sessionId]);

  function SaveNote() {
    showLoader(true);
    const note = {} as Note;
    note.sessionId = sessionId;
    note.providerId = session.providerId;
    note.patientName = session.patientName;
    note.patientEmail = session.patientEmail;
    note.clinicalCodes = props.state.clinicalCodes;
    note.modality = props.state.modality;
    note.sessionFormat = props.state.sessionFormat;
    note.startTime = props.state.startTime;
    note.endTime = props.state.endTime;
    note.levelOfFunctioning = props.state.levelOfFunctioning;
    note.stressors = props.state.stressors;
    note.emotionalStabilityGoal = props.state.emotionalStability;
    note.careerGoal = props.state.career;
    note.socialAnxietyGoal = props.state.socialAnxiety;
    note.subjective = props.state.subject;
    note.objective = props.state.objective;
    note.assessment = props.state.assessment;
    note.plan = props.state.plan;
    note.clientResponse = props.state.clientResponse;

    if (note.sessionId) {
      noteStore.saveNote(note).then((response: boolean) => {
        if (response) {
          setAlertSuccess(response);
        } else {
          setAlertFailure(true);
        }
        showLoader(false);
      });
    }
  }

  return (
    <div style={{ padding: "10px 10px" }}>
      <h3>Session Note</h3>

      {alertSuccess ? (
        <>
          <br />
          <Alert
            message="Session Note Created Successfully."
            type="success"
            showIcon
            closable
            onClose={() => {
              setAlertSuccess(false);
            }}
          />
        </>
      ) : (
        ""
      )}
      {alertFailure ? (
        <>
          <br />
          <Alert
            message="Note not saved, please ensure the fields are filled correctly."
            type="error"
            showIcon
            closable
            onClose={() => {
              setAlertFailure(false);
            }}
          />
        </>
      ) : (
        ""
      )}
      <br />
      <p>
        <TextArea
          style={{ width: "100%" }}
          placeholder="Subjective (patient expressed concerns)"
          rows={4}
          name="subject"
          value={props.getState("subject", "")}
          onChange={props.handleChange}
        />
      </p>
      <p>
        <TextArea
          style={{ width: "100%" }}
          placeholder="Objective (what did you observe about the client)"
          rows={4}
          name="objective"
          value={props.getState("objective", "")}
          onChange={props.handleChange}
        />
      </p>
      <p>
        <TextArea
          style={{ width: "100%" }}
          placeholder="Assessment (therapist analysis)"
          rows={4}
          name="assessment"
          value={props.getState("assessment", "")}
          onChange={props.handleChange}
        />
      </p>
      <p>
        <TextArea
          style={{ width: "100%" }}
          placeholder="Plan"
          rows={4}
          name="plan"
          value={props.getState("plan", "")}
          onChange={props.handleChange}
        />
      </p>
      <p>
        <Input.Group style={{ width: "100%" }}>
          <Input
            disabled
            value="Client Response"
            style={{
              width: "30%",
              height: "35px",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
            }}
          />
          <Select
            name="clientResponse"
            style={{ width: "70%", height: "35px" }}
            value={props.getState("clientResponse", "")}
            onChange={props.handleChange}
          >
            <option>Select...</option>
            {Object.values(ClientResponse).map((clientResponse) => (
              <option key={clientResponse} value={clientResponse}>
                {clientResponse}
              </option>
            ))}
          </Select>
        </Input.Group>
      </p>
      <p>
        {props.hasPrev() && (
          <Button
            onClick={props.prev}
            style={{
              backgroundColor: theme.primaryColor,
              color: theme.black,
              marginRight: "10px",
            }}
          >
            Previous
          </Button>
        )}
        {props.hasNext() && (
          <Button
            onClick={props.next}
            style={{
              backgroundColor: theme.primaryColor,
              color: theme.black,
              marginRight: "10px",
            }}
          >
            Next
          </Button>
        )}
        <Button
          onClick={SaveNote}
          style={{ backgroundColor: theme.primaryColor, color: theme.black }}
        >
          Submit Note
        </Button>
      </p>
      <Loader visible={loader} text="Saving session note... " />
    </div>
  );
};
