import { AppointmentType, Location, RequestedServiceType } from "./enum";

export enum SessionStatus {
  STARTED = "STARTED",
  ENDED = "ENDED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum WaitingRoomStatus {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  ACCEPTED = "ACCEPTED",
}

export enum WaitingRoomSource {
  DIRECT_LINK = "DIRECT_LINK",
  IN_APP = "IN_APP",
}

export enum WaitingRoomParticipant {
  GROUP = "GROUP",
  SINGLE = "SINGLE",
}

export interface WaitingRoom {
  id: string;
  path: string;
  paymentId: string;
  providerId: string;
  patientId: string;
  startTime: Date;
  startTimeTs: number;
  endTime?: Date;
  endTimeTs?: Date;
  patientName: string;
  location: Location;
  status: WaitingRoomStatus;
  appointmentId: string;
  checkinSource: WaitingRoomSource;
  requestedService?: RequestedServiceType;
  waitingRoomParticipant: WaitingRoomParticipant;
}

export default interface Session {
  paymentId: string;
  providerId: string;
  appointmentDate?: string;
  appointmentDateTs?: number;
  sessionStartDate: Date;
  sessionStartDateTs?: number;
  waitroomStartTime: Date;
  waitroomStartTimeTs: number;
  sessionEndDate?: Date;
  sessionEndDateTs?: number;
  sessionStatus: SessionStatus;
  providerName: string;
  patientName?: string;
  patientPreferredName?: string;
  patientEmail: string;
  providerEmail: string;
  participants: { id: string; name: string; joined: Date }[];
  location: Location;
  requestedService?: RequestedServiceType;
  amount: number;
  currency?: string;
  appointmentType?: AppointmentType;
}
