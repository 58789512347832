import { Alert, Col } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FiDownloadCloud } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import styled from "styled-components";
import { fontFamily } from "../../../assets/fontFamily";
import { PermissionContext } from "../../context/permissionContext";
import { Loader } from "../../elements/Loader";
import { CreateAppointment } from "../atoms/CreateAppointment";
import { ScheduleBarComponentScaffold } from "../atoms/ScheduleBarComponentScaffold";
import { Role } from "../../../types/enum";
import {
  executeImportProviderAppointmentsFunction
} from "../../../store/callableFunctions";

const StyledSceduleBar = styled.div`
  background-color: #fff;
  border-radius: 13.3855px;
  width: 100%;
  min-height: 70vh;
  margin-bottom: 10px;
  display: grid;
  place-items: center;
`;

const StyledEventButton = styled.div<{ width?: number }>`
  border: 1.11546px solid #cedce2;
  border-radius: 11.1546px;
  display: flex;
  width: ${(props) => props.width}%;
  align-items: center;
  font-size: 14.22px;
  background-color: #fff;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;

  & p {
    margin-bottom: 0px;
    margin-left: 9px;
    font-family: ${fontFamily.body};
    font-weight: 500;
    color: #5d6d74;
  }
`;

const StyledActionButtons = styled.div`
  display: flex;
  margin-left: auto;
  margin-bottom: 20px;
`;

export const ScheduleEmpty = () => {
  const history = useHistory();
  const currentDate = moment().startOf("day");
  const [loader, showLoader] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const { permission } = useContext(PermissionContext);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (permission) {
      if (
        permission.role === Role.ADMIN ||
        permission.role === Role.SUPER_ADMIN
      ) {
        // do not show import button for now
        setShowImport(false);
      }
    }
  }, [permission]);

  return (
    <>
      <Col xs={{ span: 24 }} style={{ marginTop: "30px" }}>
        {alertVisible ? (
          <>
            <Alert
              message={alertMessage}
              type="info"
              showIcon
              closable
              onClose={() => {
                setAlertVisible(false);
              }}
            />
            <br />
            <br />
          </>
        ) : (
          ""
        )}
        {showImport ? (
          <>
            <StyledActionButtons>
              <StyledEventButton
                onClick={() => {
                  showLoader(true);
                  const executeImportProviderAppointments = executeImportProviderAppointmentsFunction();
                  executeImportProviderAppointments()
                    .then(() => {
                      setAlertMessage(
                        "Appointments import job triggered successfully, please refresh the Schedule page to see the imported appointments"
                      );
                      setAlertVisible(true);
                      showLoader(false);
                    })
                    .catch(() => {
                      setAlertMessage(
                        "Appointments import could not be triggered, please ensure that the data in the Intake Google sheet is in the right format"
                      );
                      setAlertVisible(true);
                      showLoader(false);
                    });
                }}
              >
                <FiDownloadCloud size={20} />
                <p>Import Appointments</p>
              </StyledEventButton>
            </StyledActionButtons>
            <br />
          </>
        ) : (
          ""
        )}
        <br />
        <ScheduleBarComponentScaffold
          day={currentDate.format("dddd")}
          otherDate={currentDate.format("MMMM D, yyyy")}
          displaySwitchButton
          hideSwitch
          onClick={() => {
            history.push("/provider/schedule/create-appointment");
          }}
        >
          <StyledSceduleBar>
            <CreateAppointment />
          </StyledSceduleBar>
        </ScheduleBarComponentScaffold>
      </Col>
      <Loader visible={loader} text="Fetching Appointments ... " />
    </>
  );
};
