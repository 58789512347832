import { Provider } from "../types/provider";
import { generateMedconHash } from "../utils/hash";
import { databaseRef } from "./firebase";
import logger from "../utils/logger";
import { Appointment } from "../types/appointment";
import { ApptValue } from "../types/appt-value";

export default class ProviderStore {
  providerPath: string;

  appointmentPath: string;

  session: string;

  constructor() {
    this.providerPath = "provider";
    this.appointmentPath = "appointments";
    this.session = "session";
  }

  async fetchProvider(email: string): Promise<Provider> {
    let response = {} as Provider;
    try {
      const identifier = await generateMedconHash(email);
      const providerDoc = await databaseRef
        .child(`${this.providerPath}/${identifier}`)
        .once("value");
      if (providerDoc.exists()) {
        response = providerDoc.val() as Provider;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return response;
  }

  async fetchProviders(): Promise<Provider[]> {
    let response = {} as Provider[];
    try {
      const providerDoc = await databaseRef
        .child(this.providerPath)
        .once("value");
      if (providerDoc.exists()) {
        response = providerDoc.val() as Array<Provider>;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return response;
  }

  async fetchProviderAppointments(id: string): Promise<Appointment[]> {
    let response = {} as Appointment[];
    try {
      const appointmentDoc = await databaseRef
        .child(`${this.providerPath}/${id}/${this.appointmentPath}`)
        .orderByChild("appointmentDateTs")
        .once("value");
      if (appointmentDoc.exists()) {
        response = appointmentDoc.val() as Array<Appointment>;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return response;
  }

  async getAppointments(
    startDateTs: number,
    endDateTs: number
  ): Promise<any[]> {
    const parsedAppointments = [] as any[];
    try {
      const doc = await databaseRef
        .child(this.providerPath)
        .orderByChild("appointmentDateTs")
        .once("value");
      if (doc.exists()) {
        const providersDoc = doc.val();
        Object.values(providersDoc).forEach((provider: any) => {
          if (provider && provider.appointments) {
            const appointments = provider.appointments as Appointment;
            Object.keys(appointments).forEach((appointmentId) => {
              const clientAppointments = appointments[appointmentId];
              Object.values(clientAppointments).forEach((appointment: any) => {
                if (
                  appointment.appointmentDateTs >= startDateTs &&
                  appointment.appointmentDateTs <= endDateTs
                ) {
                  parsedAppointments.push({
                    provider: provider.fullName,
                    ...appointment,
                  });
                }
              });
            });
          }
        });
      }
    } catch (err) {
      logger.error(err.message);
    }
    return parsedAppointments;
  }

  async getProviderAppointment(
    providerId: string,
    patientId: string,
    apptId: string
  ): Promise<ApptValue> {
    let response = {} as ApptValue;
    try {
      const appointmentDoc = await databaseRef
        .child(
          `${this.providerPath}/${providerId}/${this.appointmentPath}/${patientId}/${apptId}`
        )
        .orderByChild("appointmentDateTs")
        .once("value");

      if (appointmentDoc.exists()) {
        response = appointmentDoc.val() as ApptValue;
      }
    } catch (err) {
      logger.error(err.message);
    }

    return response;
  }

  async patchAppointment(
    appt: any,
    providerhash: string,
    patientId: string,
    apptId: string
  ): Promise<boolean> {
    let response: boolean = false;
    try {
      const providerRef = databaseRef.child(
        `${this.providerPath}/${providerhash}`
      );
      const providerDoc = await providerRef.once("value");
      if (providerDoc.exists()) {
        const apptRef = databaseRef.child(
          `${this.providerPath}/${providerhash}/${this.appointmentPath}/${patientId}`
        );

        await apptRef
          .update({ [apptId]: appt })
          .then(() => {
            response = true;
          })
          .catch((err) => {
            logger.error(err);
          });
      }
    } catch (err) {
      logger.error(err.message);
    }

    return response;
  }
}
