import { Col, Divider, Drawer, Row } from "antd";
import React, { useEffect, useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { FaSignOutAlt, FaUsers } from "react-icons/fa";
import {
  FcBarChart,
  FcCalendar,
  FcConferenceCall,
  FcHome,
  FcLock,
  FcAreaChart,
  FcVideoCall,
  FcSupport,
  FcDocument,
} from "react-icons/fc";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import { Logo } from "../../assets/svgs/Logo";
import { theme } from "../../assets/theme";
import { firebaseAuth } from "../../store/firebase";
import { getUserPermissionFunction } from "../../store/callableFunctions";
import ProviderStore from "../../store/provider";
import { FeatureFlag, Role, VisibilityStatus } from "../../types/enum";
import { Provider, ProviderPath } from "../../types/provider";
import { PermissionContext } from "../context/permissionContext";
import { ScreenNameContext } from "../context/screenNameContext";
import { WaitlistNotifications } from "./WaitlistNotifications";
import { getUniqueId } from "../../utils/hash";
import FeatureStore from "../../store/feature";
import { FeaturesContext } from "../context/featuresContext";
import { canAccessFeature } from "../../utils/feature-helper";

declare const window: any;

const StyledUpcomingEventScaffold = styled(Row)`
  /*  width: 100vw;
    min-height: 100vh; */
  background-color: #f7fafc;
  /*  margin: 0 auto; */
  /*    padding: 20px 0px; */
  /* display: flex;   */
`;

const StyledMainContainer = styled(Col)`
  /*  width: 100%; */

  padding: 20px 20px;

  @media ${device.laptop} {
    /*  width: 87%; */
    padding: 0px 60px 40px 40px;
  }
`;

const StyledSideBar = styled(Col)`
  display: none;
  background-color: #fff;
  min-height: 100vh;

  @media ${device.laptop} {
    /* width: 17%; */
    display: inline-block;
  }
`;

const StyledHeader = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* width: 100%; */

  /*
    margin: 0 auto; */

  @media ${device.laptop} {
    display: none;
  }
`;

const StyledNav = styled.div<{ isactive?: VisibilityStatus }>`
  background-color: ${(props) =>
    props.isactive === VisibilityStatus.Active
      ? `${theme.primaryColor}`
      : "transparent"};
  width: 70%;
  display: flex;
  align-items: center;
  border-radius: 0px 20px 20px 0px;
  color: ${theme.black};
  padding: 5px 0px 5px 25px;
  margin-top: 10px;
  font-size: 14px;

  @media ${device.laptop} {
    font-size: 1.2vw;
    padding: 10px 0px 10px 25px;
    border-radius: 0px 25px 25px 0px;
  }
`;

const StyledLink = styled(Link)<{ isactive?: VisibilityStatus }>`
  color: ${theme.black};
  margin-left: 20px;
  font-family: ${fontFamily.manrope};
  font-weight: ${(props) =>
    props.isactive === VisibilityStatus.Active ? "700" : "normal"};
  font-size: 14px;
  &:focus,
  &:hover {
    color: ${theme.black};
  }

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledTitle = styled.h1`
  font-family: ${fontFamily.heading};
  color: ${theme.black};
  font-size: 28px;
  text-align: center;
  margin-top: 10px;

  @media ${device.laptop} {
    font-size: 2vw;
  }
`;

const StyledTitleHeaderDiv = styled(Col)`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: calc(10px + (1vw));
`;

const StyledLogoDiv = styled.div`
  margin-left: 10px;
  margin-top: 40px;
  margin-bottom: 50px;
  width: 40%;
  & img {
    width: 30%;
  }
`;

export const ProviderModule = (props: { children: any }) => {
  const [visibleSideBar, setVisibleSideBar] = useState(false);
  const [currentPageName, setCurrentPageName] = useState("Overview");
  const [provider, setProvider] = useState({} as Provider);
  const history = useHistory();
  const browserLocation = useLocation();
  const [permission, setPermission] = useState(
    (browserLocation.state as any)?.permission
  );
  const [features, setFeatures] = useState([] as any);
  const [sidebarNav, setSidebarNav] = useState([] as any);
  // const [isNavigationSet, setIsNavigationSet] = useState(false);
  const [navigations, setNavigations] = useState([] as any);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (user: any) => {
      if (!user) {
        history.push("/");
      } else {
        // fetch provider information
        const providerStore = new ProviderStore();
        providerStore.fetchProvider(user.email).then((loginProvider) => {
          setProvider(loginProvider);

          // fetch permission if not available.
          if (!permission) {
            const getUserPermission = getUserPermissionFunction();
            getUserPermission({ email: user.email })
              .then((response) => {
                setPermission(response.data!);
              })
              .catch(() => {});
          }
        });

        // fetch features flags
        const featureStore = new FeatureStore();
        featureStore.getFeatures().then((allFeatures) => {
          setFeatures(allFeatures);
        });
      }
    });

    return () => {
      setProvider({} as Provider);
      setPermission({} as any);
      setFeatures([] as any);
    };
  }, []);

  const setMainNavigations = (
    name?: string,
    path?: string,
    appNavigations?: any
  ) => {
    // setIsNavigationSet(true);
    const mainNavigations = appNavigations || navigations;
    const sideBarNavigations = mainNavigations.map(
      (
        item: {
          name: string;
          icon: JSX.Element;
          path: ProviderPath;
        },
        index: any
      ) => {
        const key = `sidebarnav-${index}-${getUniqueId()}`;
        const canHighlight =
          item.name === (name || currentPageName)
            ? VisibilityStatus.Active
            : VisibilityStatus.Hidden;
        return (
          <StyledNav
            onClick={() => window.onNavClick(item.name, item.path)}
            isactive={canHighlight}
            key={key}
          >
            {item.icon}
            <StyledLink to={item.path} isactive={canHighlight}>
              {item.name}
            </StyledLink>
          </StyledNav>
        );
      }
    );
    setSidebarNav(sideBarNavigations);
    if (path) {
      setVisibleSideBar(false);
      history.push(path);
    }
  };

  useEffect(() => {
    if (permission && features) {
      let appNavigations = [
        {
          name: "Overview",
          icon: <FcHome />,
          path: ProviderPath.OVERVIEW,
        },
        {
          name: "Schedule",
          icon: <FcCalendar />,
          path: ProviderPath.SCHEDULE,
        },
        {
          name: "Clients",
          icon: <FcConferenceCall />,
          path: ProviderPath.PATIENT,
        },
        {
          name: "Meetings",
          icon: <FcVideoCall />,
          path: ProviderPath.MEETINGS,
        },
      ];

      // display note taking link if feature is enabled
      const canAccessNoteTaking = canAccessFeature(
        features,
        FeatureFlag.NOTETAKING
      );
      if (canAccessNoteTaking) {
        appNavigations.push({
          name: "Notes",
          icon: <FcDocument />,
          path: ProviderPath.NOTES,
        });
      }

      const adminNavigations = [
        {
          name: "Therapists",
          icon: <FaUsers />,
          path: ProviderPath.PROVIDER,
        },
        {
          name: "Analytics",
          icon: <FcAreaChart />,
          path: ProviderPath.ANALYTICS,
        },
        {
          name: "Permissions",
          icon: <FcLock />,
          path: ProviderPath.PERMISSIONS,
        },
        {
          name: "Reports",
          icon: <FcBarChart />,
          path: ProviderPath.REPORTS,
        }
      ];

      if (
        permission.role === Role.SUPER_ADMIN ||
        permission.role === Role.ADMIN
      ) {
        appNavigations = appNavigations.concat(adminNavigations);
      }

      appNavigations.push({
        name: "Settings",
        icon: <FcSupport />,
        path: ProviderPath.APP_INTEGRATION,
      });

      setNavigations(appNavigations);
      setMainNavigations("", "", appNavigations);
    }
  }, [permission, features]);

  const handleOpenSideBar = () => {
    setVisibleSideBar(true);
  };

  const onCloseSideBar = () => {
    setVisibleSideBar(false);
  };

  window.onNavClick = (name: string, path: string) => {
    setCurrentPageName(name);
    setMainNavigations(name, path);
  };

  return (
    <ScreenNameContext.Provider value={{ setCurrentPageName }}>
      <PermissionContext.Provider value={{ permission }}>
        <FeaturesContext.Provider value={{ features }}>
          <StyledUpcomingEventScaffold>
            <StyledSideBar lg={{ span: 4 }}>
              <StyledLogoDiv>
                <Logo />
              </StyledLogoDiv>
              {sidebarNav}
              <Divider />
              <StyledNav>
                <FaSignOutAlt />
                <StyledLink
                  onClick={async () => {
                    await firebaseAuth.signOut();
                    history.push("/");
                  }}
                  to="#"
                >
                  Logout
                </StyledLink>
              </StyledNav>
            </StyledSideBar>
            <StyledMainContainer lg={{ span: 20 }}>
              <Row>
                <StyledHeader xs={{ span: 24 }}>
                  <StyledLogoDiv style={{ width: "20%" }}>
                    <Logo />
                  </StyledLogoDiv>
                  <FiMenu onClick={handleOpenSideBar} size="35" />
                </StyledHeader>
                <StyledTitleHeaderDiv xs={{ span: 24 }}>
                  <StyledTitle>{currentPageName}</StyledTitle>
                  {provider.id && (
                    <WaitlistNotifications
                      providerId={provider.id}
                      fullName={provider.fullName}
                    />
                  )}
                </StyledTitleHeaderDiv>
                <Divider />
                {props.children}
                <Drawer
                  title={
                    <StyledLogoDiv>
                      <Logo />
                    </StyledLogoDiv>
                  }
                  placement="left"
                  closable={false}
                  onClose={onCloseSideBar}
                  visible={visibleSideBar}
                  closeIcon={<FiX />}
                  bodyStyle={{ padding: "0px", position: "relative" }}
                >
                  {sidebarNav}
                  <Divider />
                  <StyledNav>
                    <FaSignOutAlt />
                    <StyledLink
                      onClick={async () => {
                        await firebaseAuth.signOut();
                        history.push("/");
                      }}
                      to="#"
                    >
                      Logout
                    </StyledLink>
                  </StyledNav>
                </Drawer>
              </Row>
            </StyledMainContainer>
          </StyledUpcomingEventScaffold>
        </FeaturesContext.Provider>
      </PermissionContext.Provider>
    </ScreenNameContext.Provider>
  );
};
