import { Badge, Popover } from "antd";
import React from "react";
import { FiBell, FiStopCircle } from "react-icons/fi";
import styled from "styled-components";
import { device } from "../../assets/breakbpoints";
import { theme } from "../../assets/theme";

const StyledPopover = styled(Popover)`
  border-radius: 10px;
`;

const StyledAlertAndButtondiv = styled.div<{ isProvider?: boolean }>`
  display: ${(props) => (props.isProvider ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media ${device.laptop} {
    display: flex;
  }
`;

const StyledAlertDiv = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
`;

const StyledFiBell = styled(FiBell)`
  color: ${theme.mutedColor};
`;

export const Alert = (props: {
  isProvider?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => (
  <StyledAlertAndButtondiv
    isProvider={props.isProvider}
    onClick={props.onClick}
  >
    <StyledAlertDiv>
      <StyledFiBell size={30} />
      <Badge
        count={23}
        style={{
          padding: "0px 11px 0px 11px",
          backgroundColor: props.isProvider ? "#DBEDE3" : "#FFE6E4",
          color: props.isProvider ? "#27AE60" : "#F16063",
          fontWeight: 700,
          borderColor: props.isProvider ? "#DBEDE3" : "#FFE6E4",
        }}
      />
    </StyledAlertDiv>
  </StyledAlertAndButtondiv>
);

export const AlertWithPopOver = (props: {
  notificationCount?: number;
  popContent: JSX.Element;
  popTitle: JSX.Element;
  isProvider?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => (
  <StyledPopover
    placement="bottomRight"
    content={props.popContent}
    title={props.popTitle}
  >
    <StyledAlertAndButtondiv
      isProvider={props.isProvider}
      onClick={props.onClick}
    >
      <StyledAlertDiv>
        <StyledFiBell size={30} />
        {Boolean(props.notificationCount) && (
          <Badge
            count={props.notificationCount}
            style={{
              padding: "0px 11px 0px 11px",
              backgroundColor: props.isProvider ? "#DBEDE3" : "#FFE6E4",
              color: props.isProvider ? "#27AE60" : "#F16063",
              fontWeight: 700,
              borderColor: props.isProvider ? "#DBEDE3" : "#FFE6E4",
            }}
          />
        )}
      </StyledAlertDiv>
    </StyledAlertAndButtondiv>
  </StyledPopover>
);

export const StyledHighlightedMessage = styled.div`
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #414457;
  font-size: 14px;
  border: 1px solid rgba(234, 43, 34, 0.24);
  border-radius: 5px;
  margin-bottom: 20px;
  min-width: fit-content;
  width: inherit;

  & p {
    margin-top: 5px;
    padding-left: 10px;
  }
  & h2 {
    margin-top: 5px;
    font-size: 14px;
    padding-left: 35px;
  }
`;

export const StyledMessage = styled.div`
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #414457;
  font-size: 14px;
  box-shadow: 0px 0px 1.73853px rgba(32 230 47 / 24%),
    0px 5.2156px 13.9083px -1.73853px rgba(50, 50, 71, 0.05);
  border-radius: 5px;
  margin-bottom: 20px;
  min-width: fit-content;
  width: inherit;
`;

export const StyledFiStopCircle = styled(FiStopCircle)`
  color: #ff6666;
  margin-right: 10px;
`;
