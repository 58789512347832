import React, { useEffect, useContext, useState } from "react";
import { Col } from "antd";
import {
  FcGoogle
} from "react-icons/fc";
import { Loader } from "../elements/Loader";
import { useScreenNameContext } from "../context/screenNameContext";
import { PermissionContext } from "../context/permissionContext";
import { StyledCard, StyledStatContainer, StyledTileCard } from "./atoms/Elements";



export const AppIntegration = () => {
  const { permission } = useContext(PermissionContext) as any;
  const { setCurrentPageName } = useScreenNameContext() as any;
  const [loader, showLoader] = useState(true);
  const [googleIntergationLink, setGoogleIntergationLink] = useState("");

  useEffect(() => {
    setCurrentPageName("Apps & Integrations");
    if (permission) {
      showLoader(false);
      const url = `https://www.google.com/calendar/render?cid=webcal://${process.env.REACT_APP_CALENDAR_FEED_URL}?id=${permission.id}`;
      setGoogleIntergationLink(url);
    }
  }, [permission]);


  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 20 }}>
       <StyledStatContainer>
        <div>
          <StyledCard>
            <StyledTileCard
              title="Google Calendar"
              icon={< FcGoogle
                size={50} />}
              description="Sync appointments on Telemedicine platform with your Google Calendar. 
              Click the Connect link below to get started..."
              onClick={() => {                
                window.open(googleIntergationLink, '_blank').focus();
              }}
              tabIndex={0}
            />
          </StyledCard>
        </div>
      </StyledStatContainer>

      </Col>
      <Loader visible={loader} text="..." />
    </>
  );
};
