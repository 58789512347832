import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Input, Select, Form, Alert } from "antd";
import queryString from "query-string";
import styled from "styled-components";
import { Loader } from "../elements/Loader";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import { Role } from "../../types/enum";
import {
  getUserPermissionFunction,
  updateUserPermissionFunction,
} from "../../store/callableFunctions";
import { PermissionContext } from "../context/permissionContext";
import { useScreenNameContext } from "../context/screenNameContext";
import { ApptButtonComponent } from "../elements/PrimaryButtonComponent";
import { BackButton } from "./atoms/BackButton";

const StyledPermissionForm = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 51px;
  margin-top: 30px;

  & h1 {
    color: #131523;
    font-size: 16px;
    font-family: ${fontFamily.Inter};
    font-weight: 700;
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.2vw;
    }
  }
`;

export const PermissionsUpdate = (): JSX.Element => {
  const history = useHistory();
  const { setCurrentPageName } = useScreenNameContext() as any;
  const { permission } = useContext(PermissionContext);

  const [loader, showLoader] = useState(true);
  const [loaderText, setLoaderText] = useState("Loading ...");
  const browserLocation = useLocation();
  const parsedQueryParams = queryString.parse(browserLocation.search || "");
  const [userPermission, setUserPermission] = useState({} as any);
  const [alertVisible, setAlertVisible] = useState(false);
  const [email] = useState(parsedQueryParams.email as string);
  const [fullName] = useState((parsedQueryParams.fullName as string) || "");
  const [role, setRole] = useState("");
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    if (permission && permission.role === Role.PROVIDER) {
      history.push("/provider/overview", { permission });
    }
  }, [permission]);

  useEffect(() => {
    setCurrentPageName("Access Control & Permissions");
    const getUserPermission = getUserPermissionFunction();
    getUserPermission({ email }).then((response: any) => {
      const fetchedPermission = response.data;
      setLocked(fetchedPermission.locked);
      setRole(fetchedPermission.role);
      setUserPermission(fetchedPermission);
      showLoader(false);
    });
  }, []);

  const updatePermission = () => {
    if (userPermission) {
      setLoaderText("Saving user permission details");
      showLoader(true);
      const updateUserPermission = updateUserPermissionFunction();
      updateUserPermission({
        id: userPermission.id,
        role,
        email: userPermission.email,
        createdDate: userPermission.createdDate,
        locked,
      }).then((response: any) => {
        setAlertVisible(response.data);
        showLoader(false);
        setLoaderText("Loading ...");
      });
    }
  };

  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 20 }}>
        <BackButton />

        {alertVisible ? (
          <>
            <br />
            <Alert
              message="User permission and access updated successfully"
              type="success"
              showIcon
              closable
              onClose={() => {
                setAlertVisible(false);
              }}
            />
          </>
        ) : (
          ""
        )}
        <StyledPermissionForm>
          <h1>User Information</h1>

          <Form labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
            <Form.Item label="User" style={{ float: "left", width: "50%" }}>
              <Input readOnly type="text" value={fullName} />
            </Form.Item>

            <Form.Item label="Email" style={{ float: "left", width: "50%" }}>
              <Input readOnly type="text" value={email} />
            </Form.Item>

            <Form.Item label="Role" style={{ float: "left", width: "50%" }}>
              <Select value={role} onChange={(e: any) => setRole(e)}>
                {Object.values(Role).map((value: any) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Locked" style={{ float: "left", width: "50%" }}>
              <Select
                value={locked.toString()}
                onChange={(e: any) => setLocked(e === "true")}
              >
                <Select.Option value="true">true</Select.Option>
                <Select.Option value="false">false</Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <div style={{ textAlign: "center" }}>
            <ApptButtonComponent
              buttonText="Update"
              width="50%"
              onClick={updatePermission}
            />
          </div>
        </StyledPermissionForm>
      </Col>
      <Loader visible={loader} text={loaderText} />
    </>
  );
};
