import { Alert, Col, Divider, Layout, Row } from "antd";
import { EligibilityCheckResult } from "medcon_types/ChangeHealthCare";
import React from "react";
import BenefitsDisplay from "./BenefitsDisplay";
import DependentsDisplay from "./DependentsDisplay";
import SubscriberDisplay from "./SubscriberDisplay";

function EligibilityCheckResultDisplay({
  result,
}: {
  result: EligibilityCheckResult;
}) {
  const { subscriber, dependents, matchingBenefits, allBenefits } = result;
  const { status: planStatus } =
    (result.planStatus || []).find((r) => r.planDetails) || {};
  return (
    <Layout.Content>
      <Divider>
        {subscriber.firstName} {subscriber.lastName} / {planStatus}
      </Divider>
      {!subscriber.firstName &&
      allBenefits.length < 1 &&
      matchingBenefits.length < 1 ? (
        <Alert
          type="warning"
          message="Unable to find this client insurance information. Please confirm the insurance details are correct and retry."
        />
      ) : (
        <></>
      )}
      {subscriber && (
        <Row>
          <Col span={20} offset={2}>
            <SubscriberDisplay subscriber={subscriber} />
          </Col>
        </Row>
      )}
      {dependents && (
        <Row>
          <Col span={20} offset={2}>
            <DependentsDisplay dependents={dependents} />
          </Col>
        </Row>
      )}
      {(matchingBenefits || allBenefits) && (
        <Row>
          <Col span={20} offset={2}>
            <BenefitsDisplay
              matchingBenefits={matchingBenefits}
              allBenefits={allBenefits}
            />
          </Col>
        </Row>
      )}
    </Layout.Content>
  );
}

export default EligibilityCheckResultDisplay;
