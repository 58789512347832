import React from "react";
import {
  StyledBlackTd,
  StyledfadeTd,
  StyledSpecialFontTd,
} from "../styles/tableRowStyle";
import { StyledButton } from "./StyledButton";
import { getUniqueId } from "../../../utils/hash";

export const TableRow = (props: {
  data: {
    text: string;
    field1: string;
    field2: string;
    field3: string;
    actions?: any[];
  };
}) => (
  <tr>
    <StyledBlackTd  data-ybug-sensitive className="fs-mask">{props.data.text}</StyledBlackTd>
    <StyledfadeTd  data-ybug-sensitive className="fs-mask">{props.data.field1}</StyledfadeTd>
    <td data-ybug-sensitive className="fs-mask">{props.data.field2}</td>
    <StyledSpecialFontTd className="specialFont fs-mask"  data-ybug-sensitive>
      {props.data.field3}
    </StyledSpecialFontTd>
    {props.data.actions ? (
      <>
        <td>
          {Object.values(props.data.actions).map((action: any) => {
            const rowAction =
              action.type === "link" ? (
                <a href={action.handler(action.param)}>
                  {action.text}
                  {action.icon}
                </a>
              ) : (
                <StyledButton
                  key={`${getUniqueId()}`}
                  icon={action.icon}
                  text={action.text}
                  title={action.title!}
                  onClick={() => {
                    action.handler(action.param);
                  }}
                />
              );
            return rowAction;
          })}
        </td>
      </>
    ) : (
      ""
    )}
  </tr>
);
