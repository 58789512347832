export enum ClaimsPath {
    ELIGIBILITY_CHECK = "/claims/eligibility-check",
  }

export enum MedconAnalyiticsEvent {
  ELIGIBILITY_CHECK_REQUEST = "client_eligibility_request",
  ELIGIBILITY_CHECK_FAIL = "client_eligibility_fail",
  ELIGIBILITY_CHECK_NO_MENTAL_HEALTH = "client_eligibility_no_mental_health",
  ELIGIBILITY_CHECK_NO_BENEFITS = "client_eligibility_no_benefits"
  }

export type MedconAnalyiticsEventData = { [key: string]: any}