import React from "react";
import { Input, Button } from "antd";
import { Select } from "@chakra-ui/react";
import { Progress } from "../../../types/enum";
import { theme } from "../../../assets/theme";

export const MiddleStep = (props: any) => {
  const middleHeader = "Treatment Plan / Progress";
  return (
    <div style={{ padding: "10px 10px" }}>
      <h3>{middleHeader}</h3>
      <br />
      <p>
        <Input.Group compact>
          <Input
            disabled
            value="Goals"
            style={{
              width: "50%",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
              fontWeight: "bold",
            }}
          />
          <Input
            disabled
            value="Progress"
            style={{
              width: "50%",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
              fontWeight: "bold",
            }}
          />
        </Input.Group>
      </p>
      <p>
        <Input.Group style={{ width: "100%" }}>
          <Input
            disabled
            value="Emotional Stability"
            style={{
              width: "50%",
              height: "35px",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
            }}
          />
          <Select
            name="emotionalStability"
            style={{ width: "50%", height: "35px" }}
            value={props.getState("emotionalStability", "")}
            onChange={props.handleChange}
          >
            <option>Select...</option>
            {Object.values(Progress).map((emotionalStability) => (
              <option key={emotionalStability} value={emotionalStability}>
                {emotionalStability}
              </option>
            ))}
          </Select>
        </Input.Group>
      </p>
      <p>
        <Input.Group style={{ width: "100%" }}>
          <Input
            disabled
            value="Career"
            style={{
              width: "50%",
              height: "35px",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
            }}
          />
          <Select
            name="career"
            style={{ width: "50%", height: "35px" }}
            value={props.getState("career", "")}
            onChange={props.handleChange}
          >
            <option>Select...</option>
            {Object.values(Progress).map((career) => (
              <option key={career} value={career}>
                {career}
              </option>
            ))}
          </Select>
        </Input.Group>
      </p>
      <p>
        <Input.Group style={{ width: "100%" }}>
          <Input
            disabled
            value="Social Anxiety"
            style={{
              width: "50%",
              height: "35px",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
            }}
          />
          <Select
            name="socialAnxiety"
            style={{ width: "50%", height: "35px" }}
            value={props.getState("socialAnxiety", "")}
            onChange={props.handleChange}
          >
            <option>Select...</option>
            {Object.values(Progress).map((socialAnxiety) => (
              <option key={socialAnxiety} value={socialAnxiety}>
                {socialAnxiety}
              </option>
            ))}
          </Select>
        </Input.Group>
      </p>
      <p>
        {props.hasPrev() && (
          <Button
            onClick={props.prev}
            style={{
              backgroundColor: theme.primaryColor,
              color: theme.black,
              marginRight: "10px",
            }}
          >
            Previous
          </Button>
        )}
        {props.hasNext() && (
          <Button
            onClick={props.next}
            style={{ backgroundColor: theme.primaryColor, color: theme.black }}
          >
            Next
          </Button>
        )}
      </p>
    </div>
  );
};
