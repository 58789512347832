import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { Select } from "@chakra-ui/react";
import { MultiSelect } from "react-multi-select-component";
import {
  RequestedServiceType,
  AppointmentType,
  LevelOfFunctioning,
  Stressors,
  ClinicalCodes,
} from "../../../types/enum";
import { theme } from "../../../assets/theme";

export const InitialStep = (props: any) => {
  const initialHeader = "Diagnosis as derived from clinical assessment";
  const [options, setOptions] = useState<any[]>([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const clinicalCodes = Object.entries(
      ClinicalCodes
    ).map(([value, label]) => ({ value, label }));
    setOptions(clinicalCodes);
  }, []);

  function PreserveClinicalCodes() {
    const selectedCodes = selected.reduce(
      (acc, { value, label }) => ({ ...acc, [value]: label }),
      {}
    );

    props.next();
    props.setState("clinicalCodes", selectedCodes);
  }

  return (
    <div style={{ padding: "10px 10px" }}>
      <h3>{initialHeader}</h3>
      <br />
      <p>
        <h4>Select ICD / CPT Codes</h4>
        <MultiSelect
          options={options}
          value={selected}
          onChange={setSelected}
          labelledBy="Select"
        />
      </p>
      <p>
        <Input.Group style={{ width: "100%" }}>
          <Input
            disabled
            value="Modality"
            style={{
              width: "40%",
              height: "35px",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
            }}
          />
          <Select
            name="modality"
            style={{ width: "60%", height: "35px" }}
            value={props.getState("modality", "")}
            onChange={props.handleChange}
          >
            <option>Select...</option>
            {Object.values(RequestedServiceType).map((modality) => (
              <option key={modality} value={modality}>
                {modality}
              </option>
            ))}
          </Select>
        </Input.Group>
      </p>
      <p>
        <Input.Group style={{ width: "100%" }}>
          <Input
            disabled
            value="Session Format"
            style={{
              width: "40%",
              height: "35px",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
            }}
          />
          <Select
            name="sessionFormat"
            style={{ width: "60%", height: "35px" }}
            value={props.getState("sessionFormat", "")}
            onChange={props.handleChange}
          >
            <option>Select...</option>
            {Object.values(AppointmentType).map((sessionFormat) => (
              <option key={sessionFormat} value={sessionFormat}>
                {sessionFormat.toUpperCase()}
              </option>
            ))}
          </Select>
        </Input.Group>
      </p>
      <p>
        <Input.Group style={{ width: "100%" }}>
          <Input
            disabled
            value="Start Time"
            style={{
              width: "40%",
              height: "35px",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
            }}
          />
          <input
            type="time"
            name="startTime"
            style={{ width: "60%", height: "35px" }}
            value={props.getState("startTime", "")}
            onChange={props.handleChange}
          />
        </Input.Group>
      </p>
      <p>
        <Input.Group style={{ width: "100%" }}>
          <Input
            disabled
            value="End Time"
            style={{
              width: "40%",
              height: "35px",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
            }}
          />
          <input
            type="time"
            name="endTime"
            style={{ width: "60%", height: "35px" }}
            value={props.getState("endTime", "")}
            onChange={props.handleChange}
          />
        </Input.Group>
      </p>
      <p>
        <Input.Group style={{ width: "100%" }}>
          <Input
            disabled
            value="Level Of Functioning"
            style={{
              width: "40%",
              height: "35px",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
            }}
          />
          <Select
            name="levelOfFunctioning"
            style={{ width: "60%", height: "35px" }}
            value={props.getState("levelOfFunctioning", "")}
            onChange={props.handleChange}
          >
            <option>Select...</option>
            {Object.values(LevelOfFunctioning).map((levelOfFunctioning) => (
              <option key={levelOfFunctioning} value={levelOfFunctioning}>
                {levelOfFunctioning}
              </option>
            ))}
          </Select>
        </Input.Group>
      </p>
      <p>
        <Input.Group style={{ width: "100%" }}>
          <Input
            disabled
            value="Stressors"
            style={{
              width: "40%",
              height: "35px",
              color: "rgba(0, 0, 0, 0.65)",
              cursor: "auto",
            }}
          />
          <Select
            name="stressors"
            style={{ width: "60%", height: "35px" }}
            value={props.getState("stressors", "")}
            onChange={props.handleChange}
          >
            <option>Select...</option>
            {Object.values(Stressors).map((stressors) => (
              <option key={stressors} value={stressors}>
                {stressors}
              </option>
            ))}
          </Select>
        </Input.Group>
      </p>
      <p>
        {props.hasPrev() && (
          <Button
            onClick={props.prev}
            style={{
              backgroundColor: theme.primaryColor,
              color: theme.black,
              marginRight: "10px",
            }}
          >
            Previous
          </Button>
        )}
        {props.hasNext() && (
          <Button
            onClick={PreserveClinicalCodes}
            style={{ backgroundColor: theme.primaryColor, color: theme.black }}
          >
            Next
          </Button>
        )}
      </p>
    </div>
  );
};
