import * as Sentry from "@sentry/react";
import "antd/dist/antd.css";
import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/styles/index.css";
import reportWebVitals from "./utils/report-web-vitals";
import { sendToGoogleAnalytics } from "./utils/web-analytics";

declare const document: any;

Sentry.init({
  dsn: "https://e3802f9daf356b05660d470bf660e029@o4507210472947712.ingest.us.sentry.io/4507210481139712",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);
