import React from "react";
import styled from "styled-components";
import { fontFamily } from "../../assets/fontFamily";
import { theme } from "../../assets/theme";
import { device } from "../../assets/breakbpoints";

const StyledOnBoardingTopText = styled.div`
  margin-top: 40px;
  & h1 {
    padding: 0;
    margin-top: 40px;
    font-size: 48px;
    font-family: ${fontFamily.heading};
    font-weight: 500;
    line-height: 50px !important;

    & img {
      width: 40px;
      padding-left: 10px;
      margin-right: 10px;
    }

    & span {
      font-weight: 100;
      color: ${theme.secondaryColor};
    }
  }

  & p {
    font-family: ${fontFamily.body};
    font-weight: 400;
    color: ${theme.normalText};
    font-size: 16px;
    margin-left: 5px;
    margin-top: 20px;
  }

  @media ${device.tablet} {
    & h1 {
      font-size: 3.3vw;
    }
  }

  @media ${device.laptop} {
    margin-top: 20px;
  }

  @media ${device.desktop} {
    & p {
      font-size: 1vw;
    }
  }
`;

export const OnBoardingTopText = (props: {
  prefixText?: string;
  segment?: string;
  continousSeg?: string;
  logo?: string;
  botMessage?: string;
  newLine?: string;
  checkinMargin?: string;
}) => (
  <StyledOnBoardingTopText>
    <h1>
      {props.prefixText}
      &nbsp;
      <span>{props.segment}</span>
      &nbsp;
      {props.continousSeg}
    </h1>
    <p style={{ marginTop: `${props.checkinMargin}px` }}>
      {props.botMessage}
      <br />
      {props.newLine}
    </p>
  </StyledOnBoardingTopText>
);
