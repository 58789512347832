import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { BackButton } from "../atoms/BackButton";
import { ReportContainer } from "./ReportContainer";
import { SessionDetailsBody } from "./SessionDetailsBody";
import { PermissionContext } from "../../context/permissionContext";
import { StyledGroupHead, ReportGroupHeader } from "./ReportAssets";
import { Loader } from "../../elements/Loader";
import SessionStore from "../../../store/session";
import TSession from "../../../types/session";
import { formatWithCurrency, getUniqueId } from "../../../utils/hash";
import { Role, AppointmentType } from "../../../types/enum";

export const InPersonSessionDetailsReports = () => {
  const [loader, showLoader] = useState(true);
  const dateFormat = "MM-DD-YYYY";
  const [reportStartDate, setReportStartDate] = useState(
    moment().startOf("month")
  );
  const [reportEndDate, setReportEndDate] = useState(moment().endOf("month"));
  const reportTitle = "In-Person Session Details Report";
  const reportFileName = "teleconsult-session-details-report.csv";
  const groupByFilter = ["Client", "Provider", "Date"];
  const [sessionData, setSessionData] = useState([] as any);
  const [reportBody, setReportBody] = useState([] as any);
  const reportHeaders = [
    "Client",
    "Service",
    "Location",
    "Status",
    "Time",
    "Provider",
    "Copay",
  ];

  const history = useHistory();
  const { permission } = useContext(PermissionContext);

  useEffect(() => {
    if (permission && permission.role === Role.PROVIDER) {
      history.push("/provider/overview", { permission });
    }
  }, [permission]);

  const processReportData = (orderedSessions: any[]) => {
    const headers = reportHeaders.map((item, index) => {
      const key = `thkey-${index}-${getUniqueId()}`;
      return <th key={key}>{item}</th>;
    });

    const groupedSessions = _.groupBy(orderedSessions, "date");
    const sessionsElements: any[] = [];
    Object.keys(groupedSessions).forEach((sessionDate: any) => {
      // Group header, by date
      sessionsElements.push(
        <tr>
          <td colSpan={7}>
            <ReportGroupHeader>
              {moment(sessionDate).format("MMMM, D yyyy")}
            </ReportGroupHeader>
          </td>
        </tr>
      );

      // Group row header
      sessionsElements.push(<StyledGroupHead>{headers}</StyledGroupHead>);

      const groupedSession = groupedSessions[sessionDate];

      Object.values(groupedSession).forEach((session: any) => {
        const key = `curr-data-${getUniqueId()}`;
        sessionsElements.push(<SessionDetailsBody key={key} data={session} />);
      });
    });

    setReportBody(sessionsElements);
    showLoader(false);
  };

  const fetchSessionsByDate = (startDate: any, endDate: any) => {
    showLoader(true);
    const sessionStore = new SessionStore();
    sessionStore
      .getSessionsByDate(startDate.valueOf(), endDate.valueOf())
      .then((sessions: TSession[]) => {
        const parsedSessions = [] as any[];

        Object.values(sessions).forEach((session: TSession) => {
          const sessionDate = moment(session.sessionStartDate);
          if (session.appointmentType === AppointmentType.WalkIn) {
            parsedSessions.push({
              client: session.patientName,
              service: session.requestedService,
              location: session.location,
              timeStamp: sessionDate.format("HH:mm"),
              copay: formatWithCurrency(session.amount, session.currency),
              status: session.sessionStatus,
              provider: session.providerName,
              date: sessionDate.format("YYYY-MM-DD"), // format date for easy sorting
            });
          }
        });

        // group data by date and order desc and group by date
        const orderedSessions = _.orderBy(
          parsedSessions,
          ["date", "timeStamp"],
          "desc"
        );
        setSessionData(orderedSessions);
        processReportData(orderedSessions);
      });
  };

  useEffect(() => {
    fetchSessionsByDate(reportStartDate, reportEndDate);
  }, [dateFormat]);

  const filterByDate = (
    startDate: any,
    endDate: any,
    processDataHandler: any
  ) => {
    setReportStartDate(startDate);
    setReportEndDate(endDate);
    showLoader(true);
    if (startDate && endDate) {
      fetchSessionsByDate(startDate, endDate);
    } else {
      processDataHandler(sessionData);
    }
  };
  return (
    <>
      <BackButton />
      <ReportContainer
        reportTitle={reportTitle}
        dateFormat={dateFormat}
        showStartDate
        showEndDate
        showGroupBy={false}
        showApply
        showPrint
        showExport
        groupByList={groupByFilter}
        reportBody={reportBody}
        data={sessionData}
        reportFileName={reportFileName}
        filterHandler={filterByDate}
        dataHandler={processReportData}
        reportStartDate={reportStartDate}
        reportEndDate={reportEndDate}
      />

      <Loader visible={loader} text={`Loading ${reportTitle} ...`} />
    </>
  );
};
