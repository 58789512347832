import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaWifi } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import { MiddleIcon } from "../../../assets/svgs/MiddleIcon";
import { theme } from "../../../assets/theme";
import { databaseRef, firebaseAuth } from "../../../store/firebase";
import MeetingStore from "../../../store/meeting";
import { MeetingStatus } from "../../../types/enum";
import { Meeting } from "../../../types/meeting";
import { ProviderPath } from "../../../types/provider";
import { WaitlistNotifications } from "../../provider/WaitlistNotifications";
import ConferenceCall from "./ConferenceCall";

declare const Ybug: any;
declare const FS: any;

interface ToolBarProps {
  isProvider?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isActive?: boolean;
  sessionTitle: string;
  noOfParticipants: number;
  sessionStartTime: string;
  notificationComponent: JSX.Element;
}

const StyledToolBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  & h1 {
    font-size: 24px;
    font-family: ${fontFamily.heading};
    font-weight: 500;
    margin: 0px;
  }
  & > div {
    margin-left: 30px;
  }
  @media ${device.laptop} {
    flex-wrap: nowrap;
  }
`;

const StyledWifi = styled.div<{ isActive?: boolean }>`
  display: flex;
  color: ${(props) => (props.isActive ? "#EB5757" : theme.mutedColor)};
  align-items: center;
  justify-content: center;
  height: fit-content;
  font-weight: 500;
  font-family: ${fontFamily.body};
  & p {
    margin: 0px;
    margin-left: 8px;
  }
`;

const StyledControlButton = styled.button`
  outline: none;
  box-shadow: none;
  border: none;
  background-color: #fff;
  display: flex;
  place-items: center;
  cursor: pointer;
  padding: 12px 12px;
  font-size: 16px;
  border-radius: 4px;
  &:focus,
  &:hover {
    outline: none;
    box-shadow: none;
    border: none;
  }
  @media ${device.laptop} {
    font-size: 1.2vw;
  }
`;

const StyledCallEnded = styled.div`
  background-color: #f2f5f8;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCallEndedNotification = styled.div`
  width: 95%;
  & > div:first-child {
    width: 100%;
    background-color: #f6faff;
    height: 10vh;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    & > div {
      position: absolute;
      bottom: -35%;
      height: fit-content;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  & > div:last-child {
    text-align: center;
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
    padding-bottom: 20px;
    padding-top: 40px;
    & h1 {
      font-weight: 600;
      font-family: ${fontFamily.body};
      font-size: 26px;
    }
    & p {
      font-size: 16px;
      font-family: ${fontFamily.body};
      font-weight: 400;
      color: ${theme.mutedColor};
      margin-bottom: 30px;
    }
    & div {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      & button:last-child {
        margin-left: 10px;
      }
    }
  }
  @media ${device.laptop} {
    width: 35%;
    & > div:last-child {
      & h1 {
        font-weight: 600;
        font-family: ${fontFamily.body};
        font-size: 1.5vw;
      }
      & p {
        font-size: 1.1vw;
      }
    }
  }
  @media ${device.desktop} {
    & > div:first-child {
      height: 7vh;
      & > div {
        bottom: -25%;
      }
    }
  }
`;

const StyledHomePageButton = styled(StyledControlButton)`
  background-color: ${theme.secondaryColor};
  color: #fff;
`;

const StyledSignOutButton = styled(StyledControlButton)`
  background-color: #eb5757;
  color: #fff;
`;

const StyledChatBox = styled.div`
  height: 80vh;
  width: 100%;
  position: relative;
  margin-top: 20px;
  border-radius: 20px;
  overflow: hidden;
`;

const SessionRoomDiv = styled.div`
  padding-top: 3px;
  padding-left: 35px;
  padding-right: 35px;
`;

const MediumToolBar = (props: ToolBarProps) => (
  <StyledToolBar>
    <div
      style={{
        width: "100%",
        marginLeft: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <h1>{props.sessionTitle}</h1>
      <div>{props.notificationComponent}</div>
    </div>
    <div
      style={{
        marginLeft: "0px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <StyledWifi isActive={props.isActive}>
        <FaWifi />
        <p>{props.isActive ? "Live" : "Ended"}</p>
      </StyledWifi>
    </div>
  </StyledToolBar>
);

const LargeToolBar = (props: ToolBarProps) => (
  <StyledToolBar>
    <h1>{props.sessionTitle}</h1>
    <StyledWifi isActive={Boolean(props.isActive)}>
      <FaWifi />
      <p>{props.isActive ? "Live" : "Ended"}</p>
    </StyledWifi>
    <div style={{ marginLeft: "auto" }} />
    {props.notificationComponent}
  </StyledToolBar>
);

const MiniToolBar = (props: ToolBarProps) => (
  <StyledToolBar>
    <div style={{ display: "flex", alignItems: "center", marginLeft: "0px" }}>
      <h1>{props.sessionTitle}</h1>
      {props.notificationComponent}
    </div>
    <div
      style={{
        marginTop: "20px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginLeft: "0px",
        justifyContent: "space-between",
      }}
    >
      <StyledWifi isActive={props.isActive}>
        <FaWifi />
        <p>{props.isActive ? "Live" : "Ended"}</p>
      </StyledWifi>
    </div>
  </StyledToolBar>
);

const CallEnded = ({
  isProvider,
  onSignOut,
  onGoHome,
}: {
  isProvider?: boolean;
  onSignOut?: () => void;
  onGoHome: () => void;
}) => (
  <StyledCallEnded>
    <StyledCallEndedNotification>
      <div>
        <div>
          <MiddleIcon />
        </div>
      </div>
      <div>
        <h1>Session Ended</h1>
        <p>{isProvider ? "" : "Thank you for choosing Humantold"}</p>
        <div>
          <StyledHomePageButton onClick={onGoHome}>
            {isProvider ? "Return to Dashboard" : "Return to Homepage"}
          </StyledHomePageButton>
          {!isProvider && (
            <StyledSignOutButton onClick={() => onSignOut && onSignOut()}>
              Sign out
            </StyledSignOutButton>
          )}
        </div>
      </div>
    </StyledCallEndedNotification>
  </StyledCallEnded>
);

export const GroupMeetingRoom = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userType,
}: {
  userType: "patient" | "provider";
}) => {
  const history = useHistory();
  const browserLocation = useLocation();
  const [toolBarType, setToolBarType] = useState("large");
  const [clientEndedCall, setClientEndedCall] = useState(false);
  const [currentMeeting, setCurrentMeeting] = useState<Meeting>({} as Meeting);
  const [meetingDetails] = useState(
    (browserLocation.state as any)?.meetingDetails as {
      meeting: Meeting;
      participant: {
        name: string;
        id: string;
      };
    }
  );

  useEffect(() => {
    setTimeout(() => {
      if (typeof Ybug !== "undefined") {
        Ybug.hide();
      }

      if (typeof FS !== "undefined") {
        FS.shutdown();
      }
    }, 5000);
  }, []);

  useEffect(() => {
    const getUpdatedMeetingDetails = async () => {
      if (!meetingDetails) {
        history.push(
          userType === "provider" ? ProviderPath.OVERVIEW : "/patient/signin"
        );
      } else {
        const { meeting, participant } = meetingDetails;
        const meetingStore = new MeetingStore();
        const fetchedMeeting = await meetingStore.getMeetingById(meeting.id);
        if (fetchedMeeting) {
          const hasParticipant = (fetchedMeeting.participants || []).some(
            (p) => p.id === participant.id
          );
          if (!hasParticipant) {
            fetchedMeeting.participants = [
              ...(fetchedMeeting.participants || []),
              {
                id: participant.id,
                name: participant.name,
                joined: new Date(),
              },
            ];

            // Update session with participant.
            await meetingStore.saveMeeting(fetchedMeeting);
          }

          // listen for changes to session.
          const meetingRef = databaseRef.child(`meeting/${meeting.id}`);
          meetingRef.on("value", (snapshot) => {
            const updatedMeeting = snapshot.val() as Meeting;
            setCurrentMeeting(updatedMeeting);
          });

          setCurrentMeeting(fetchedMeeting);
        }
      }
    };
    getUpdatedMeetingDetails();
  }, [meetingDetails]);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setToolBarType("large");
    } else if (window.innerWidth < 768) {
      setToolBarType("small");
    } else {
      setToolBarType("medium");
    }
  }, [window.innerWidth]);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (user: any) => {
      if (!user && !meetingDetails?.meeting?.id) {
        history.push("/");
      }
    });
  }, []);

  const handleEndMeeting = async () => {
    if (
      userType === "provider" &&
      meetingDetails.participant.id === currentMeeting.host
    ) {
      const meetingToUpdate = { ...currentMeeting } as Meeting;
      meetingToUpdate.status = MeetingStatus.ENDED;
      meetingToUpdate.meetingEndDate = new Date();
      new MeetingStore().saveMeeting(meetingToUpdate);
    } else {
      setClientEndedCall(true);
    }

    if (typeof FS !== "undefined") {
      FS.restart();
    }
  };

  const getToolBar = (
    _toolBarType: string,
    notificationComponent: JSX.Element
  ) => {
    const isSessionActive = currentMeeting.status === MeetingStatus.STARTED;
    const isProvider = userType === "provider";
    const sessionTitleName = currentMeeting.topic;
    const sessionTitle = sessionTitleName;
    const sessionStartTime = moment(currentMeeting.startTime).fromNow(true);
    const callNotificationComponent = isProvider ? (
      notificationComponent
    ) : (
      <></>
    );
    switch (_toolBarType) {
      case "large":
        return (
          <LargeToolBar
            isActive={isSessionActive}
            sessionTitle={sessionTitle}
            noOfParticipants={currentMeeting.participants.length}
            sessionStartTime={sessionStartTime}
            notificationComponent={callNotificationComponent}
          />
        );
      case "medium":
        return (
          <MediumToolBar
            isActive={isSessionActive}
            sessionTitle={sessionTitle}
            noOfParticipants={currentMeeting.participants.length}
            sessionStartTime={sessionStartTime}
            notificationComponent={callNotificationComponent}
          />
        );
      default:
        return (
          <MiniToolBar
            isActive={isSessionActive}
            sessionTitle={sessionTitle}
            noOfParticipants={currentMeeting.participants.length}
            sessionStartTime={sessionStartTime}
            notificationComponent={callNotificationComponent}
          />
        );
    }
  };

  const notificationComponent =
    meetingDetails.participant.id &&
    meetingDetails.meeting.id &&
    meetingDetails.participant.name ? (
      <WaitlistNotifications
        providerId={meetingDetails.participant.id}
        paymentId={meetingDetails.meeting.id}
        fullName={meetingDetails.participant.name}
      />
    ) : (
      <></>
    );

  return (
    <SessionRoomDiv>
      {currentMeeting.status && getToolBar(toolBarType, notificationComponent)}
      <StyledChatBox>
        {(currentMeeting.status === MeetingStatus.ENDED || clientEndedCall) && (
          <CallEnded
            isProvider={userType === "provider"}
            onGoHome={async () => {
              if (userType === "provider") {
                history.push(ProviderPath.OVERVIEW);
              } else {
                await firebaseAuth.signOut();
                history.push("/patient/signin");
              }
            }}
            onSignOut={async () => {
              await firebaseAuth.signOut();
              history.push("/");
            }}
          />
        )}
        {currentMeeting.status === MeetingStatus.STARTED && (
          <ConferenceCall
            meetingId={currentMeeting.id}
            roomName={`${currentMeeting.topic}`}
            participantId={meetingDetails.participant.id}
            displayName={meetingDetails.participant.name}
            email={`${meetingDetails.participant.id}@medcon.live`}
            duration={moment
              .utc(currentMeeting.startTime!)
              .add(5, "h")
              .toDate()
              .getTime()}
            onMeetingEnd={handleEndMeeting}
          />
        )}
      </StyledChatBox>
    </SessionRoomDiv>
  );
};
