import { Descriptions } from "antd";
import {
    Subscriber
} from "medcon_types/ChangeHealthCare";
import moment from "moment";
import React from "react";

function SubscriberDisplay({ subscriber }: { subscriber: Subscriber }) {
  return (
    <Descriptions title="Subscriber">
      <Descriptions.Item label="First name">
        {subscriber.firstName}
      </Descriptions.Item>
      <Descriptions.Item label="Last name">
        {subscriber.lastName}
      </Descriptions.Item>
      <Descriptions.Item label="Middle name">
        {subscriber.middleName}
      </Descriptions.Item>
      <Descriptions.Item label="Date of birth">
        {subscriber.dateOfBirth
          ? moment(subscriber.dateOfBirth, "YYYYMMDD").format("MM/DD/YYYY")
          : ""}
      </Descriptions.Item>
      <Descriptions.Item label="Gender">{subscriber.gender}</Descriptions.Item>
      <Descriptions.Item label="Address">
        {subscriber.address?.address1} {subscriber.address?.city}{" "}
        {subscriber.address?.postalCode} {subscriber.address?.state}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default SubscriberDisplay;