import { Feature } from "../types/feature";
import { databaseRef } from "./firebase";
import logger from "../utils/logger";

export default class FeatureStore {
  path: string;

  constructor() {
    this.path = "feature";
  }

  async getFeatures(): Promise<Feature[]> {
    let response = {} as Feature[];
    try {
      const document = await databaseRef.child(this.path).once("value");
      if (document.exists()) {
        response = document.val() as Array<Feature>;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return response;
  }
}
