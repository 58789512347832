import React from "react";
import styled from "styled-components";
import { device } from "../../assets/breakbpoints";

const StyledInputDiv = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c6cdd6;
  border-radius: 10px;
  padding: 5px 14px;
  min-width: fit-content;

  @media ${device.laptop} {
    width: 50%;
  }

  @media ${device.desktopL} {
    padding: 20px 17px;
  }
`;

export const InputDiv = (props: { width?: string; children?: any }) => (
  <StyledInputDiv style={{ width: props.width }}>
    {props.children}
  </StyledInputDiv>
);
