import React from "react";
import {
  StyledBlackTd,
  StyledfadeTd,
  StyledSpecialFontTd,
} from "../styles/tableRowStyle";

export const SessionDetailsBody = (props: {
  data: {
    client: string;
    service: string;
    location: string;
    timeStamp: string;
    copay: string;
    status: string;
    provider: string;
  };
}) => (
  <tr>
    <StyledfadeTd>{props.data.client}</StyledfadeTd>
    <StyledBlackTd>{props.data.service}</StyledBlackTd>
    <StyledBlackTd>{props.data.location}</StyledBlackTd>
    <StyledBlackTd>{props.data.status}</StyledBlackTd>
    <StyledSpecialFontTd className="specialFont">
      {props.data.timeStamp}
    </StyledSpecialFontTd>
    <StyledfadeTd>{props.data.provider}</StyledfadeTd>
    <td>
      <StyledBlackTd>{props.data.copay}</StyledBlackTd>
    </td>
  </tr>
);
