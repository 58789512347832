import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import "firebase/app-check";
import "firebase/functions";
import logger from "../utils/logger";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABSE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

// Initialize Firebase and stop the firebase initialisation happening multiple times
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  const appCheck = firebase.appCheck();
  appCheck.activate(process.env.REACT_APP_RECAPTCHA_SITE_KEY);
}

export const databaseRef = firebase
  .database()
  .ref(process.env.REACT_APP_FB_PROJECT_ID);


export async function authenticate() {
  const firebaseAuth = firebase.auth();
  let authResult = {} as any;
  try {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    googleAuthProvider.addScope("profile");
    googleAuthProvider.addScope("email");
    authResult = await firebaseAuth.signInWithRedirect(googleAuthProvider);
  } catch (err) {
    logger.error(err);
  }
  return authResult;
}

export const firebaseAuth = firebase.auth();
export const firebaseAnalytics = firebase.analytics();

export default {
  authenticate, firebase, databaseRef,
  firebaseAuth, functions: firebase.functions
};
