import { databaseRef } from "./firebase";
import { Setting } from "../types/setting";
import logger from "../utils/logger";

export default class SettingStore {
  path: string;

  constructor() {
    this.path = "setting";
  }

  async getBusinessSettings(): Promise<Setting> {
    let setting = {} as Setting;
    try {
      const settingDoc = await databaseRef.child(this.path).once("value");
      if (settingDoc.exists()) {
        setting = settingDoc.val() as Setting;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return setting;
  }

  async saveSetting(setting: Setting): Promise<boolean> {
    let isSaved = false;
    try {
      await databaseRef.child(this.path).update(setting);
      isSaved = true;
    } catch (err) {
      logger.error(err.message);
    }
    return isSaved;
  }
}
