import { PaymentCaptureType } from "../types/enum";
import firebaseTools from "./firebase";

export function getCallablePaymentFunction(
    paymentCaptureType: PaymentCaptureType
) {
    let paymentFunction = firebaseTools.functions().httpsCallable("authorizeCreditCard");
    if (paymentCaptureType === PaymentCaptureType.AuthorizeAndCapture) {
        paymentFunction = firebaseTools.functions().httpsCallable("authorizeAndChargeCreditCard");
    }
    return paymentFunction;
}

export function getCallableCaptchaFunction() {
    return firebaseTools.functions().httpsCallable("validateRecaptchaToken");
}

export function getCallableCreatePaymentFromRequestFunction() {
    return firebaseTools.functions().httpsCallable("createPaymentFromRequest");
}

export function getGenerateMeetingTokenFunction() {
    return firebaseTools.functions().httpsCallable("generateMeetingToken");
}

export function getFetchBlogFunction() {
    return firebaseTools.functions().httpsCallable("fetchBlogContents");
}

export function getBackendUsersFunction() {
    return firebaseTools.functions().httpsCallable("getBackendUsers");
}

export function getUserPermissionFunction() {
    return firebaseTools.functions().httpsCallable("getUserPermission");
}

export function updateUserPermissionFunction() {
    return firebaseTools.functions().httpsCallable("updateUserPermission");
}

export function getMeetingAuthenticationTokenFunction() {
    return firebaseTools.functions().httpsCallable("getMeetingAuthenticationToken");
}

export function getPaymentProfileFunction() {
    return firebaseTools.functions().httpsCallable("getPaymentProfile");
}

export function getAuthorizeAndChargeProfileFunction() {
    return firebaseTools.functions().httpsCallable("authorizeAndChargeProfile");
}

export function executeImportProviderAppointmentsFunction() {
    return firebaseTools.functions().httpsCallable("executeImportProviderAppointments");
}

export function runCheckClientEligibility() {
    return firebaseTools.functions().httpsCallable("checkClientEligibility");
}