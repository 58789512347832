import React, { useContext, useEffect, useState } from "react";
import { FiClipboard } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { Loader } from "../../elements/Loader";
import NoteStore from "../../../store/note";
import { Note } from "../../../types/note";
import { useScreenNameContext } from "../../context/screenNameContext";
import { PermissionContext } from "../../context/permissionContext";
import { TableMini } from "../atoms/TableMini";
import { generateMedconHash } from "../../../utils/hash";

declare const window: any;

const title = ["Name", "Email", "Session Format", "Modality", ""];
const csvHeaders = [
  { label: "Name", key: "text" },
  { label: "Email", key: "field1" },
  { label: "Session Format", key: "field2" },
  { label: "Modality", key: "field3" },
];

export const Notes = () => {
  const history = useHistory();
  const [loader, showLoader] = useState(true);
  const [sessionNotes, setSessionNotes] = useState([] as any);
  const { setCurrentPageName } = useScreenNameContext() as any;
  const { permission } = useContext(PermissionContext);

  window.navigateToNote = (note: any) => {
    const link = `/provider/note/notedetails/?sessionId=${note.sessionId}`;
    history.push(link);
  };

  useEffect(() => {
    setCurrentPageName("Notes");
    if (permission) {
      const noteStore = new NoteStore();
      generateMedconHash(permission.email).then((hashedProviderId: string) => {
        noteStore.getProviderNotes(hashedProviderId).then((notes: Note[]) => {
          const formattedNotes = [] as any[];

          Object.values(notes).forEach((note: Note) => {
            formattedNotes.push({
              text: note.patientName,
              field1: note.patientEmail,
              field2: note.sessionFormat,
              field3: note.modality.toLocaleLowerCase(),
              actions: [
                {
                  text: "View Note",
                  handler: window.navigateToNote,
                  param: note,
                  type: "button",
                  icon: <FiClipboard />,
                },
              ],
            });
          });

          setSessionNotes(formattedNotes);
          showLoader(false);
        });
      });
    }
  }, [permission]);

  return (
    <>
      <TableMini
        sessionName="Client Session Notes"
        tableTitle={title}
        tableName="All Notes"
        showLocationFilter={false}
        showDateFilter={false}
        data={sessionNotes}
        csvHeaders={csvHeaders}
        isActive
        showSearch
        showExportButton
      />
      <Loader visible={loader} text="Loading ... " />
    </>
  );
};
