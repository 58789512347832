import { Alert } from "antd";
import React, { useEffect, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import sideImage1 from "../../assets/images/sideImage1.png";
import { theme } from "../../assets/theme";
import { getUserPermissionFunction } from "../../store/callableFunctions";
import {
  authenticate,
  firebaseAuth
} from "../../store/firebase";
import logger from "../../utils/logger";
import { getUserUniqueId, generateMedconHash } from "../../utils/hash";
import {
  initializePendo,
  initializeFullStory,
} from "../../utils/web-analytics";
import { Loader } from "../elements/Loader";
import { OnBoardingTopText } from "../elements/OnBoardingTopText";
import { OnBoardingScaffold } from "../layout/OnBoardingScaffold";

declare const Ybug: any;

const StyledAlert = styled.div`
  width: 60%;
`;

const StyledButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  min-width: fit-content;
  border: 1px solid #c6cdd6;
  background-color: transparent;
  border-radius: 10px;
  padding: 13px 14px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    border: 1px solid ${theme.primaryColor};
  }

  &:nth-child(2) {
    margin-top: 20px !important;
  }

  & div {
    &:nth-child(1) {
      margin-bottom: 0px;
      font-family: ${fontFamily.heading};
      color: ${theme.normalText};
      font-size: 16px;
      place-items: center;
      display: flex;

      & img {
        margin-right: 23px;
        width: 16px;
      }
    }

    &:nth-child(2) {
      color: ${theme.mutedColor};
      place-items: center;
      display: flex;
      font-size: 12px;
    }
  }

  @media ${device.tablet} {
    width: 90%;
  }

  @media ${device.laptop} {
    width: 45%;
    & div:first-child {
      font-size: 1.1vw;

      & img {
        width: 1.1vw;
      }
    }

    & div:nth-child(2) {
      font-size: 0.9vw;
    }
  }

  @media ${device.desktopL} {
    padding: 20px 17px;
  }
`;

const StyledFiChevronRight = styled(FiChevronRight)`
  margin-left: 5px;
`;

const CategoryButton = (props: {
  onClick: () => void;
  icon?: string;
  buttonText: string;
}) => (
  <StyledButton onClick={props.onClick}>
    <div>{props.buttonText}</div>
    <div>
      Select <StyledFiChevronRight />
    </div>
  </StyledButton>
);

export const WelcomeScreen = () => {
  const history = useHistory();

  const [alertVisible, setAlertVisible] = useState(false);
  const [loader, showLoader] = useState(false);

  const onPatientClick = () => {
    history.push(`/patient/signin`);
  };

  const alertClose = () => {
    setAlertVisible(false);
  };

  useEffect(() => {
    setAlertVisible(false);
    showLoader(true);
    firebaseAuth
      .getRedirectResult()
      .then((result: any) => {
        if (result && result.additionalUserInfo) {
          // get the permission for user
          const getUserPermission = getUserPermissionFunction();
          getUserPermission({
            email: result.additionalUserInfo.profile.email,
          }).then((response: any) => {
            const userPermission = response.data;
            if (
              userPermission &&
              !userPermission.locked &&
              userPermission.role
            ) {
              const uniqueId = getUserUniqueId();
              const profileEmail = result.additionalUserInfo.profile.email;
              const displayName = result.additionalUserInfo.profile.name;
              generateMedconHash(profileEmail).then(
                (visitorId) => {
                  // initialize pendo
                  initializePendo({
                    accountUniqueId: uniqueId,
                    visitorUniqueId: visitorId,
                    email:profileEmail,
                    displayName
                  });

                  // intialize full story
                  initializeFullStory({
                    visitorUniqueId: visitorId,
                    email: profileEmail,
                    displayName
                  });
                }
              );
              history.push(`/provider/overview`, {
                permission: userPermission,
              });
            } else {
              setAlertVisible(true);
              showLoader(false);
            }
          });
        } else {
          showLoader(false);
        }
      })
      .catch((err) => {
        logger.error({error: err});
        setAlertVisible(true);
        showLoader(false);
      });
  }, []);

  const signIn = () => {
    authenticate();
  };

  useEffect(() => {
    setTimeout(() => {
      if (typeof Ybug !== "undefined") {
        Ybug.hide();
      }
    }, 5000);
  }, []);

  return (
    <OnBoardingScaffold
      topItem={
        <OnBoardingTopText
          prefixText="Welcome to"
          logo=""
          segment="Humantold"
          botMessage="See your therapist from anywhere"
        />
      }
      sideImage={sideImage1}
    >
      {alertVisible ? (
        <StyledAlert>
          <Alert
            message="We are unable to sign you in, please try again"
            type="error"
            showIcon
            closable
            onClose={alertClose}
          />
        </StyledAlert>
      ) : (
        ""
      )}
      <CategoryButton onClick={onPatientClick} buttonText="I’m a Client" />
      <CategoryButton onClick={signIn} buttonText="I’m a Therapist" />
      <Loader visible={loader} text="Please wait ... " />
    </OnBoardingScaffold>
  );
};
