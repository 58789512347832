import React, { useEffect, useState } from "react";

export const Logo = () => {
  const [logoDimention, setLogoDimention] = useState({
    width: 165,
    height: 26,
  });

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      const width = window.innerWidth * (11 / 100);
      const height = window.innerHeight * (11 / 100);

      setLogoDimention({ width, height });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("load", () => {
      if (window.innerWidth >= 1024) {
        const width = window.innerWidth * (11 / 100);
        const height = window.innerHeight * (11 / 100);

        setLogoDimention({ width, height });
      }
    });

    return () =>
      window.removeEventListener("load", () => {
        if (window.innerWidth >= 768) {
          const width = window.innerWidth * (11 / 100);
          const height = window.innerHeight * (11 / 100);

          setLogoDimention({ width, height });
        }
      });
  }, []);

  return (
    <svg
      width={logoDimention.width}
      /* "113" */ height={logoDimention.height}
      /* "31" */ viewBox="0 0 165 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.70324 11.0908C6.95395 8.08931 9.23024 6.47877 12.1944 6.47877C16.0591 6.47877 17.9352 8.49195 17.9352 12.4817V22.1083C17.9352 22.7672 18.1228 22.877 18.423 22.877H20.1615V24.5974H13.0824V22.877H14.6333C14.9335 22.877 15.1211 22.7672 15.1211 22.1083V12.6281C15.1211 9.77306 14.033 8.41874 11.3314 8.41874C7.99204 8.41874 5.70324 11.469 5.70324 16.5813V22.1083C5.70324 22.7672 5.89085 22.877 6.22854 22.877H7.85446V24.5974H0.662877V22.877H2.40136C2.70153 22.877 2.88914 22.7672 2.88914 22.1083V2.89165C2.88914 2.19619 2.77658 2.01318 2.40136 2.01318H0V0.292826H1.61342C2.98267 0.306366 4.35069 0.208417 5.70324 0V11.0908Z"
        fill="#FFAB4D"
      />
      <path
        d="M34.2697 24.8901V20.6808C33.069 23.426 30.8553 25.0121 27.9286 25.0121C24.3265 25.0121 22.1878 23.2918 22.1878 19.1556V9.39477C22.1878 8.73591 22.0002 8.6261 21.7 8.6261H20.5869V6.88135H25.0144V19.0336C25.0144 22.0839 26.4402 23.0966 28.8041 23.0966C32.1435 23.0966 34.2822 20.0463 34.2822 15.8125V9.37037C34.2822 8.71151 34.0946 8.6017 33.7569 8.6017H32.5937V6.88135H37.0838V21.9985C37.0838 22.6939 37.1964 22.877 37.5716 22.877H38.7222V24.5973H38.397C37.0154 24.5858 35.6351 24.6837 34.2697 24.8901Z"
        fill="#FFAB4D"
      />
      <path
        d="M40.5106 22.8768H41.6237C41.9239 22.8768 42.1115 22.767 42.1115 22.1081V9.47998C42.1115 8.78452 41.9989 8.60151 41.6237 8.60151H40.5106V6.88116H40.7982C42.1674 6.89142 43.5351 6.79349 44.888 6.58833V10.1755C45.9386 7.73523 47.7021 6.47852 50.3662 6.47852C53.3303 6.47852 55.1314 7.86944 55.7317 10.7489C56.7823 7.93045 58.5458 6.47852 61.51 6.47852C65.4872 6.47852 67.3258 8.60151 67.3258 13.1892V22.1081C67.3258 22.767 67.5134 22.8768 67.8136 22.8768H68.9267V24.5972H63.1609V22.8768H64.0114C64.3491 22.8768 64.5367 22.767 64.5367 22.1081V13.36C64.5367 9.95583 63.4486 8.41849 60.7095 8.41849C57.7453 8.41849 56.1319 10.8587 56.1319 15.1291V22.0837C56.1319 22.7426 56.3195 22.8524 56.6572 22.8524H57.8454V24.5728H51.9045V22.8768H52.7925C53.1302 22.8768 53.2803 22.767 53.2803 22.1081V12.8109C53.2803 9.95583 52.0296 8.41849 49.4907 8.41849C46.4889 8.41849 44.9131 10.9075 44.9131 15.1291V22.0837C44.9131 22.7426 45.1007 22.8524 45.4008 22.8524H46.4389V24.5728H40.498L40.5106 22.8768Z"
        fill="#FFAB4D"
      />
      <path
        d="M73.7168 9.7606C74.0779 9.86316 74.3928 10.0816 74.6107 10.3806C74.8286 10.6795 74.9367 11.0415 74.9175 11.4078C74.9208 11.644 74.8737 11.8784 74.779 12.0959C74.6844 12.3134 74.5444 12.5093 74.3679 12.6711C74.1915 12.8329 73.9824 12.9571 73.754 13.0358C73.5256 13.1145 73.283 13.1459 73.0415 13.1281C72.7581 13.1379 72.4758 13.0887 72.2133 12.9839C71.9508 12.8792 71.7142 12.7212 71.5191 12.5204C71.3239 12.3197 71.1748 12.0808 71.0814 11.8196C70.9881 11.5583 70.9528 11.2807 70.9778 11.0052C70.9778 8.18666 74.5048 6.46631 78.6697 6.46631C83.6225 6.46631 85.8738 8.36968 85.8738 12.6157V22.1081C85.8738 22.6572 85.9863 22.8768 86.3615 22.8768H87.4371V24.5971H83.735C83.3821 23.3673 83.1723 22.1026 83.1097 20.827C82.0966 23.3526 79.32 24.9998 76.1682 24.9998C72.7163 24.9998 70.54 23.0598 70.54 20.3146C70.54 16.2882 74.5548 14.5679 83.0471 13.6894V12.6645C83.0471 9.51658 81.5838 8.19886 78.2819 8.19886C76.0807 8.19886 74.5423 8.78452 73.7168 9.7606ZM83.0597 16.6787V15.1535C76.3809 15.8124 73.3041 16.9837 73.3041 19.9851C73.3041 21.8885 74.805 23.1696 77.0562 23.1696C80.6708 23.1818 83.0221 20.5342 83.0597 16.6909V16.6787Z"
        fill="#FFAB4D"
      />
      <path
        d="M94.1158 11.237C95.3164 8.12567 97.6052 6.47852 100.644 6.47852C104.434 6.47852 106.385 8.5649 106.385 12.5791V22.0959C106.385 22.7548 106.573 22.8646 106.873 22.8646H107.986V24.585H102.158V22.8768H103.046C103.384 22.8768 103.571 22.767 103.571 22.1081V12.7011C103.571 9.77281 102.408 8.43069 99.7815 8.43069C96.4421 8.43069 94.1533 11.4322 94.1533 16.5933V22.1203C94.1533 22.7792 94.3409 22.889 94.6411 22.889H95.7167V24.6094H89.7383V22.8768H90.8514C91.1516 22.8768 91.3392 22.767 91.3392 22.1081V9.47998C91.3392 8.78452 91.2266 8.60151 90.8514 8.60151H89.7383V6.88116H90.051C91.4202 6.8947 92.7882 6.79675 94.1408 6.58833L94.1158 11.237Z"
        fill="#FFAB4D"
      />
      <path
        d="M111.026 20.2418V8.60202H108.187V7.10129L110.175 6.95488C111.038 6.88167 111.426 6.62545 111.526 5.73477L111.789 2.55029H113.852V6.90607H118.092V8.60202H113.815V20.2418C113.815 22.3282 114.565 23.1335 116.441 23.1335C117.134 23.1394 117.822 23.0151 118.467 22.7674V24.5244C117.61 24.8138 116.71 24.9623 115.803 24.9636C112.414 24.9636 111.026 23.6093 111.026 20.2418Z"
        fill="#FFAB4D"
      />
      <path
        d="M118.455 15.7392C118.455 10.2487 122.169 6.47852 127.647 6.47852C133.125 6.47852 136.727 10.2121 136.727 15.7392C136.727 21.2663 133.013 25.012 127.572 25.012C122.132 25.012 118.455 21.2663 118.455 15.7392ZM133.951 15.8612V15.5684C133.951 10.993 131.587 8.24768 127.647 8.24768C123.595 8.24768 121.269 10.993 121.269 15.5684V15.8612C121.269 20.4366 123.595 23.1818 127.572 23.1818C131.55 23.1818 133.951 20.4244 133.951 15.8612Z"
        fill="#FFAB4D"
      />
      <path
        d="M138.204 22.877H139.317C139.654 22.877 139.805 22.7672 139.805 22.1083V2.89165C139.805 2.19619 139.692 2.01318 139.317 2.01318H138.204V0.292826H138.566C139.929 0.302102 141.291 0.204176 142.637 0V22.1083C142.637 22.7672 142.825 22.877 143.125 22.877H144.163V24.5974H138.204V22.877Z"
        fill="#FFAB4D"
      />
      <path
        d="M145.351 15.7394C145.351 10.1391 148.728 6.47877 153.756 6.47877C155.213 6.41635 156.655 6.7926 157.885 7.5565C159.116 8.32041 160.076 9.435 160.635 10.7491V2.89165C160.635 2.19619 160.485 2.01318 160.109 2.01318H158.959V0.292826H159.359C160.724 0.30243 162.088 0.204503 163.436 0V22.1083C163.436 22.7672 163.586 22.877 163.924 22.877H165V24.5974H160.96C160.847 23.7555 160.735 22.2548 160.697 20.6808C160.14 21.9999 159.182 23.1209 157.952 23.8931C156.723 24.6653 155.28 25.0515 153.818 25C148.69 25.0122 145.351 21.3519 145.351 15.7394ZM160.585 15.9224V15.5564C160.585 11.0908 158.033 8.38214 154.469 8.38214C150.717 8.38214 148.215 10.9444 148.215 15.5198V15.959C148.215 20.5344 150.717 23.0966 154.469 23.0966C158.071 23.0966 160.585 20.4246 160.585 15.9224Z"
        fill="#FFAB4D"
      />
    </svg>
  );
};
