import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import {
  EligibilityCheckResult,
  EligibityRequest,
} from "medcon_types/ChangeHealthCare";
import moment from "moment";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import insuranceOptions from "../../../assets/data/defaultInsuranceOptions";
import { runCheckClientEligibility } from "../../../store/callableFunctions";
import logger from "../../../utils/logger";
import EligibilityCheckResultDisplay from "./EligibilityResultDisplay";
import { StyledEligibilityCheckForm } from "./styledBits";
import { logAnalyticsEvent } from "../analytics";
import { MedconAnalyiticsEvent } from "../types";

interface EligibilityFormValues {
  insurance: string;
  memberId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: moment.Moment;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 11 },
};
const actionButtonsLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const { Option } = Select;

async function submitEligibilityCheckRequest(
  eligibilityFormValues: EligibilityFormValues
) {
  const request: EligibityRequest = {
    tradingPartnerServiceId: eligibilityFormValues.insurance,
    subscriber: {
      lastName: eligibilityFormValues.lastName,
      firstName: eligibilityFormValues.firstName,
      dateOfBirth: eligibilityFormValues.dateOfBirth.format("YYYYMMDD"),
      memberId: eligibilityFormValues.memberId,
    },
  };
  return (await runCheckClientEligibility()(request)).data as
    | EligibilityCheckResult
    | { error: string };
}

function findInsurance(secondaryIndentifier = "") {
  if (secondaryIndentifier) {
    const foundInsurance = insuranceOptions.find(
      (i) =>
        i.secondaryIdentifier.trim().toLowerCase() ===
        secondaryIndentifier.trim().toLowerCase()
    );
    if (foundInsurance) {
      return foundInsurance.payerId;
    }
  }
  return "";
}

function EligibilityCheck() {
  const [eligibilityForm] = Form.useForm();
  const browserLocation = useLocation();
  const parsedQueryParams = queryString.parse(browserLocation.search || "");
  const [initialInsurance] = useState(
    findInsurance((parsedQueryParams.ins || "") as string)
  );
  const [initialFirstName] = useState((parsedQueryParams.fn || "") as string);
  const [initialLastName] = useState((parsedQueryParams.ln || "") as string);
  const [initialDateOfBirth] = useState(parsedQueryParams.dob as string);
  const [initialMemberId] = useState((parsedQueryParams.mid || "") as string);
  const [modal] = Modal.useModal();
  const [checkResultToDisplay, setCheckResultToDisplay] =
    useState<EligibilityCheckResult>();
  const [loadingResult, setLoadingResult] = useState(false);

  const onFinish = async (values: EligibilityFormValues) => {
    try {
      setLoadingResult(true);
      logAnalyticsEvent(MedconAnalyiticsEvent.ELIGIBILITY_CHECK_REQUEST, {
        insurance: values.insurance,
      });
      const response = await submitEligibilityCheckRequest(values);
      logger.log({ response });
      if ("error" in response) {
        logAnalyticsEvent(MedconAnalyiticsEvent.ELIGIBILITY_CHECK_FAIL, {
          insurance: values.insurance,
        });
        logger.error({ error: response.error });
        const checkError = response as { error: string };
        modal.error({
          title: "Error",
          content: (
            <div>
              <p>Error while trying to do eligibility check</p>
              <p>{checkError.error}</p>
            </div>
          ),
        });
      } else {
        const checkResult = response as EligibilityCheckResult;
        if (response.allBenefits.length <= 1) {
          logAnalyticsEvent(MedconAnalyiticsEvent.ELIGIBILITY_CHECK_NO_BENEFITS, {
            insurance: values.insurance,
          });
        }
        setCheckResultToDisplay(checkResult);
      }
    } finally {
      setLoadingResult(false);
    }
  };

  const onReset = () => {
    eligibilityForm.setFieldsValue({
      insurance: "",
      memberId: "",
      firstName: "",
      lastName: "",
      dateOfBirth: null,
    });
    setCheckResultToDisplay(undefined);
  };

  return (
    <>
      <StyledEligibilityCheckForm>
        <Form
          {...layout}
          initialValues={{
            insurance: initialInsurance,
            memberId: initialMemberId,
            firstName: initialFirstName,
            lastName: initialLastName,
            dateOfBirth: initialDateOfBirth
              ? moment(initialDateOfBirth, "MM_DD_YYYY")
              : null,
          }}
          form={eligibilityForm}
          onReset={onReset}
          onFinish={onFinish}
          name="eligbility-form"
        >
          <Form.Item
            label="Insurance"
            name="insurance"
            rules={[{ required: true, message: "Please select the insurance" }]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select Insurance"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option.children as any).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {insuranceOptions.map((o, idx) => (
                <Option key={`opt-${idx}-${o.payerId}`} value={o.payerId}>
                  {o.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Member ID"
            name="memberId"
            rules={[{ required: true, message: "Please enter the member ID" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "Please enter the first name" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please enter the last name" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Date of birth"
            name="dateOfBirth"
            rules={[
              { required: true, message: "Please select the date of birth" },
            ]}
          >
            <DatePicker format="MM/DD/YYYY" onChange={() => {}} />
          </Form.Item>

          <Form.Item {...actionButtonsLayout}>
            <Button type="primary" htmlType="submit" loading={loadingResult}>
              Submit
            </Button>
            <Button
              htmlType="button"
              onClick={onReset}
              style={{
                marginLeft: "1em",
              }}
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </StyledEligibilityCheckForm>
      {checkResultToDisplay && (
        <EligibilityCheckResultDisplay result={checkResultToDisplay} />
      )}
    </>
  );
}

export default EligibilityCheck;
