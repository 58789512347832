import { Col } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { FiRefreshCcw } from "react-icons/fi";
import styled from "styled-components";
import { fontFamily } from "../../../assets/fontFamily";
import { ApptValue } from "../../../types/appt-value";
import { firebaseAuth } from "../../../store/firebase";
import { generateMedconHash } from "../../../utils/hash";

const localizer = momentLocalizer(moment);

const StyledActionButtons = styled.div`
  display: flex;
  margin-left: auto;
  margin-bottom: 20px;
`;
const StyledContainer = styled(Col)`
  background-color: #fff;
  border-radius: 13.3855px;
  padding-bottom: 40px;
`;
const StyledMyCalendar = styled.div`
  width: 90%;
  height: 80vh;
  margin: 0 auto;
  margin-top: 50px;
  background-color: #fff;
`;

const StyledCalendar = styled(Calendar as any)`
  height: 100%;
  width: 100%;

  & .rbc-header {
    padding-top: 40px;
    background-color: #f7fcfe;
  }
  & .rbc-header + .rbc-header {
    padding-top: 40px;
    padding-bottom: 40px;
    border-right: none;
    border-left: none;
    background-color: #f7fcfe;
  }

  & rbc-ellipsis,
  .rbc-event-label,
  .rbc-row-segment .rbc-event-content,
  .rbc-show-more {
    background-color: #ebf8fe;
    color: #41748a;
    font-weight: bolder;
    font-family: ${fontFamily.body};
    font-size: 12px;
  }

  & .rbc-event {
    background-color: transparent;
  }

  & .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    background-color: #f6a851;
    color: #000;
    border: 1px solid #f6a851;
    font-family: ${fontFamily.heading};
  }

  & .rbc-toolbar button:focus,
  .rbc-toolbar button.rbc-active {
    outline: none;
    border: 1px solid #f6a851;
  }

  & .rbc-btn-group button + button,
  .rbc-toolbar button {
    font-family: ${fontFamily.heading};
    background-color: #fff;
  }

  & .rbc-toolbar .rbc-toolbar-label {
    font-size: 20px;
    font-family: ${fontFamily.heading};
    color: #1c1d21;
    font-weight: 700;
  }

  & .jQTuZS rbc-ellipsis,
  .jQTuZS .rbc-event-label,
  .jQTuZS .rbc-row-segment .rbc-event-content,
  .jQTuZS .rbc-show-more {
    height: 100px;
  }
`;

const StyledSwitch = styled.div`
  border: 1.11546px solid #cedce2;
  border-radius: 11.1546px;
  display: flex;
  align-items: center;
  font-size: 14.22px;
  background-color: #fff;
  padding: 10px 15px;
  margin-right: 20px;
  cursor: pointer;

  & p {
    margin-bottom: 0px;
    margin-left: 10px;
    font-family: ${fontFamily.body};
    font-weight: 500;
    color: #5d6d74;
  }
`;

const StyledFiRefreshCcw = styled(FiRefreshCcw)`
  color: #5d6d74;
  font-size: 21px;
`;

export const ScheduleCalendar = () => {
  const history = useHistory();
  const browserLocation = useLocation();
  const [providerId, setProviderId] = useState("");

  const handleSwitch = () => {
    history.goBack();
  };

  useEffect(() => {
    const userEmail = firebaseAuth.currentUser?.email!;
    generateMedconHash(userEmail).then((hashedId: string) => {
      setProviderId(hashedId);
    });
  }, []);

  const [appointments] = useState(
    (browserLocation.state as any)?.appointments as ApptValue[]
  );

  const eventsList: [] | any = [];

  if (appointments) {
    let index = 0;
    [...appointments].forEach((appointment: ApptValue) => {
      const appointmentStartDate = new Date(appointment.appointmentDate);
      const appointmentEndDate = new Date(appointment.appointmentEndDate);
      const clientName = `${appointment.clientFirstName} ${appointment.clientLastName}`;
      eventsList.push({
        id: index,
        title: clientName,
        desc: clientName,
        allDay: false,
        start: appointmentStartDate,
        end: appointmentEndDate,
        data: appointment,
      });
      index += 1;
    });
  }

  return (
    <>
      <StyledActionButtons>
        <StyledSwitch onClick={handleSwitch}>
          <StyledFiRefreshCcw />
          <p>Switch to Table view</p>
        </StyledSwitch>
      </StyledActionButtons>
      <StyledContainer xs={{ span: 24 }}>
        <StyledMyCalendar>
          <StyledCalendar
            localizer={localizer}
            events={eventsList}
            step={60}
            onSelectEvent={(event: any) => {
              history.push(
                `/provider/schedule/create-appointment?appointmentId=${event.data.id}&patientId=${event.data.patientId}&providerId=${providerId}`
              );
            }}
          />
        </StyledMyCalendar>
      </StyledContainer>
    </>
  );
};
