import React from "react";
import styled from "styled-components";

const StyledSpan = styled.span`
  color: #b94a48;
  font-weight: bold;
  margin-top: 10px;
  display: none;
`;

export const Validation = (props: { text: string; visible: boolean }) => (
  <StyledSpan style={{ display: props.visible ? "inline-block" : "none" }}>
    {props.text}
  </StyledSpan>
);
