import { Layout, PageHeader, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getUserPermissionFunction } from "../../store/callableFunctions";
import FeatureStore from "../../store/feature";
import { firebaseAuth } from "../../store/firebase";
import ProviderStore from "../../store/provider";
import { Provider } from "../../types/provider";
import { FeaturesContext } from "../context/featuresContext";
import { PermissionContext } from "../context/permissionContext";
import { ClaimsPath } from "./types";
import './style.css';

const { Header, Footer, Content } = Layout;

export const ClaimsModule = (props: { children: any }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loggedInUser, setLoggedInUser] = useState<Provider>();
  const history = useHistory();
  const browserLocation = useLocation();
  // TODO: Add check for features and permissions for claims module.
  const [permission, setPermission] = useState(
    (browserLocation.state as any)?.permission
  );
  const [features, setFeatures] = useState([] as any);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (user: any) => {
      if (!user) {
        history.push("/");
        return;
      }

      // fetch provider information
      const providerStore = new ProviderStore();
      const returnedUser = await providerStore.fetchProvider(user.email);
      setLoggedInUser(returnedUser);

      // fetch permission if not available.
      if (!permission) {
        const permissions = (
          await getUserPermissionFunction()({ email: user.email })
        ).data;
        setPermission(permissions);
      }
    });

    // fetch features flags
    const getFeatureFlags = async () => {
      const allFeatures = await new FeatureStore().getFeatures();
      setFeatures(allFeatures);
    };

    getFeatureFlags();
  }, []);

  const routeSubtitles = {
    [ClaimsPath.ELIGIBILITY_CHECK]: "Client eligiblity check"
  };

  return (
    <PermissionContext.Provider value={{ permission }}>
      <FeaturesContext.Provider value={{ features }}>
        <Layout>
          <Header className="claims-header">
            <PageHeader
              className="claims-page-header"
              onBack={() => null}
              title="Humantold Claims"
              subTitle={routeSubtitles[browserLocation.pathname]}
              tags={<Tag color="red">Beta</Tag>}
            />
          </Header>
          <Content className="claims-content">{props.children}</Content>
          <Footer>.</Footer>
        </Layout>
      </FeaturesContext.Provider>
    </PermissionContext.Provider>
  );
};
