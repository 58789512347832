/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { confirmAlert } from "react-confirm-alert";
import { getGenerateMeetingTokenFunction } from "../../../store/callableFunctions";
import logger from "../../../utils/logger";
import { Loader } from "../../elements/Loader";

declare const JitsiMeetExternalAPI: any;

const ConferenceCallWindow = styled.div`
  height: 75vh;
`;

function ConferenceCall({
  participantId,
  displayName,
  onMeetingEnd,
  meetingId,
  email,
  duration,
  isProvider,
}: {
  roomName: string;
  participantId: string;
  displayName: string;
  meetingId: string;
  email: string;
  duration: number;
  isProvider?: boolean;
  onMeetingEnd?: () => void;
}) {
  const history = useHistory();
  const [hasJoinedCall, setHasjoinedCall] = useState(false);

  useEffect(() => {
    const endSession = async (api: any, updateSession: boolean) => {
      api.executeCommand("hangup");
      api.dispose();
      if (onMeetingEnd && updateSession) {
        onMeetingEnd();
      } else {
        history.push("/provider/overview");
      }
    };
    const setupConferenceCall = async (userHasJoinedCall: boolean) => {
      if (userHasJoinedCall) {
        return;
      }
      try {
        const tokenFunction = getGenerateMeetingTokenFunction();
        const requestData = {
          roomName: meetingId,
          name: displayName,
          email,
          id: participantId,
          expiryTimeStamp: duration,
        };
        const tokenResponse: any = await tokenFunction(requestData);
        if (!tokenResponse) {
          logger.error("No token response for video call");
        }
        const jwt: string = tokenResponse.data;
        const domain = process.env.REACT_APP_MEDCON_SERVER_URL;
        const options = {
          roomName: meetingId,
          parentNode: document.querySelector("#conference-call-window"),
          noSSL: false,
          height: "100%",
          width: "100%",
          jwt,
          userInfo: {
            participantId,
            email: email || `${participantId}@telepracticepro.com`,
            displayName,
          },
          // https://github.com/jitsi/jitsi-meet/blob/master/config.js
          configOverwrite: {
            enableWelcomePage: false,
            defaultLanguage: "en",
            prejoinPageEnabled: false,
            apiLogLevels: ["error", "warn"],
            disableAudioLevels: true,
            enableLayerSuspension: true,
            hideConferenceSubject: true,
            p2p: {
              enabled: true,
              stunServers: [
                { urls: `${process.env.REACT_APP_MEDCON_STUN_SERVER_URL}` },
              ],
            },
            e2eping: {
              pingInterval: -1,
              analyticsInterval: -1,
            },
          },
          // https://github.com/jitsi/jitsi-meet/blob/master/interface_config.js
          interfaceConfigOverwrite: {
            LANG_DETECTION: false,
            MOBILE_DOWNLOAD_LINK_ANDROID: "",
            MOBILE_DOWNLOAD_LINK_F_DROID: "",
            MOBILE_DOWNLOAD_LINK_IOS: "",
            SUPPORT_URL: "htps://support.medcon.live",
            NATIVE_APP_NAME: "Medcon Secure Telemedicine System",
            PROVIDER_NAME: "Medcon",
            DEFAULT_LOCAL_DISPLAY_NAME: "me",
            DEFAULT_REMOTE_DISPLAY_NAME: "Participant",
            MOBILE_APP_PROMO: false,
            DEFAULT_BACKGROUND: "#F6A851",
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
            SET_FILMSTRIP_ENABLED: false,
            DISABLE_FOCUS_INDICATOR: true,
            DISPLAY_WELCOME_FOOTER: false,
            DISABLE_VIDEO_BACKGROUND: true,
            HIDE_DEEP_LINKING_LOGO: true,
            CLOSE_PAGE_GUEST_HINT: false,
            HIDE_INVITE_MORE_HEADER: true,
          },
          onload: () => {
            setHasjoinedCall(true);
          },
        };
        const api = new JitsiMeetExternalAPI(domain, options);
        // set the video screen subject to empty to avoid displaying meeting id
        api.executeCommand("subject", "");
        api.on("readyToClose", () => {
          if (isProvider) {
            confirmAlert({
              title: "Choose Action",
              message:
                "Please indicate if you want to end the session or rejoin later",
              overlayClassName: "custom-background",
              buttons: [
                {
                  label: "No, I will Rejoin Later",
                  onClick: () => {
                    endSession(api, false);
                  },
                },
                {
                  label: "Yes, End Session",
                  onClick: () => {
                    endSession(api, true);
                  },
                },
              ],
              willUnmount: () => {},
            });
          } else {
            endSession(api, true);
          }
        });
      } catch (err) {
        logger.error(err);
      }
    };
    setupConferenceCall(hasJoinedCall);

    return () => {
      setHasjoinedCall(true);
    };
  }, [hasJoinedCall]);
  return (
    <div className="conference-call-container">
      <ConferenceCallWindow id="conference-call-window" />
      <Loader
        visible={!hasJoinedCall}
        text="Loading Secured Teleconference Session ... "
      />
    </div>
  );
}

export default ConferenceCall;
