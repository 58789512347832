import React, { useEffect } from "react";
import { Steps, Step } from "react-step-builder";
import styled from "styled-components";
import { fontFamily } from "../../../assets/fontFamily";
import { device } from "../../../assets/breakbpoints";
import { InitialStep } from "./initailstep";
import { FinalStep } from "./finalstep";
import { useScreenNameContext } from "../../context/screenNameContext";
import { MiddleStep } from "./middlestep";

const StyledNote = styled.div`
  background-color: #fff;
  border-radius: 16px;
  width: 100%;

  & h1 {
    color: #131523;
    font-size: 16px;
    font-family: ${fontFamily.Inter};
    font-weight: 700;
  }

  & > div {
    width: 950px;
    margin: 10px 10px;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.2vw;
    }
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    div {
      width: 100%;
    }
  }
`;

export const TakeNote = () => {
  const { setCurrentPageName } = useScreenNameContext() as any;

  useEffect(() => {
    setCurrentPageName("Progress Note");
  });

  return (
    <StyledNote>
      <Steps>
        <Step component={InitialStep} />
        <Step component={MiddleStep} />
        <Step component={FinalStep} />
      </Steps>
    </StyledNote>
  );
};
