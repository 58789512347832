import React from "react";

export const MiddleIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24.0006" cy="23.6842" r="23.6842" fill="#41748A" />
    <rect
      x="21.1299"
      y="15.2632"
      width="5.74163"
      height="16.8421"
      rx="2.87081"
      fill="white"
    />
    <rect
      x="28.7852"
      y="19.0908"
      width="5.74162"
      height="9.1866"
      rx="2.87081"
      fill="white"
    />
    <rect
      x="13.4746"
      y="19.0908"
      width="5.74163"
      height="9.1866"
      rx="2.87081"
      fill="white"
    />
  </svg>
);
