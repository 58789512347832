import React from "react";
import { FiCornerUpLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import { useScreenNameContext } from "../../context/screenNameContext";

declare const window: any;

const StyledBackButton = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  border-style: solid;
  border-color: coral;
  width: fit-content;
  padding: 10px;
  font-family: ${fontFamily.body};
  font-weight: 400;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 20px;

  & p {
    margin-bottom: 0px;
    margin-left: 10px;
  }

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledFiCornerUpLeft = styled(FiCornerUpLeft)`
  margin: 0px;
  font-size: 24px;
  font-weight: bolder;
`;

export const BackButton = (props: {
  pageTitle?: string;
  location?: string;
  canReload?: boolean;
}) => {
  const history = useHistory();
  const { setCurrentPageName } = useScreenNameContext() as any;
  const handleBackNav = () => {
    if (props.location) {
      history.push(props.location);
    } else {
      history.goBack();
    }

    setCurrentPageName(props.pageTitle);

    if (props.canReload) {
      window.location.reload();
    }
  };

  return (
    <StyledBackButton onClick={handleBackNav}>
      <StyledFiCornerUpLeft />
      <p>Back</p>
    </StyledBackButton>
  );
};
