import React from "react";
import {
  StyledBlackTd,
  StyledfadeTd,
  StyledFancyTd,
  StyledSpecialFontTd,
} from "../styles/tableRowStyle";
import { StyledButton } from "./StyledButton";
import { getUniqueId } from "../../../utils/hash";

export const AnalyticTableRow = (props: {
  data: {
    sessionTitle: string;
    location: string;
    patient: string;
    status: string;
    timeStamp: string;
    amountPaid: string;
    actions?: any[];
  };
}) => (
  <tr>
    <StyledBlackTd>{props.data.sessionTitle}</StyledBlackTd>
    <StyledfadeTd>{props.data.location}</StyledfadeTd>
    <td>{props.data.patient}</td>
    <StyledfadeTd>{props.data.status}</StyledfadeTd>
    <StyledSpecialFontTd className="specialFont">
      {props.data.timeStamp}
    </StyledSpecialFontTd>
    <td>
      {" "}
      <StyledFancyTd>{props.data.amountPaid}</StyledFancyTd>
    </td>
    {props.data.actions ? (
      <>
        <td>
          {Object.values(props.data.actions).map((action: any) => {
            const rowAction =
              action.type === "link" ? (
                <a href={action.handler(action.param)}>
                  {action.text}
                  {action.icon}
                </a>
              ) : (
                <StyledButton
                  key={`${getUniqueId()}`}
                  icon={action.icon}
                  text={action.text}
                  onClick={() => {
                    action.handler(action.param);
                  }}
                />
              );
            return rowAction;
          })}
        </td>
      </>
    ) : (
      ""
    )}
  </tr>
);
