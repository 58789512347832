import React from "react";
import { DatePicker, Select } from "antd";
import styled from "styled-components";
import { fontFamily } from "../../../assets/fontFamily";
import { theme } from "../../../assets/theme";
import { device } from "../../../assets/breakbpoints";

export const { Option } = Select;

export const StyledReportContainer = styled.div`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  width: 100%;
  height: 80%;
`;

export const StyledToolBar = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0 0;
`;

export const StyledReportBody = styled.div`
  background: white;
  max-height: 80%;
  box-sizing: border-box;
  padding: 0.75in 0.4in 0.4in 0.5in;
  overflow: auto;

  @media print {
    page-break-before: always;
    size: potrait;
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  font-family: ${fontFamily.body};
  padding: 7px 5px;

  & .ant-picker-input > input:placeholder-shown {
    font-size: 11px;
    cursor: pointer;
    display: table-cell;
    background: white;
    color: inherit;
    padding: 5px;
    min-height: 2em;
  }

  & .ant-picker-input > input {
    color: #030229;
    font-size: 11px;
    cursor: pointer;
    display: table-cell;
    background: white;
    color: inherit;
    padding: 5px;
    min-height: 2em;
  }
`;

export const StyledSelect = styled(Select)`
  font-size: 11px;
  color: ${theme.black};
  font-weight: 500;
  width: 100px;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px 0px 0px 2px !important;
  }

  @media ${device.laptop} {
    font-size: 0.7vw;
    width: 7vw;
  }
`;

export const StyledExport = styled.div`
  justify-content: center;
  font-family: ${fontFamily.body};
  font-weight: 600;
  color: #16192c;
  padding: 7px 10px;
`;

export const DateInput = (props: {
  value: any;
  placeholder: string;
  format: string;
  onChange: any;
}) => (
  <StyledDatePicker
    value={props.value}
    placeholder={props.placeholder}
    bordered={false}
    format={props.format}
    onChange={props.onChange}
    allowClear
  />
);

export const StyledSpan = styled.span`
  color: black;
  font-weight: bold;
  margin-right: 10px;
  padding-left: 30px;
`;

export const StyledPrinterSpan = styled.span`
  color: black;
  font-weight: bold;
  margin-right: 10px;
  padding-left: 30px;
  cursor: pointer;
`;

export const StyledSpanDate = styled.span`
  color: #8394a1;
  font-size: 14px;
`;

export const ReportHeader = styled.span`
  font-size: 36px;
  line-height: 48px;
  font-weight: 300;
  color: #0075dd;
  margin-bottom: 20px;
`;

export const ReportLine = styled.div`
  border-top: 2px solid #f6a851;
  margin-bottom: 24pt;
  margin-top: 20px;
  pagebreakinside: avoid;
`;

export const ReportGroupHeader = styled.div`
  border-bottom: 1px solid #062942;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #062942;
  font-weight: bold;
`;

export const ReportGroupFooter = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  color: #062942;
  font-weight: bold;
`;

export const ReportInner = styled.table`
  margin-top: 20px;
  width: 100%;
  background-color: #fff;
`;

export const ReportLogo = styled.img`
  margin-bottom: 20px;
  width: 100px;
`;

export const StyledGroupHead = styled.tr`
  background-color: #fafafb;
  text-transform: uppercase;
  margin-top: 20px;
  padding-top: 10;
  padding-bottom: 10;
  font-family: ${fontFamily.heading};
  color: #062942;
  font-weight: 700;
  font-size: 14px;
`;

const NoEntriesSpan = styled.span`
  font-weight: 600;
  display: table;
  margin: 0 auto;
`;
export class ReportBody extends React.PureComponent<ReportBodyProps> {
  render() {
    return this.props.data ? (
      <>
        <StyledReportBody>
          <ReportLogo
            src="https://humantold.com/wp-content/themes/humantold/assets/images/human-told-logo.svg?1604507125"
            alt="Report Logo"
          />
          <br />
          <ReportHeader>{this.props.reportTitle}</ReportHeader>
          <br />
          <StyledSpanDate>
            {" "}
            From {this.props.reportStartDate.format("MMMM D, yyyy")} to{" "}
            {this.props.reportEndDate.format("MMMM D, yyyy")}
          </StyledSpanDate>
          <br />
          <ReportLine />
          {this.props.component && this.props.component.length > 0 ? (
            <>
              <ReportInner>{this.props.component}</ReportInner>
            </>
          ) : (
            <>
              <NoEntriesSpan>
                {" "}
                No report entries found in this time range
              </NoEntriesSpan>
            </>
          )}
        </StyledReportBody>
      </>
    ) : (
      ""
    );
  }
}

interface ReportBodyProps {
  reportTitle: string;
  reportStartDate: any;
  reportEndDate: any;
  component: any;
  data: any;
}
