import { Table } from "antd";
import { ColumnType } from "antd/lib/table";
import {
    Dependent
} from "medcon_types/ChangeHealthCare";
import React from "react";

interface DependentsDisplayTableColumn extends ColumnType<Dependent> {
    dataIndex: keyof Dependent | Array<keyof Dependent>
}

function DependentsDisplay({ dependents }: { dependents: Dependent[] }) {
  const columns: DependentsDisplayTableColumn[] = [
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Relation to subscriber",
      dataIndex: "relationToSubscriber",
      key: "relationToSubscriber",
    },
  ];
  return (
    <>
      <div className="ant-descriptions-title">Dependents ({dependents.length})</div>
      <Table dataSource={dependents} columns={columns} />
    </>
  );
}

export default DependentsDisplay;