import React from "react";
import styled from "styled-components";
import { device } from "../../../assets/breakbpoints";

const StyledAppointmentsIcon = styled.div<{
  borderColor: string;
  large: boolean;
}>`
  color: ${(props) => props.color};
  font-size: 13px;
  border-radius: 2px;
  display: grid;
  place-items: center;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.borderColor};
  background-color: #fff;
  margin: auto;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
  @media ${device.laptop} {
    font-size: ${(props) => (props.large ? "1.2vw" : "1.1vw")};
  }
`;

export const IconBorder = (props: {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  large: boolean;
  color: string;
  borderColor: string;
  icon: JSX.Element;
  text?: string;
}) => (
  <StyledAppointmentsIcon
    onClick={props.onClick}
    large={props.large}
    color={props.color}
    borderColor={props.borderColor}
  >
    {props.icon}
    {props.text}
  </StyledAppointmentsIcon>
);
