import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaWifi } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import { MiddleIcon } from "../../../assets/svgs/MiddleIcon";
import { theme } from "../../../assets/theme";
import { databaseRef, firebaseAuth } from "../../../store/firebase";
import SessionStore from "../../../store/session";
import { AppointmentType, FeatureFlag } from "../../../types/enum";
import { ProviderPath } from "../../../types/provider";
import TSession, { SessionStatus } from "../../../types/session";
import { WaitlistNotifications } from "../../provider/WaitlistNotifications";
import ConferenceCall from "./ConferenceCall";
import { FeaturesContext } from "../../context/featuresContext";
import { canAccessFeature } from "../../../utils/feature-helper";

declare const Ybug: any;
declare const FS: any;
interface ToolBarProps {
  isProvider?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isActive?: boolean;
  sessionTitle: string;
  noOfParticipants: number;
  sessionStartTime: string;
  notificationComponent: JSX.Element;
}

const StyledToolBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  & h1 {
    font-size: 24px;
    font-family: ${fontFamily.heading};
    font-weight: 500;
    margin: 0px;
  }
  & > div {
    margin-left: 30px;
  }
  @media ${device.laptop} {
    flex-wrap: nowrap;
  }
`;

const StyledWifi = styled.div<{ isActive?: boolean }>`
  display: flex;
  color: ${(props) => (props.isActive ? "#EB5757" : theme.mutedColor)};
  align-items: center;
  justify-content: center;
  height: fit-content;
  font-weight: 500;
  font-family: ${fontFamily.body};
  & p {
    margin: 0px;
    margin-left: 8px;
  }
`;

const StyledControlButton = styled.button`
  outline: none;
  box-shadow: none;
  border: none;
  background-color: #fff;
  display: flex;
  place-items: center;
  cursor: pointer;
  padding: 12px 12px;
  font-size: 16px;
  border-radius: 4px;
  &:focus,
  &:hover {
    outline: none;
    box-shadow: none;
    border: none;
  }
  @media ${device.laptop} {
    font-size: 1.2vw;
  }
`;

const StyledCallEnded = styled.div`
  background-color: #f2f5f8;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCallEndedNotification = styled.div`
  width: 95%;
  & > div:first-child {
    width: 100%;
    background-color: #f6faff;
    height: 10vh;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    & > div {
      position: absolute;
      bottom: -35%;
      height: fit-content;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  & > div:last-child {
    text-align: center;
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
    padding-bottom: 20px;
    padding-top: 40px;
    & h1 {
      font-weight: 600;
      font-family: ${fontFamily.body};
      font-size: 26px;
    }
    & p {
      font-size: 16px;
      font-family: ${fontFamily.body};
      font-weight: 400;
      color: ${theme.mutedColor};
      margin-bottom: 30px;
    }
    & div {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      & button:last-child {
        margin-left: 10px;
      }
    }
  }
  @media ${device.laptop} {
    width: 35%;
    & > div:last-child {
      & h1 {
        font-weight: 600;
        font-family: ${fontFamily.body};
        font-size: 1.5vw;
      }
      & p {
        font-size: 1.1vw;
      }
    }
  }
  @media ${device.desktop} {
    & > div:first-child {
      height: 7vh;
      & > div {
        bottom: -25%;
      }
    }
  }
`;

const StyledHomePageButton = styled(StyledControlButton)`
  background-color: ${theme.secondaryColor};
  color: #fff;
`;

const StyledNotePageButton = styled(StyledControlButton)`
  background-color: ${theme.secondaryColor};
  color: #fff;
`;

const StyledSignOutButton = styled(StyledControlButton)`
  background-color: #eb5757;
  color: #fff;
`;

const StyledChatBox = styled.div`
  height: 80vh;
  width: 100%;
  position: relative;
  margin-top: 20px;
  border-radius: 20px;
  overflow: hidden;
`;

const SessionRoomDiv = styled.div`
  padding-top: 3px;
  padding-left: 35px;
  padding-right: 35px;
`;

const MediumToolBar = (props: ToolBarProps) => (
  <StyledToolBar>
    <div
      style={{
        width: "100%",
        marginLeft: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <h1>{props.sessionTitle}</h1>
      <div>{props.notificationComponent}</div>
    </div>
    <div
      style={{
        marginLeft: "0px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <StyledWifi isActive={props.isActive}>
        <FaWifi />
        <p>{props.isActive ? "Live" : "Ended"}</p>
      </StyledWifi>
    </div>
  </StyledToolBar>
);

const LargeToolBar = (props: ToolBarProps) => (
  <StyledToolBar>
    <h1>{props.sessionTitle}</h1>
    <StyledWifi isActive={Boolean(props.isActive)}>
      <FaWifi />
      <p>{props.isActive ? "Live" : "Ended"}</p>
    </StyledWifi>
    <div style={{ marginLeft: "auto" }} />
    {props.notificationComponent}
  </StyledToolBar>
);

const MiniToolBar = (props: ToolBarProps) => (
  <StyledToolBar>
    <div style={{ display: "flex", alignItems: "center", marginLeft: "0px" }}>
      <h1>{props.sessionTitle}</h1>
      {props.notificationComponent}
    </div>
    <div
      style={{
        marginTop: "20px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginLeft: "0px",
        justifyContent: "space-between",
      }}
    >
      <StyledWifi isActive={props.isActive}>
        <FaWifi />
        <p>{props.isActive ? "Live" : "Ended"}</p>
      </StyledWifi>
    </div>
  </StyledToolBar>
);

const CallEnded = ({
  isProvider,
  onSignOut,
  onGoHome,
  onTakeNote,
  featureAccessible,
}: {
  isProvider?: boolean;
  onSignOut?: () => void;
  onGoHome: () => void;
  onTakeNote: () => void;
  featureAccessible?: boolean;
}) => (
  <StyledCallEnded>
    <StyledCallEndedNotification>
      <div>
        <div>
          <MiddleIcon />
        </div>
      </div>
      <div>
        <h1>Session Ended</h1>
        <p>{isProvider ? "" : "Thank you for choosing Humantold"}</p>
        <div>
          <StyledHomePageButton onClick={onGoHome}>
            {isProvider ? "Return to Dashboard" : "Return to Homepage"}
          </StyledHomePageButton>
          {isProvider && featureAccessible ? (
            <StyledNotePageButton onClick={onTakeNote}>
              Take Note
            </StyledNotePageButton>
          ) : (
            ""
          )}
          {!isProvider && (
            <StyledSignOutButton onClick={() => onSignOut && onSignOut()}>
              Sign out
            </StyledSignOutButton>
          )}
        </div>
      </div>
    </StyledCallEndedNotification>
  </StyledCallEnded>
);

export const SessionRoom = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userType,
}: {
  userType: "patient" | "provider";
}) => {
  const { features } = useContext(FeaturesContext) as any;
  const featureAccessible = canAccessFeature(features, FeatureFlag.NOTETAKING);
  const history = useHistory();
  const browserLocation = useLocation();
  const [toolBarType, setToolBarType] = useState("large");
  const [currentSession, setCurrentSession] = useState({} as TSession);
  const [clientEndedCall, setClientEndedCall] = useState(false);
  // Appointment details
  const [sessionDetails] = useState(
    (browserLocation.state as any)?.sessionDetails as {
      paymentId: string;
      participant: {
        name: string;
        id: string;
        isProvider?: boolean;
        providerId?: boolean;
      };
    }
  );

  useEffect(() => {
    setTimeout(() => {
      if (typeof Ybug !== "undefined") {
        Ybug.hide();
      }

      if (typeof FS !== "undefined") {
        FS.shutdown();
      }
    }, 5000);
  }, []);

  useEffect(() => {
    const getSessionDetails = async () => {
      if (!sessionDetails) {
        history.push("/patient/signin");
      } else {
        const sessionStore = new SessionStore(
          undefined,
          sessionDetails.paymentId
        );
        const fetchedSession = await sessionStore.getSession();
        if (fetchedSession) {
          const hasParticipant = (fetchedSession.participants || []).some(
            (p) => p.id === sessionDetails.participant.id
          );
          if (!hasParticipant) {
            fetchedSession.participants = [
              ...(fetchedSession.participants || []),
              {
                id: sessionDetails.participant.id,
                name: sessionDetails.participant.name,
                joined: new Date(),
              },
            ];

            // Update session with participant.
            await sessionStore.saveSession(fetchedSession);
          }

          // listen for changes to session.
          const sessionRef = databaseRef.child(
            `session/${sessionDetails.paymentId}`
          );
          sessionRef.on("value", (snapshot) => {
            const updatedSession = snapshot.val() as TSession;
            setCurrentSession(updatedSession);
          });

          setCurrentSession(fetchedSession);
        }
      }
    };
    getSessionDetails();

    return () => {
      setCurrentSession({} as any);
    };
  }, [sessionDetails]);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setToolBarType("large");
    } else if (window.innerWidth < 768) {
      setToolBarType("small");
    } else {
      setToolBarType("medium");
    }
  }, [window.innerWidth]);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (user: any) => {
      if (!user) {
        history.push("/");
      }
    });
  }, []);

  const handleEndSession = async () => {
    const sessionToUpdate = { ...currentSession };
    sessionToUpdate.sessionStatus = SessionStatus.ENDED;
    sessionToUpdate.sessionEndDate = new Date();
    if (userType === "provider" || sessionDetails.participant.isProvider) {
      await new SessionStore(undefined, sessionToUpdate.paymentId).saveSession(
        sessionToUpdate
      );
    } else {
      setClientEndedCall(true);
    }
    if (typeof FS !== "undefined") {
      FS.restart();
    }
  };

  const getToolBar = (
    _toolBarType: string,
    notificationComponent: JSX.Element
  ) => {
    const isSessionActive =
      currentSession.sessionStatus === SessionStatus.STARTED;
    const isProvider =
      sessionDetails.participant.isProvider || userType === "provider";
    // show first name only for clients
    const sessionTitleName = isProvider
      ? currentSession.patientPreferredName
      : currentSession.providerName;
    const sessionTitle = `Session with ${sessionTitleName}`;
    const sessionStartTime = moment(currentSession.sessionStartDate).fromNow(
      true
    );
    const callNotificationComponent = isProvider ? (
      notificationComponent
    ) : (
      <></>
    );
    switch (_toolBarType) {
      case "large":
        return (
          <LargeToolBar
            isActive={isSessionActive}
            sessionTitle={sessionTitle}
            noOfParticipants={currentSession.participants.length}
            sessionStartTime={sessionStartTime}
            notificationComponent={callNotificationComponent}
          />
        );
      case "medium":
        return (
          <MediumToolBar
            isActive={isSessionActive}
            sessionTitle={sessionTitle}
            noOfParticipants={currentSession.participants.length}
            sessionStartTime={sessionStartTime}
            notificationComponent={callNotificationComponent}
          />
        );
      default:
        return (
          <MiniToolBar
            isActive={isSessionActive}
            sessionTitle={sessionTitle}
            noOfParticipants={currentSession.participants.length}
            sessionStartTime={sessionStartTime}
            notificationComponent={callNotificationComponent}
          />
        );
    }
  };
  const notificationComponent =
    sessionDetails.participant.id &&
    sessionDetails.paymentId &&
    sessionDetails.participant.name ? (
      <WaitlistNotifications
        providerId={sessionDetails.participant.id}
        paymentId={sessionDetails.paymentId}
        fullName={sessionDetails.participant.name}
      />
    ) : (
      <></>
    );

  if (
    currentSession.sessionStatus === SessionStatus.STARTED &&
    currentSession.appointmentType === AppointmentType.WalkIn &&
    userType === "patient"
  ) {
    history.push(
      sessionDetails.participant.isProvider
        ? ProviderPath.OVERVIEW
        : "/patient/signin"
    );
    return <></>;
  }

  return (
    <SessionRoomDiv>
      {currentSession.sessionStatus &&
        getToolBar(toolBarType, notificationComponent)}
      <StyledChatBox>
        {currentSession.sessionStatus === SessionStatus.STARTED && (
          <ConferenceCall
            meetingId={currentSession.paymentId}
            roomName={`${currentSession.requestedService} Session by ${currentSession.providerName}`}
            participantId={sessionDetails.participant.id}
            displayName={sessionDetails.participant.name}
            email={
              sessionDetails.participant.isProvider
                ? currentSession.providerEmail
                : currentSession.patientEmail
            }
            duration={moment
              .utc(currentSession.appointmentDate!)
              .add(5, "h")
              .toDate()
              .getTime()}
            onMeetingEnd={handleEndSession}
            isProvider={sessionDetails.participant.isProvider}
          />
        )}
        {(currentSession.sessionStatus === SessionStatus.ENDED ||
          clientEndedCall) && (
          <CallEnded
            isProvider={sessionDetails.participant.isProvider}
            onGoHome={async () => {
              if (sessionDetails.participant.isProvider) {
                history.push(ProviderPath.OVERVIEW);
              } else {
                await firebaseAuth.signOut();
                history.push("/patient/signin");
              }
            }}
            onSignOut={async () => {
              await firebaseAuth.signOut();
              history.push("/");
            }}
            onTakeNote={async () => {
              const link = `/provider/note/takenote/?sessionId=${sessionDetails.paymentId}`;
              history.push(link);
            }}
            featureAccessible={featureAccessible}
          />
        )}
      </StyledChatBox>
    </SessionRoomDiv>
  );
};
