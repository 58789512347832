import { Col, DatePicker, Input, Select, TimePicker, Form, Alert } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import styled from "styled-components";
import { Loader } from "../../elements/Loader";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import PatientStore from "../../../store/patient";
import ProviderStore from "../../../store/provider";
import {
  RequestedServiceType,
  Location,
  Currency,
  TimeZone,
  AppointmentType,
} from "../../../types/enum";
import { Patient } from "../../../types/patient";
import { Provider } from "../../../types/provider";
import { cleanUp, getUniqueId } from "../../../utils/hash";
import { useScreenNameContext } from "../../context/screenNameContext";
import { ApptButtonComponent } from "../../elements/PrimaryButtonComponent";
import { BackButton } from "../atoms/BackButton";
import { ApptValue } from "../../../types/appt-value";
import { convertToUTC } from "../../../utils/date-helper";
import { StyledHighlightedMessage } from "../../elements/Alert";

const StyledCreateAppointmentForm = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 51px;
  margin-top: 30px;

  & h1 {
    color: #131523;
    font-size: 16px;
    font-family: ${fontFamily.Inter};
    font-weight: 700;
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.2vw;
    }
  }
`;

export const CreateAppointmentForm = () => {
  const { setCurrentPageName } = useScreenNameContext() as any;
  setCurrentPageName("Appointment");
  const patientStore = new PatientStore();
  const providerStore = new ProviderStore();
  const [loader, showLoader] = useState(true);
  const browserLocation = useLocation();
  const [provider] = useState(
    (browserLocation.state as any)?.provider as Provider
  );
  const parsedQueryParams = queryString.parse(browserLocation.search || "");
  const [alertVisible, setAlertVisible] = useState(false);
  const [actionPerformed, setActionPerfomed] = useState("");
  const [extApptId] = useState(parsedQueryParams.appointmentId as string);
  const [extProviderId] = useState(parsedQueryParams.providerId as string);
  const [extPatientId] = useState(parsedQueryParams.patientId as string);
  const [appt, setAppt] = useState({} as ApptValue);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [patientId, setPatientId] = useState<string>("Select Client...");
  const [apptDate, setApptDate] = useState(moment());
  const [copay, setCopay] = useState("");
  const [location, setLocation] = useState("Select Location...");
  const [recurrentRule, setRecurrentRule] = useState("");
  const [requestType, setRequestType] = useState("Select Service...");
  const [appointmentType, setAppointmentType] = useState(
    "Select Appointment Type..."
  );
  const [time, setTime] = useState(moment());
  const [timeZone, setTimeZone] = useState("Select Timezone...");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [canReload, setCanReload] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] =useState<any>("success");

  useEffect(() => {
    if (extApptId && extProviderId && extPatientId) {
      setCurrentPageName("Update Appointment");
      providerStore
        .getProviderAppointment(extProviderId, extPatientId, extApptId)
        .then((data: ApptValue) => {
          if (data) {
            setApptDate(moment(data.appointmentDate));
            setCopay(data.copayAmount ? data.copayAmount!.toString() : "0.00");
            setLocation(data.location);
            setRecurrentRule(data.recurrentRule);
            setRequestType(data.requestedService);
            setTime(moment(data.time));
            setTimeZone(data.timeZone!);
            setFirstName(data.clientFirstName!);
            setLastName(data.clientLastName!);
            setAppointmentType(data.appointmentType!);
            showLoader(false);
            setAppt(data);
            setActionPerfomed("updated");
          }
        });
    } else {
      setCurrentPageName("Create Appointment");
      patientStore.getAllPatient().then((data: Patient[]) => {
        setPatients(data);
        setActionPerfomed("created");
        showLoader(false);
      });
    }
  }, [extApptId, extProviderId, extPatientId]);

  function SaveAppointment() {
    try{
      showLoader(true);
      let patientData = {} as Patient;
      let uniqueApptId = "";
      let providerId = "";

      if (extApptId) {
        patientData.email = appt?.clientEmail!;
        patientData.firstName = appt?.clientFirstName!;
        patientData.lastName = appt?.clientLastName!;
        patientData.contactNumber = appt?.clientPhoneNo!;
        patientData.preferredName = appt?.clientPreferedName!;
        patientData.id = appt?.patientId!;
        providerId = extProviderId;
        uniqueApptId = extApptId;
      } else {
        Object.values(patients).forEach((patient: Patient) => {
          if (patient.id === patientId) {
            patientData = patient;
          }
        });
        providerId = provider.id;
        uniqueApptId = getUniqueId();
      }
      const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
      const appointmentTime = time.format("HH:mm");
      const appointmentDate = apptDate.format("YYYY-MM-DD");
      const updatedAppointmentDate = convertToUTC(
        `${appointmentDate}T${appointmentTime}`,
        timeZone
      );
      const endDateTime = convertToUTC(
        `${appointmentDate}T${appointmentTime}`,
        timeZone
      ).add(1, "hours");
      const newAppt = {
        appointmentDate: updatedAppointmentDate.format(dbDateFormat),
        appointmentDateTs: updatedAppointmentDate.valueOf(),
        appointmentEndDate: endDateTime.format(dbDateFormat),
        appointmentEndDateTs: endDateTime.valueOf(),
        clientEmail: patientData.email,
        clientFirstName: patientData.firstName,
        clientLastName: patientData.lastName,
        clientPhoneNo: patientData.contactNumber,
        clientPreferedName: patientData.preferredName,
        copayAmount: parseFloat(copay),
        currency: Currency.USD.toString(),
        id: uniqueApptId,
        location,
        patientId: patientData.id,
        recurrentRule,
        appointmentType,
        requestedService: requestType? requestType.toString() : RequestedServiceType.Therapy,
        startingDate: updatedAppointmentDate.format(dbDateFormat),
        startingDateTs: updatedAppointmentDate.valueOf(),
        time: updatedAppointmentDate.format(dbDateFormat),
        timeZone,
      };
      providerStore
        .patchAppointment(newAppt, providerId, patientData.id, uniqueApptId)
        .then(() => {
          setAlertVisible(true);
          setCanReload(true);
          showLoader(false);
          setAlertType("success");
          setAlertMessage(`Appointment ${actionPerformed} successfully, navigate to the Schedule page to see the updated appointment.`);
        }).catch(() => {
          showLoader(false);
          setAlertMessage("Unable to update appointment, please fill all fields");
          setAlertVisible(true);
          setAlertType("warning");
        });
    } catch(ex){
      showLoader(false);
      setAlertMessage("Unable to update appointment, please fill all fields");
      setAlertVisible(true);
      setAlertType("warning");
    }
  }

  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 20 }}>
        <BackButton
          canReload={canReload}
          location="/provider/schedule"
          pageTitle="Schedule"
        />
        <br />
        <StyledHighlightedMessage>
          <p>
            Please note that the changes made to the appointment here is for
            today and will be overwritten when the appointment is synced from
            the intake system tommorrow.
          </p>
        </StyledHighlightedMessage>
        {alertVisible ? (
          <>
            <br />
            <Alert
              message={alertMessage}
              type={alertType}
              showIcon
              closable
              onClose={() => {
                setAlertVisible(false);
              }}
            />
          </>
        ) : (
          ""
        )}

        <StyledCreateAppointmentForm>
          <h1>Appointment Information</h1>

          <br />

          <Form labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
            {extApptId ? (
              ""
            ) : (
              <Form.Item
                label="Clients"
                style={{ float: "left", width: "50%" }}
              >
                <Select
                  value={patientId}
                  data-ybug-sensitive 
                  className="fs-mask"
                  onChange={(e: any) => setPatientId(e)}
                >
                  {Object.values(patients).map((patient: Patient) => (
                    <Select.Option key={patient.id} value={patient.id}>
                      {`${patient.firstName} ${patient.lastName} (${patient.contactNumber})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <div style={{ clear: "both" }} />
            {extApptId ? (
              <Form.Item label="Client" style={{ float: "left", width: "50%" }}>
                <Input
                  readOnly
                  type="text"
                  data-ybug-sensitive
                  className="fs-mask"
                  value={`${firstName} ${lastName}`}
                />
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item label="Service" style={{ float: "left", width: "50%" }}>
              <Select
                value={requestType}
                onChange={(e: any) => setRequestType(e)}
              >
                {Object.values(RequestedServiceType).map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div style={{ clear: "both" }} />
            <Form.Item label="Date" style={{ float: "left", width: "50%" }}>
              <DatePicker
                value={apptDate}
                format="MM/DD/YYYY"
                data-ybug-sensitive
                className="fs-mask"
                placeholder="MM/DD/YYYY"
                onChange={(e: any) => setApptDate(e)}
              />
            </Form.Item>
            <Form.Item label="Time" style={{ float: "left", width: "50%" }}>
              <TimePicker
                value={time}
                showNow
                data-ybug-sensitive
                className="fs-mask"
                onChange={(e: any) => setTime(e)}
              />
            </Form.Item>
            <div style={{ clear: "both" }} />
            <Form.Item label="Location" style={{ float: "left", width: "50%" }}>
              <Select value={location} onChange={(e: any) => setLocation(e)}>
                {Object.values(Location).map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Time Zone"
              style={{ float: "left", width: "50%" }}
            >
              <Select value={timeZone} onChange={(e: any) => setTimeZone(e)}>
                {Object.values(TimeZone).map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Appointment Type"
              style={{ float: "left", width: "50%" }}
            >
              <Select
                value={appointmentType}
                onChange={(e: any) => setAppointmentType(e)}
              >
                {Object.values(AppointmentType).map((value) => (
                  <Select.Option key={value} value={value}>
                    {cleanUp(value)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <div style={{ textAlign: "center" }}>
            <ApptButtonComponent
              buttonText={extApptId ? "Update" : "Create"}
              onClick={SaveAppointment}
            />
          </div>
        </StyledCreateAppointmentForm>
      </Col>
      <Loader visible={loader} text="Saving ... " />
    </>
  );
};
