import styled from "styled-components";
import { fontFamily } from "../../../assets/fontFamily";
import { theme } from "../../../assets/theme";

export const StyledBlackTd = styled.td`
  color: #27272e;
  font-weight: 500;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
`;

export const StyledfadeTd = styled.td`
  color: ${theme.secondaryColor};
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
`;

export const StyledSpecialFontTd = styled.td`
  font-family: ${fontFamily.Inter} !important;
  font-weight: 400;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
`;

export const StyledFancyTd = styled.div`
  width: fit-content;
  height: fit-content;
  background-color: #deffee;
  padding: 4px 10px;
  color: #66cb9f;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
`;
