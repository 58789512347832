import { Alert, Button, Col, Row, Tag, Space } from "antd";
import { concat, groupBy } from "lodash";
import { BenefitsInformation } from "medcon_types/ChangeHealthCare";
import React, { useEffect, useState } from "react";
import { logAnalyticsEvent } from "../analytics";
import { MedconAnalyiticsEvent } from "../types";
import BenefitsInformationTable from "./BenefitsInformationTable";

function BenefitsDisplay({
  allBenefits,
  matchingBenefits,
}: {
  allBenefits: BenefitsInformation[];
  matchingBenefits: BenefitsInformation[];
}) {
  const [selectedBenefitsList, setSelectedBenefitsList] =
    useState(matchingBenefits);
  const [filteredBenefitList, setFilteredBenefitList] = useState<
    BenefitsInformation[]
  >([]);
  const [selectedBenefitsTag, setSelectedBenefitsTag] = useState<string[]>([
    "Co-Payment",
  ]);

  const groupedBenefits = groupBy(selectedBenefitsList, "name");
  const groupedBenefitNames = Object.keys(groupedBenefits);

  const filterBenefits = (names: string[]): BenefitsInformation[] => {
    if (names.length > 0) {
      return names.reduce((prev, curr) => {
        prev.push(...(groupedBenefits[curr] || []));
        return prev;
      }, [] as BenefitsInformation[]);
    }
    return concat(Object.values(selectedBenefitsList));
  };

  useEffect(() => {
    setFilteredBenefitList(filterBenefits(selectedBenefitsTag));
  }, [selectedBenefitsTag, selectedBenefitsList]);

  return (
    <>
      <Space direction="vertical" size="middle">
        <div className="ant-descriptions-title">
          Benefits ({selectedBenefitsList.length})
        </div>
        <>
          <Row>
            <Col span={24}>
              <Space direction="vertical" size="middle">
                {matchingBenefits.length < 1 && (
                  <Alert
                    message={`There are no ${
                      allBenefits.length > 0 ? "mental health" : ""
                    } benefits found in this plan. ${
                      allBenefits.length > 0
                        ? 'To see all the benefits click on "Show all benefits".'
                        : ""
                    }`}
                    type="warning"
                  />
                )}
                {allBenefits.length > 0 && (
                  <Button
                    onClick={() => {
                      logAnalyticsEvent(
                        MedconAnalyiticsEvent.ELIGIBILITY_CHECK_NO_MENTAL_HEALTH,
                        {
                          allBenefitsCount: allBenefits.length,
                        }
                      );
                      setSelectedBenefitsList(allBenefits);
                    }}
                  >
                    Show all benefits
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </>

        {selectedBenefitsList.length > 0 && (
          <Row>
            <Col span={24}>
              <Space direction="vertical" size="middle">
                {groupedBenefitNames.length > 0 ? (
                  <p className="no-margin-notes">
                    Click on the benefits category below to filter
                  </p>
                ) : (
                  <></>
                )}
                <Row>
                  {groupedBenefitNames.map((tag, idx) => (
                    <Tag.CheckableTag
                      key={`tag-${tag}-${idx}`}
                      checked={selectedBenefitsTag.includes(tag)}
                      onChange={(checked) => {
                        setSelectedBenefitsTag((prevValue) => {
                          let value = [...prevValue];
                          if (checked) {
                            value.push(tag);
                          } else {
                            value = value.filter((v) => v !== tag);
                          }
                          return value;
                        });
                      }}
                    >
                      {tag}
                    </Tag.CheckableTag>
                  ))}
                </Row>
                <BenefitsInformationTable benefits={filteredBenefitList} />
              </Space>
            </Col>
          </Row>
        )}
      </Space>
    </>
  );
}

export default BenefitsDisplay;
