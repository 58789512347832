import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import styled from "styled-components";
import NoteStore from "../../../store/note";
import { useScreenNameContext } from "../../context/screenNameContext";
import { PermissionContext } from "../../context/permissionContext";
import { Note } from "../../../types/note";
import { Loader } from "../../elements/Loader";
import { theme } from "../../../assets/theme";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";

const StyledNote = styled.div`
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
`;

const NotePane = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 13px;
  min-width: fit-content;
  border-bottom: 1px dashed ${theme.mutedColor};
  margin-bottom: 10px;
  width: 100%;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.desktopL} {
    padding: 20px 0px;
  }
`;

const StyledNoteTitle = styled.div`
  font-family: ${fontFamily.heading};
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  width: 40%;

  & p {
    margin-bottom: 0px;
    margin-left: 10px;
  }

  @media ${device.laptop} {
    font-size: 0.9vw;
  }
`;

const StyledNoteText = styled.p`
  font-family: ${fontFamily.body};
  font-weight: 400;
  font-size: 12px;
  color: ${theme.black};
  margin-bottom: 0px;
  text-align: left;
  width: 60%;

  & span {
    color: ${theme.mutedColor};
  }

  @media ${device.laptop} {
    font-size: 0.9vw;
  }
`;

export const NoteDetails = () => {
  const [loader, showLoader] = useState(true);
  const { setCurrentPageName } = useScreenNameContext() as any;
  const { permission } = useContext(PermissionContext);
  const browserLocation = useLocation();
  const parsedQueryParams = queryString.parse(browserLocation.search || "");
  const [sessionId] = useState(parsedQueryParams.sessionId as string);
  const [note, setNote] = useState<Note>();

  useEffect(() => {
    setCurrentPageName("Note Details");

    if (permission) {
      const noteStore = new NoteStore();
      noteStore.getNote(sessionId).then((data: Note) => {
        setNote(data);
        showLoader(false);
      });
    }
  }, [permission]);

  return (
    <>
      {note ? (
        <StyledNote>
          <NotePane>
            <StyledNoteTitle>
              <p>Patient Name:</p>
            </StyledNoteTitle>
            <StyledNoteText>{note.patientName}</StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Patient Email:</p>
            </StyledNoteTitle>
            <StyledNoteText>{note.patientEmail}</StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Diagnosis as derived from clinical assessment:</p>
            </StyledNoteTitle>
            <StyledNoteText>
              {Object.values(note.clinicalCodes).join(", ")}
            </StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Modality:</p>
            </StyledNoteTitle>
            <StyledNoteText>{note.modality.toLocaleLowerCase()}</StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Session Format:</p>
            </StyledNoteTitle>
            <StyledNoteText>{note.sessionFormat}</StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Start Time:</p>
            </StyledNoteTitle>
            <StyledNoteText>{note.startTime}</StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>End Time:</p>
            </StyledNoteTitle>
            <StyledNoteText>{note.endTime}</StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Level of Functioning:</p>
            </StyledNoteTitle>
            <StyledNoteText>
              {note.levelOfFunctioning.toLocaleLowerCase()}
            </StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Stressors:</p>
            </StyledNoteTitle>
            <StyledNoteText>
              {note.stressors.toLocaleLowerCase()}
            </StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Emotional Stability Goal:</p>
            </StyledNoteTitle>
            <StyledNoteText>
              {note.emotionalStabilityGoal.toLocaleLowerCase()}
            </StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Career Goal:</p>
            </StyledNoteTitle>
            <StyledNoteText>
              {note.careerGoal.toLocaleLowerCase()}
            </StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Social Anxiety Goal:</p>
            </StyledNoteTitle>
            <StyledNoteText>
              {note.socialAnxietyGoal.toLocaleLowerCase()}
            </StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Subjective (patient expressed concerned):</p>
            </StyledNoteTitle>
            <StyledNoteText>{note.subjective}</StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Objective (what did you observe):</p>
            </StyledNoteTitle>
            <StyledNoteText>{note.objective}</StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Assessment (therapist analysis):</p>
            </StyledNoteTitle>
            <StyledNoteText>{note.assessment}</StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Plan:</p>
            </StyledNoteTitle>
            <StyledNoteText>{note.plan}</StyledNoteText>
          </NotePane>
          <NotePane>
            <StyledNoteTitle>
              <p>Client Response:</p>
            </StyledNoteTitle>
            <StyledNoteText>
              {note.clientResponse.toLocaleLowerCase()}
            </StyledNoteText>
          </NotePane>
        </StyledNote>
      ) : (
        ""
      )}
      <Loader visible={loader} text="Loading ... " />
    </>
  );
};
