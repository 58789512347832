import { databaseRef } from "./firebase";
import { Meeting } from "../types/meeting";
import { TimeZone, VisibilityStatus } from "../types/enum";
import logger from "../utils/logger";

export default class MeetingStore {
  path: string;

  defaultTimeZone: string;

  constructor() {
    this.path = "meeting";
    this.defaultTimeZone = TimeZone.EST;
  }

  async getMeetingById(id: string): Promise<Meeting | null> {
    let meeting = null;
    try {
      const meetingDoc = await databaseRef
        .child(`${this.path}/${id}`)
        .once("value");
      if (meetingDoc.exists()) {
        meeting = meetingDoc.val() as Meeting;
      }
    } catch (err) {
      logger.error(err.message);
    }
    return meeting;
  }

  async saveMeeting(meeting: Meeting): Promise<boolean> {
    let isSaved = false;
    try {
      await databaseRef.child(`${this.path}/${meeting.id}`).update(meeting);
      isSaved = true;
    } catch (err) {
      logger.error(err.message);
    }
    return isSaved;
  }

  async deleteMeeting(meetingId: string): Promise<boolean> {
    let isDeleted = false;
    try {
      await databaseRef
        .child(`${this.path}/${meetingId}`)
        .update({ visibilityStatus: VisibilityStatus.Deleted });
      isDeleted = true;
    } catch (err) {
      logger.error(err.message);
    }
    return isDeleted;
  }

  async getAllMeetings(): Promise<Meeting[]> {
    const meetings: Meeting[] = [];
    try {
      const meetingDoc = await databaseRef
        .child(`${this.path}`)
        .orderByChild("startTimeTs")
        .once("value");
      if (meetingDoc.exists() && Object.keys(meetingDoc.val()).length >= 1) {
        Object.values(meetingDoc.val()).forEach((meeting: any) => {
          if (meeting.visibilityStatus === VisibilityStatus.Active) {
            meetings.push(meeting as Meeting);
          }
        });
      }
    } catch (err) {
      logger.error(err.message);
    }
    return meetings;
  }
}
