import moment from "moment";
import { getFetchBlogFunction } from "../../../store/callableFunctions";

export default class WaitingRoomUtils {
  static getRelativeAppointmentStartTime(sessionStart: Date) {
    const startHour = moment(sessionStart).hour();
    const startMinutes = moment(sessionStart).minute();
    return moment()
      .hour(startHour)
      .minutes(startMinutes)
      .seconds(0)
      .milliseconds(0)
      .toDate();
  }

  static async getLatestBlogEntries() {
    return new Promise((resolve) => {
      getFetchBlogFunction()().then((entries) => resolve(entries));
    });
  }
}
