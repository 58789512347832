export enum TimeZone {
  EST = "EST",
  AKST = "AKST",
  PST = "PST",
  MST = "MST",
  CST = "CST",
  UTC = "UTC",
}

export enum TimeZoneLabel {
  EST = "EST - Eastern Standard Time",
  AKST = "AKST - Alaska Standard Time",
  PST = "PST - Pacific Standard Time",
  MST = "MST - Mountain Standard Time",
  CST = "CST - Central Standard Time",
  UTC = "UTC - Coordinated Universal Time",
}

export enum RecurrentRule {
  Weekly = "WEEKLY",
  BiWeekly = "BI-WEEKLY",
  Monthly = "MONTHLY",
}

export enum RequestedServiceType {
  IndividualTherapy = "INDIVIDUAL THERAPY",
  CouplesTherapy = "COUPLES THERAPY",
  ChildTherapy = "CHILD THERAPY",
  FamilyTherapy = "FAMILY THERAPY",
  TeleTherapy = "TELETHERAPY",
  Therapy = "THERAPY",
}

export enum Currency {
  USD = "USD",
  GBP = "GBP",
  EUR = "EUR",
  CAD = "CAD",
}

export enum CurrencyLabel {
  USD = "USD - US dollar",
  GBP = "GBP - Pound Sterling",
  EUR = "EUR - Euro",
  CAD = "CAD - Canadian Dollar",
}

export enum DateFormat {
  MMDDYYY = "mm/dd/yyyy",
  YYYYMMDD = "yyyy-mm-dd",
  DDMMYYYY = "dd/mm/yyyy",
}

export enum Location {
  Madison = "MADISON",
  UpperEastSide = "UPPER EAST SIDE",
  Brooklyn = "BROOKLYN",
  FinancialDistrict = "FINANCIAL DISTRICT",
  Remote = "REMOTE",
}

// Notification alerts enum

export enum NotificationType {
  Success = "success",
  Warning = "warning",
  Error = "error",
  Info = "info",
}

export enum NotificationPosition {
  Top = "top",
  TopLeft = "top-left",
  TopRight = "top-right",
  Bottom = "bottom",
  BottomLeft = "bottom-left",
  BottomRight = "bottom-right",
}

// Payment related enums

export enum PaymentCaptureType {
  Authorize = "authorize",
  Capture = "capture",
  AuthorizeAndCapture = "authorizeandcapture",
  NoPayment = "no-payment",
}

export enum PaymentStatus {
  Hold = "hold",
  Failed = "failed",
  Success = "success",
  Completed = "completed",
  Refunded = "refunded",
  Voided = "voided",
}

export enum PaymentProvider {
  AuthorizeNet = "authorize.net",
}

export enum Role {
  PROVIDER = "PROVIDER",
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum AppointmentType {
  WalkIn = "walk_in",
  TeleConsult = "teleconsult",
}

export enum VisibilityStatus {
  Active = 0,
  Archived = 1,
  Deleted = 2,
  Hidden = 4,
}

export enum MeetingStatus {
  STARTED = "STARTED",
  ENDED = "ENDED",
}

export enum MeetingType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export enum FeatureStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum FeatureFlag {
  CLAIM = "claim",
  CLIENTINTAKE = "clientIntake",
  NOTETAKING = "noteTaking",
}

export enum LevelOfFunctioning {
  MuchBetter = "MUCH BETTER",
  Better = "BETTER",
  Unchanged = "UNCHANGED",
  Worse = "WORSE",
  MuchWorse = "MUCH WORSE",
}

export enum Stressors {
  SignificantlyIncreased = "SIGNIFICANTLY INCREASED",
  Increased = "INCREASED",
  NoChange = "NO CHANGE",
  Decreased = "DECREASED",
  SignificantlyDecreased = "SIGNIFICANTLY DECRESSED",
}

export enum Progress {
  Complete = "COMPLETE",
  NoChange = "NO CHANGE",
  Progress = "PROGRESS",
  Regression = "REGRESSION",
}

export enum ClientResponse {
  Compliant = "COMPLIANT",
  Engaged = "ENGAGED",
  Hesitating = "HESITATING",
  NotCompliant = "NOT COMPLIANT",
  Resistant = "RESISTANT",
  Ambivalent = "AMBIVALENT",
}

export enum ClinicalCodes {
  C90791 = "90791 -  Psychiatric diagnostic evaluation",
  C90834 = "90834 -  Psychotherapy, 45 minutes with patient",
  C90837 = "90837 -  Psychotherapy, 60 minutes with patient",
  C90832 = "90832 -  Psychotherapy, 30 minutes with patient",
  C90846 = "90846 -  Family psychotherapy (without the patient present), 50 minutes",
  C90847 = "90847 -  Family psychotherapy (conjoint psychotherapy) (with patient present), 50 minutes",
  F01 = "F01 - Vascular dementia",
  F02 = "F02 - Dementia in other diseases classified elsewhere",
  F03 = "F03 - Unspecified dementia",
  F04 = "F04 - Amnestic disorder due to known physiological condition",
  F05 = "F05 - Delirium due to known physiological condition",
  F06 = "F06 - Other mental disorders due to known physiological condition",
  F07 = "F07 - Personality and behavioral disorders due to known physiological condition",
  F09 = "F09 - Unspecified mental disorder due to known physiological condition",
  F10 = "F10 - Alcohol related disorders",
  F11 = "F11 - Opioid related disorders",
  F12 = "F12 - Cannabis related disorders",
  F13 = "F13 - Sedative, hypnotic, or anxiolytic related disorders",
  F14 = "F14 - Cocaine related disorders",
  F15 = "F15 - Other stimulant related disorders",
  F16 = "F16 - Hallucinogen related disorders",
  F17 = "F17 - Nicotine dependence",
  F18 = "F18 - Inhalant related disorders",
  F19 = "F19 - Other psychoactive substance related disorders",
  F20 = "F20 - Schizophrenia",
  F21 = "F21 - Schizotypal disorder",
  F22 = "F22 - Delusional disorders",
  F23 = "F23 - Brief psychotic disorder",
  F24 = "F24 - Shared psychotic disorder",
  F25 = "F25 - Schizoaffective disorders",
  F28 = "F28 - Other psychotic disorder not due to a substance or known physiological condition",
  F29 = "F29 - Unspecified psychosis not due to a substance or known physiological condition",
  F30 = "F30 - Manic episode",
  F31 = "F31 - Bipolar disorder",
  F32 = "F32 - Major depressive disorder, single episode",
  F33 = "F33 - Major depressive disorder, recurrent",
  F34 = "F34 - Persistent mood affective disorders",
  F39 = "F39 - Unspecified mood affective disorder",
  F40 = "F40 - Phobic anxiety disorders",
  F41 = "F41 - Other anxiety disorders",
  F42 = "F42 - Obsessive-compulsive disorder",
  F43 = "F43 - Reaction to severe stress, and adjustment disorders",
  F44 = "F44 - Dissociative and conversion disorders",
  F45 = "F45 - Somatoform disorders",
  F48 = "F48 - Other nonpsychotic mental disorders",
  F50 = "F50 - Eating disorders",
  F51 = "F51 - Sleep disorders not due to a substance or known physiological condition",
  F52 = "F52 - Sexual dysfunction not due to a substance or known physiological condition",
  F53 = "F53 - Mental and behavioral disorders associated with the puerperium, not elsewhere classified",
  F54 = "F54 - Psychological and behavioral factors associated with disorders or diseases classified elsewhere",
  F55 = "F55 - Abuse of non-psychoactive substances",
  F59 = "F59 - Unspecified behavioral syndromes associated with physiological disturbances and physical",
  F60 = "F60 - Specific personality disorders",
  F63 = "F63 - Impulse disorders",
  F64 = "F64 - Gender identity disorders",
  F65 = "F65 - Paraphilias",
  F66 = "F66 - Other sexual disorders",
  F68 = "F68 - Other disorders of adult personality and behavior",
  F69 = "F69 - Unspecified disorder of adult personality and behavior",
  F70 = "F70 - Mild intellectual disabilities",
  F71 = "F71 - Moderate intellectual disabilities",
  F72 = "F72 - Severe intellectual disabilities",
  F73 = "F73 - Profound intellectual disabilities",
  F78 = "F78 - Other intellectual disabilities",
  F79 = "F79 - Unspecified intellectual disabilities",
  F80 = "F80 - Specific developmental disorders of speech and language",
  F81 = "F81 - Specific developmental disorders of scholastic skills",
  F82 = "F82 - Specific developmental disorder of motor function",
  F84 = "F84 - Pervasive developmental disorders",
  F88 = "F88 - Other disorders of psychological development",
  F89 = "F89 - Unspecified disorder of psychological development",
  F90 = "F90 - Attention-deficit hyperactivity disorders",
  F91 = "F91 - Conduct disorders",
  F93 = "F93 - Emotional disorders with onset specific to childhood",
  F94 = "F94 - Disorders of social functioning with onset specific to childhood and adolescence",
  F95 = "F95 - Tic disorder",
  F98 = "F98 - Other behavioral and emotional disorders with onset usually occurring in childhood and adolescence",
  F99 = "F99 - Mental disorder, not otherwise specified",
}
