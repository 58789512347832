import React from "react";
import styled from "styled-components";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import { EmptyTablets } from "./EmptyTablets";

const StyledContainer = styled.div`
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
`;
const StyledUpcomingEventFooter = styled.div`
  text-align: center;
  width: 80vw;
  display: grid;
  place-items: center;

  & h1 {
    font-family: ${fontFamily.heading};
    font-weight: 600;
    font-size: 20px;
  }

  & p {
    font-family: ${fontFamily.body};
    font-weight: 400;
    font-size: 12px;
    color: #7a8593;
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.4vw;
    }

    & p {
      font-size: 0.8vw;
    }
  }

  @media ${device.tablet} {
    width: 50vw;
  }

  @media ${device.laptop} {
    width: 25vw;
  }

  @media ${device.laptopL} {
    width: 20vw;
  }
`;

export const CreateAppointment = () => (
  <StyledContainer>
    <EmptyTablets />
    <StyledUpcomingEventFooter>
      <h1>You do not have an upcoming scheduled appointments.</h1>
      <p>
        You can create a new appointment using the InTake System.
        Contact your Intake System Administrator for appointments
        creation.
      </p>
    </StyledUpcomingEventFooter>
  </StyledContainer>
);
