import React from "react";
import styled from "styled-components";
import { Select } from "antd";
import { InputDiv } from "./InputDiv";

const StyledSelect = styled(Select)`
  width: 100%;
`;

const { Option } = Select;

export const SelectInput = () => (
  <InputDiv>
    <StyledSelect defaultValue="Dr Spring Nelson" bordered={false}>
      <Option value="jack">Jack</Option>
      <Option value="Dr Spring Nelson">Dr Spring Nelson</Option>
      <Option value="Yiminghe">yiminghe</Option>
    </StyledSelect>
  </InputDiv>
);
