/* eslint-disable jsx-a11y/no-static-element-interactions */
import { DatePicker, Input, Pagination, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { FiChevronDown, FiSearch } from "react-icons/fi";
import { FcExport } from "react-icons/fc";
import { TiArrowSortedDown } from "react-icons/ti";
import styled from "styled-components";
import { AnalyticTableRow } from "./AnalyticTableRow";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import { theme } from "../../../assets/theme";
import { Location, RequestedServiceType } from "../../../types/enum";
import { getUniqueId } from "../../../utils/hash";

const { Option } = Select;

const StyledContainer = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: inset 0px -1px 0px #edf2f7;
  border: 1px solid #edf2f7;
`;
const StyledTable = styled.table`
  width: 100%;
  background-color: #fff;
`;

const StyledTableHead = styled.thead`
  background-color: #fafafb;
  text-transform: uppercase;

  & tr {
    & th {
      padding-top: 24px;
      padding-bottom: 24px;
      font-family: ${fontFamily.heading};
      color: #8492a6;
      font-weight: 500;
      font-size: 10px;

      &:first-child {
        padding-left: 24px;
      }
    }
  }

  @media ${device.laptop} {
    & tr {
      & th {
        font-size: 0.7vw;
      }
    }
  }
`;
const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 20px 24px;

  & > div:first-child {
    display: flex;
    align-items: center;

    & > p {
      font-weight: 500;
      font-family: ${fontFamily.heading};
      font-size: 14px;
      color: #1f2d3d;
      margin: 0px;
    }
  }

  @media ${device.laptop} {
    & > div:first-child {
      & > p {
        font-size: 0.97vw;
      }
    }
  }
`;

const StyledTableBody = styled.tbody`
  & tr {
  }

  & td {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 14px;
    font-family: ${fontFamily.heading};
    border-bottom: 1px solid #edf2f7;

    &:first-child {
      padding-left: 24px;
    }
  }

  & .specialFont {
    font-family: ${fontFamily.body};
    font-weight: 400;
    color: #425466;
  }

  @media ${device.laptop} {
    & td {
      font-size: 0.97vw;
    }
  }
`;

const StyledTableFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  padding-top: 50px;

  & p {
    font-family: ${fontFamily.Inter};
    font-weight: 400;
    font-size: 14px;
    color: #7a7a9d;
    margin: 0px;
  }

  @media ${device.laptop} {
    & p {
      font-size: 0.97vw;
    }
  }
`;

const StyledPagination = styled(Pagination)`
  & .anticon svg {
    border: 1px solid ${theme.mutedColor};
    font-size: 25px;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
    margin-left: 10px;
  }

  & .ant-pagination-item a {
    color: ${theme.mutedColor};
    font-family: ${fontFamily.body};
  }

  & .ant-pagination-item-active a {
    color: ${theme.secondaryColor};
    font-weight: bolder;
  }

  &.ant-pagination-item-active {
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border-color: transparent;
    }
  }

  @media ${device.laptop} {
    & .anticon svg {
      font-size: 1.9vw;
    }
  }
`;

const StyledSearchInput = styled.div`
  border: 1px solid #e7e8e9;
  border-radius: 5px;
  min-width: 18vw;
`;
const StyledFiSearch = styled(FiSearch)`
  color: #92929d;
  font-size: 16px;

  @media ${device.laptop} {
    font-size: 1.1vw;
  }
`;

const StyledInput = styled(Input)`
  font-family: ${fontFamily.body};
  font-weight: 400;
  font-size: 10.76px;

  @media ${device.laptop} {
    font-size: 1.06vw;
  }
`;

const StyledShowType = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;
  font-family: ${fontFamily.heading};
  font-size: 11px;
  margin-left: 20px;

  & p {
    margin: 0px;
    color: #7a7a9d;
  }

  @media ${device.laptop} {
    font-size: 0.9vw;
  }
`;

const StyledSelect = styled(Select)`
  font-size: 11px;
  color: ${theme.black};
  font-weight: 500;
  width: 100px;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px 0px 0px 2px !important;
  }

  @media ${device.laptop} {
    font-size: 0.7vw;
    width: 7vw;
  }
`;

const StyledTableMainHeader = styled.div<{ activeState: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  /*  & >div:first-child{
        display: flex;
        align-items: center;
        & h1{
            font-family: ${fontFamily.heading};
            font-weight: ${(props) => (props.activeState ? 700 : "normal")};
            font-size: 18px;
            color: ${(props) =>
    props.activeState ? "#27272E" : theme.mutedColor};
            margin: 0px;
            cursor: pointer;


            &:last-child{
                margin-left: 30px;
            }
        }
    }

    @media ${device.laptop}{
        & >div:first-child{
            & h1{
                font-size: 1.3vw;
            }
        }
    } */
`;

const StyledFilterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .dateDivider {
    color: #7a7a9d;
    font-size: 18px;
    margin-left: 10px;
  }
`;

const StyledFilterLocation = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  border: 0.725px solid #e2e2ea;
  cursor: pointer;

  background-color: #fff;
  border-radius: 5px;

  font-family: ${fontFamily.body};
  font-size: 11px;
  padding-left: 5px;

  & p {
    margin: 0px;
    color: #7a7a9d;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  font-family: ${fontFamily.body};
  padding: 7px 5px;
  cursor: pointer;

  & .ant-picker-input > input:placeholder-shown {
    font-size: 11px;
  }

  & .ant-picker-input > input {
    color: #030229;
    font-size: 11px;
  }
`;

const StyledExport = styled(StyledFilterLocation)`
  justify-content: center;
  font-family: ${fontFamily.body};
  font-weight: 600;
  color: #16192c;
  padding: 7px 10px;
`;

const StyledSessionNameDiv = styled.div<{ activeState: boolean }>`
  display: flex;
  align-items: center;
  & h1 {
    font-family: ${fontFamily.heading};
    font-weight: ${(props) => (props.activeState ? 700 : "normal")};
    font-size: 18px;
    color: ${(props) => (props.activeState ? "#27272E" : theme.mutedColor)};
    margin: 0px;
    cursor: pointer;

    &:last-child {
      margin-left: 30px;
    }
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.3vw;
    }
  }
`;
/* const StyledTiArrowSortedDown = styled(TiArrowSortedDown)`
    border-left: 1px solid #7A7A9D;
    padding-left: 5px;
    font-size: 16px;
` */
const DateInput = (props: {
  value: string;
  placeholder: string;
  format: string;
  onChange: any;
}) => (
  <StyledFilterLocation>
    <StyledDatePicker
      value={moment(props.value)}
      placeholder={props.placeholder}
      suffixIcon={<FiChevronDown />}
      allowClear={false}
      format={props.format}
      onChange={props.onChange}
    />
  </StyledFilterLocation>
);

export const Table = (props: {
  tableTitle: string[];
  handleSessionClicked?: (sessionName?: string) => void;
  isActive?: boolean;
  isActiveSecondary?: boolean;
  SecondSessionName?: string;
  sessionName?: string;
  showLocationFilter?: boolean;
  showServiceFilter?: boolean;
  showDateFilter: boolean;
  showSearch?: boolean;
  showCalenderFilter?: boolean;
  tableName: string;
  data: any[];
  filterByDateHandler?: any;
}) => {
  const dateFormat = "MM/DD/YYYY";
  const [pageSize, setPageSize] = useState(10);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format(dateFormat)
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format(dateFormat)
  );
  const [locationFilter, setLocationFilter] = useState("");
  const [serviceFilter, setServiceFilter] = useState("");
  const [start, setStart] = useState(0);
  const [currentData, setCurrentData] = useState([] as any[]);
  const [tableRows, setTableRows] = useState([] as any[]);
  const tableTitle = props.tableTitle.map((item, index) => {
    const key = `thkey-${index}-${getUniqueId()}`;
    return <th key={key}>{item}</th>;
  });

  const showFilteredRecords = (
    filteredRecords: any[],
    persistData: boolean = true
  ) => {
    // sort by date descending
    const sortedRecords = filteredRecords.sort(
      (a: any, b: any) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    if (persistData) {
      setCurrentData(sortedRecords);
    }
    // show only the first 10 records and paginate the rest
    const slicedRecords = sortedRecords.slice(0, 10);
    const rows = slicedRecords.map((item, index) => {
      const key = `curr-data-${index}-${getUniqueId()}`;
      return <AnalyticTableRow key={key} data={item} />;
    });
    setTableRows(rows);
  };

  const getDataFilteredByLocation = (location: string, data: any[]) => {
    const filteredRecords =
      !location || location === "All"
        ? data
        : data.filter(
            (record) => record.location.toUpperCase() === location.toUpperCase()
          );
    return filteredRecords;
  };

  const getDataFilteredByService = (service: string, data: any[]) => {
    const filteredRecords =
      !service || service === "All"
        ? data
        : data.filter(
            (record) =>
              record.sessionTitle.toUpperCase() === service.toUpperCase()
          );
    return filteredRecords;
  };

  const getDataFilteredByDate = (
    filterStartDate: string,
    filterEndDate: string,
    data: any[] = props.data
  ) => {
    if (!filterStartDate && !filterEndDate) {
      return data;
    }

    const parsedStartDate = filterStartDate ? new Date(filterStartDate) : null;
    const parsedEndDate = filterEndDate ? new Date(filterEndDate) : null;
    let filteredRecords = [];
    if (parsedStartDate && parsedEndDate) {
      filteredRecords = data.filter(
        (record) =>
          new Date(record.date) >= parsedStartDate &&
          new Date(record.date) <= parsedEndDate
      );
    } else if (parsedStartDate) {
      filteredRecords = data.filter(
        (record) => new Date(record.date) >= parsedStartDate
      );
    } else if (parsedEndDate) {
      filteredRecords = data.filter(
        (record) => new Date(record.date) <= parsedEndDate
      );
    }
    return filteredRecords;
  };

  const filterData = (
    location: string,
    service: string,
    filterStartDate: string,
    filterEndDate: string,
    data: any[] = props.data
  ) => {
    let filteredRecords = getDataFilteredByLocation(location, data);
    filteredRecords = getDataFilteredByService(service, filteredRecords);
    filteredRecords = getDataFilteredByDate(
      filterStartDate,
      filterEndDate,
      filteredRecords
    );
    showFilteredRecords(filteredRecords);
  };

  const filterByLocation = (location: string, data: any[] = props.data) => {
    filterData(location, serviceFilter, startDate, endDate, data);
  };

  const filterByService = (service: string, data: any[] = props.data) => {
    filterData(locationFilter, service, startDate, endDate, data);
  };

  const filterByDate = (
    filterStartDate: string,
    filterEndDate: string,
    data: any[] = props.data
  ) => {
    if (filterStartDate && filterEndDate) {
      props.filterByDateHandler(filterStartDate, filterEndDate);
    }
    filterData(
      locationFilter,
      serviceFilter,
      filterStartDate,
      filterEndDate,
      data
    );
  };

  useEffect(() => {
    showFilteredRecords(props.data);
    setCurrentPageSize(
      props.data && props.data.length > 10 ? 10 : props.data.length
    );
    setStart(props.data.length > 0 ? 1 : 0);
  }, [props]);

  const handlePaginationChange = (page: number, pageSizeNum: number = 20) => {
    const end = page * pageSizeNum;
    const pageStart = (page - 1) * pageSizeNum + 1;
    const currentView = currentData.slice(pageStart - 1, end);
    showFilteredRecords(currentView, false);
    if (currentData.length < end) {
      setCurrentPageSize(currentData.length);
    } else {
      setCurrentPageSize(pageSizeNum * page);
    }
    setPageSize(pageSizeNum);
    setCurrentPage(page);
     setStart(pageStart);
  };

  return (
    <>
      <StyledTableMainHeader activeState>
        <StyledSessionNameDiv activeState>
          <h1>
            <span
              onKeyDown={() =>
                props.handleSessionClicked &&
                props.handleSessionClicked(props.sessionName)
              }
              onClick={() =>
                props.handleSessionClicked &&
                props.handleSessionClicked(props.sessionName)
              }
              style={{
                fontWeight: props.isActive ? 700 : "normal",
                color: props.isActive ? "#27272E" : theme.mutedColor,
              }}
            >
              {props.sessionName}
            </span>
          </h1>

          <h1>
            <span
              onKeyDown={() =>
                props.handleSessionClicked &&
                props.handleSessionClicked(props.SecondSessionName)
              }
              onClick={() =>
                props.handleSessionClicked &&
                props.handleSessionClicked(props.SecondSessionName)
              }
              style={{
                fontWeight: props.isActiveSecondary ? 700 : "normal",
                color: props.isActiveSecondary ? "#27272E" : theme.mutedColor,
              }}
            >
              {props.SecondSessionName}
            </span>
          </h1>
        </StyledSessionNameDiv>

        <StyledFilterDiv>
          {props.showLocationFilter && (
            <StyledFilterLocation>
              <p>Location:</p>
              <StyledSelect
                defaultValue="All"
                bordered={false}
                suffixIcon={<TiArrowSortedDown />}
                onChange={(e: any) => {
                  setLocationFilter(e);
                  filterByLocation(e);
                }}
              >
                <Option value="All">All</Option>
                {Object.values(Location).map((key) => (
                  <Option value={key}>{key}</Option>
                ))}
              </StyledSelect>
            </StyledFilterLocation>
          )}
          {props.showServiceFilter && (
            <StyledFilterLocation>
              <p>Service:</p>
              <StyledSelect
                defaultValue="All"
                bordered={false}
                suffixIcon={<TiArrowSortedDown />}
                onChange={(e: any) => {
                  setServiceFilter(e);
                  filterByService(e);
                }}
              >
                <Option value="All">All</Option>
                {Object.values(RequestedServiceType).map((key) => (
                  <Option value={key}>{key}</Option>
                ))}
              </StyledSelect>
            </StyledFilterLocation>
          )}
          {props.showDateFilter && (
            <DateInput
              value={startDate}
              placeholder={startDate}
              format={dateFormat}
              onChange={(e: any) => {
                setStartDate(e);
                if (e) {
                  filterByDate(e, endDate);
                } else {
                  filterData(
                    locationFilter,
                    serviceFilter,
                    startDate,
                    endDate,
                    currentData
                  );
                }
              }}
            />
          )}{" "}
          {props.showDateFilter && <span className="dateDivider">:</span>}
          {props.showDateFilter && (
            <DateInput
              value={endDate}
              placeholder={endDate}
              format={dateFormat}
              onChange={(e: any) => {
                setEndDate(e);
                if (e) {
                  filterByDate(startDate, e);
                } else {
                  filterData(
                    locationFilter,
                    serviceFilter,
                    startDate,
                    endDate,
                    currentData
                  );
                }
              }}
            />
          )}
          <StyledExport>
            <CSVLink data={currentData} filename="session-history.csv">
              <FcExport size={15} />
              Export CSV
            </CSVLink>
          </StyledExport>
        </StyledFilterDiv>
      </StyledTableMainHeader>
      <StyledContainer>
        <StyledHeader>
          <div>
            <p>{props.tableName}</p>
            {props.showCalenderFilter && (
              <StyledShowType>
                <p>Show:</p>
                <StyledSelect
                  defaultValue="This Month"
                  bordered={false}
                  suffixIcon={<TiArrowSortedDown />}
                >
                  <Option value="All">All</Option>
                  <Option value="This Year">This Year</Option>
                  <Option value="This Month">This Month</Option>
                  <Option value="This Week">This Week</Option>
                </StyledSelect>
              </StyledShowType>
            )}
          </div>

          {props.showSearch && (
            <StyledSearchInput>
              <StyledInput
                placeholder="Search..."
                prefix={<StyledFiSearch />}
                bordered={false}
              />
            </StyledSearchInput>
          )}
        </StyledHeader>
        <StyledTable>
          <StyledTableHead>
            <tr>{tableTitle}</tr>
          </StyledTableHead>
          <StyledTableBody>{tableRows}</StyledTableBody>
        </StyledTable>
        <StyledTableFooter>
          <p>{`Showing ${start} - ${currentPageSize} items out of ${currentData.length} result found`}</p>
          <StyledPagination
            onChange={handlePaginationChange}
            pageSize={pageSize}
            current={currentPage}
            defaultCurrent={1}
            total={currentData.length}
            showSizeChanger={false}
            size="small"
          />
        </StyledTableFooter>
      </StyledContainer>
    </>
  );
};
