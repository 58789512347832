import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";

const ProgressLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(76, 175, 80, 0.25);
  opacity: 0.5;
  height: 100% !important;
  width: 100% !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
`;

export const Loader = (props: { visible: boolean; text: string }) => (
  <ProgressLoader
    style={{
      position: props.visible ? "fixed" : "unset",
      display: props.visible ? "flex" : "none",
    }}
  >
    <Spinner message={props.text} />
  </ProgressLoader>
);
