import React from "react";
import { FiClock } from "react-icons/fi";
import { IconBorder } from "./IconBorder";

export const TimeIcon = (props: {
  color: string;
  borderColor: string;
  large?: boolean;
}) => (
  <IconBorder
    color={props.color}
    borderColor={props.borderColor}
    large={Boolean(props.large)}
    icon={<FiClock />}
  />
);
