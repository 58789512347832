import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { FiLayout, FiLock, FiCalendar, FiActivity } from "react-icons/fi";
import { FaCreditCard } from "react-icons/fa";
import styled from "styled-components";
import { Alert, DatePicker, Divider, Form, Input, Modal, Select } from "antd";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";
import docIcon from "../../assets/images/docIcon.png";
import sideImage4 from "../../assets/images/sideImage4.png";
import medconLogo from "../../assets/images/medcon-logo.png";
import buttonIcon from "../../assets/images/buttonIcon.png";
import paymentHeaderImage from "../../assets/images/paymentHeaderImage.png";
import { Loader } from "../elements/Loader";
import { theme } from "../../assets/theme";
import { InputComponent } from "../elements/InputComponent";
import { InputDiv } from "../elements/InputDiv";
import { BackButton } from "../elements/BackButton";
import { OnBoardingTopText } from "../elements/OnBoardingTopText";
import { PrimaryButtonComponent } from "../elements/PrimaryButtonComponent";
import { OnBoardingScaffold } from "../layout/OnBoardingScaffold";
import { Patient } from "../../types/patient";
import countryList from "../../types/countryList";
import { Provider } from "../../types/provider";
import CardAuthorizeRequest from "../../types/card-authorize-request";
import {
  AppointmentType,
  PaymentCaptureType,
  PaymentProvider,
  PaymentStatus,
  RequestedServiceType,
} from "../../types/enum";
import { formatWithCurrency } from "../../utils/hash";
import {
  getFormattedDate,
  getFormattedTime,
  isDateInFuture,
  getDateParts,
} from "../../utils/date-helper";
import { EncryptedCardDetail } from "../../types/encrypted-card-detail";
import { Payment } from "../../types/payment";
import {
  firebaseAuth
} from "../../store/firebase";
import {
  getAuthorizeAndChargeProfileFunction,
  getCallablePaymentFunction,
  getPaymentProfileFunction,
} from "../../store/callableFunctions";
import logger from "../../utils/logger";
import { ApptValue } from "../../types/appt-value";
import { SessionStatus } from "../../types/session";
import PaymentStore from "../../store/payment";
import SessionStore from "../../store/session";
import { StyledMessage, StyledHighlightedMessage } from "../elements/Alert";
import { StyledUpdateButton } from "../elements/ActionButtons";

declare const document: any;
declare const Accept: any;
declare const window: any;

const StyledAlert = styled.div`
  width: 80%;
  margin-bottom: 20px;
`;

const StyledBillPane = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 13px;
  min-width: fit-content;
  border-bottom: 1px dashed ${theme.mutedColor};
  margin-bottom: 30px;
  @media ${device.laptop} {
    width: 50%;
  }
  @media ${device.desktopL} {
    padding: 20px 0px;
  }
`;

const StyledDropDown = styled.div`
  min-width: fit-content;
  justify-content: space-between;
  align-items: center;
  @media ${device.laptop} {
    width: 50%;
  }
`;

const StyledBillTitle = styled.div`
  font-family: ${fontFamily.heading};
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  & p {
    margin-bottom: 0px;
    margin-left: 10px;
  }
  @media ${device.laptop} {
    font-size: 0.9vw;
  }
`;

const StyledPrice = styled.p`
  font-family: ${fontFamily.body};
  font-weight: 600;
  font-size: 14px;
  color: #0f9f7d;
  margin-bottom: 0px;
  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledFiLayout = styled(FiLayout)`
  font-size: 18px;
  color: ${theme.mutedColor};
  @media ${device.laptop} {
    font-size: 1.3vw;
  }
`;

const StyledBillText = styled.p`
  font-family: ${fontFamily.body};
  font-weight: 400;
  font-size: 12px;
  color: ${theme.black};
  margin-bottom: 0px;
  & span {
    color: ${theme.mutedColor};
  }
  @media ${device.laptop} {
    font-size: 0.9vw;
  }
`;

const StyledPaymentHeader = styled.div`
  display: flex;
  /* & > div:first-child{
      display: flex;
      place-items: center;
      width: fit-content;
  } */
  & img {
    width: 13%;
    object-fit: contain;
    height: auto;
    margin-right: 20px;
  }
  & > div {
    & h1 {
      font-family: ${fontFamily.heading};
      font-size: 24px;
      font-weight: 500;
      margin: 0px;
    }
    & p {
      color: ${theme.mutedColor};
      margin: 0px;
      font-family: ${fontFamily.body};
      font-weight: 400;
      font-size: 14px;
    }
  }
  @media ${device.laptop} {
    & > div {
      & h1 {
        font-size: 2.2vw;
      }
      & p {
        font-size: 0.9vw;
      }
    }
  }
`;

const StyledPaymentForm = styled.div`
  width: 100%;
  & div {
    border-radius: 4px;
    margin: 5px 5px 5px 0px;
  }
`;

const StyledInputCollection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  & > div {
    flex-basis: 48%;
  }
`;

const StyledCardInputCollection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  & > div {
    flex-basis: 45%;
  }
`;

const StyledCardCollection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`;

const StyledBillingAddress = styled.div`
  width: 100%;
  & h1 {
    font-family: ${fontFamily.heading};
    font-size: 16px;
    font-weight: 100;
    margin: 0px;
  }
  @media ${device.laptop} {
    & h1 {
      font-size: 1vw;
    }
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100% !important;
  height: 20px;
  letter-spacing: 0.6em;
  font-size: 16px;
`;

const StyledFiActivity = styled(FiActivity)`
  font-size: 18px;
  color: ${theme.mutedColor};
  @media ${device.laptop} {
    font-size: 1.3vw;
  }
`;

const StyledPaymentInput = styled(Input)`
  letter-spacing: 0.75em;
  font-weight: bold;
`;

const DateInput = (props: { onChange: any; value: any, class: string }) => (
  <InputDiv width="100%">
    <FiCalendar />
    <StyledDatePicker
      placeholder="MM/YY"
      format="MM/YY"
      picker="month"
      suffixIcon=""
      bordered={false}
      onChange={props.onChange}
      className={props.class}
      value={props.value}
    />
  </InputDiv>
);

const BillPane = (props: { children: any }) => (
  <StyledBillPane>{props.children}</StyledBillPane>
);

function selectAppointment(
  patient: Patient,
  provider: Provider
): ApptValue | null {
  const { appointments } = provider;
  for (const appointmentId in appointments) {
    const providerAppointments = Object.values(
      provider.appointments[appointmentId] || {}
    );
    const appointmentValues = providerAppointments as ApptValue[];
    const orderedAppointments = _.orderBy(
      appointmentValues,
      ["appointmentDateTs"],
      ["asc"]
    );
    for (const orderedAppointment of orderedAppointments) {
      if (
        orderedAppointment.patientId === patient.id &&
        !moment(orderedAppointment.appointmentDate).add(1, "hours").isBefore()
      ) {
        return orderedAppointment;
      }
    }
  }
  return null;
}

function buildCardRequestData(
  patient: Patient,
  apptInfo: ApptValue,
  providerInfo: Provider
) {
  return {
    firstName: patient.firstName,
    lastName: patient.lastName,
    preferredName: patient.preferredName || patient.firstName,
    appointmentId: apptInfo.id,
    appointmentDateTs: apptInfo.appointmentDateTs,
    recurrentRule: apptInfo.recurrentRule,
    patientId: patient.id,
    providerId: providerInfo.id,
    providerName: providerInfo.fullName,
    patientDob: patient.dob,
    requestedService: apptInfo.requestedService || RequestedServiceType.Therapy,
    amount: apptInfo.copayAmount,
    currency: apptInfo.currency,
    location: apptInfo.location,
    sessionStart: apptInfo.appointmentDate,
    paymentProvider: PaymentProvider.AuthorizeNet,
    paymentCaptureType: PaymentCaptureType.AuthorizeAndCapture,
    patientEmail: patient.email,
    providerEmail: providerInfo.email,
    appointmentType: apptInfo.appointmentType,
  } as CardAuthorizeRequest;
}

export const Pay = () => {
  const [isPaymentModalVisible, setPaymentModalVisible] = useState(false);
  const [loader, showLoader] = useState(false);
  const history = useHistory();
  const browserLocation = useLocation();

  const [alertVisible, setAlertVisible] = useState(false);
  const [
    inputValidationAlertVisible,
    setInputValidationAlertVisible,
  ] = useState(false);

  const [patient] = useState(
    (browserLocation.state as any)?.patient as Patient
  );

  const [providers] = useState(
    (browserLocation.state as any)?.providers as Provider[]
  );

  const [appointment, setAppointment] = useState<ApptValue>();
  const [provider, setProvider] = useState<Provider>();

  const [cardAuthorizeRequest, setCardAuthorizeRequest] = useState(
    {} as CardAuthorizeRequest
  );
  const [loadingMessage, setLoadingMessage] = useState(
    "Loading please wait ..."
  );
  const [cardNumber, setCardNumber] = useState("");
  const [cardCode, setCardCode] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("United States");
  const [postalCode, setPostalCode] = useState("");
  const [lastFourDigits, setLastFourDigits] = useState("");
  const [paymentProfile, setPaymentProfile] = useState<any>();
  const [canSave, setCanSave] = useState(true);
  const [validationMessage, setValidationMessage] = useState(
    "Please fill out all the required fields marked with *"
  );
  const [rawData, setRawData] = useState<any>();
  const [cardToken, setCardToken] = useState<any>();

  if (!providers) {
    history.push("/patient/signin");
    return <></>;
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_AUTHORIZE_NET_URL;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (user: any) => {
      if (!user) {
        history.push("/");
      }
    });
  }, []);

  const setDefaultState = () => {
    showLoader(false);
    setLoadingMessage("Processing payment ...");
  };

  useEffect(() => {
    // check if user has saved payment profile
    showLoader(true);
    const getPaymentProfile = getPaymentProfileFunction();
    getPaymentProfile({ patientId: patient.id })
      .then((response) => {
        if (response.data) {
          setPaymentProfile({ lastFourDigits: response.data });
        }
        setDefaultState();
      })
      .catch(() => {
        setDefaultState();
      });
  }, []);

  useEffect(() => {
    if (!providers) {
      history.push(`/patient/signin`);
    } else if (providers.length === 1) {
      const providerInfo = providers[0];

      if (providerInfo) {
        setProvider(providerInfo);
        const appt = selectAppointment(patient, providerInfo);
        if (!appt) {
          history.push(`/patient/failed`, {
            message: [
              "We couldn't find your appointment.",
              "If you have an appointment at this time, Please contact our support desk.",
            ].join(" "),
            allowBack: false,
          });
        } else {
          setAppointment(appt);
          setCardAuthorizeRequest(
            buildCardRequestData(patient, appt, providerInfo)
          );
        }
      }
    }
  }, [providers]);

  const successfulPaymentHandler = (requestData: CardAuthorizeRequest) => {
    history.push(`/patient/checkin`, { cardAuthorizeRequest: requestData });
  };

  const clearPaymentForm = (clearAll?: boolean) => {
    setCardCode("");
    setCardNumber("");
    setExpiryDate("");

    if (clearAll) {
      setFirstName("");
      setLastName("");
      setAddress("");
      setCity("");
      setState("");
      setCountry("");
      setPostalCode("");
    }
  };

  const handlePaymentModalCancel = () => {
    clearPaymentForm(true);
    setPaymentModalVisible(false);
    setInputValidationAlertVisible(false);
  };

  const showPaymentModal = () => {
    showLoader(false);
    setAlertVisible(false);
    setPaymentModalVisible(true);
  };

  const showCardTokenizerFailureMessage = (cardDetail: EncryptedCardDetail) => {
    clearPaymentForm(true);
    showLoader(false);
    setAlertVisible(true);
    setPaymentModalVisible(false);
    let messageCount = 0;
    while (messageCount < cardDetail.messages.message.length) {
      logger.warn(
        `${cardDetail.messages.message[messageCount].code}: ${cardDetail.messages.message[messageCount].text}`
      );
      messageCount += 1;
    }
  };

  const handleFailedPayment = () => {
    clearPaymentForm(true);
    showLoader(false);
    setAlertVisible(true);
    setPaymentModalVisible(false);
  };

  const processPaymentResponse = (response: any) => {
    if (response !== undefined) {
      const paymentResponse = response.data as Payment;
      if (paymentResponse && paymentResponse.status !== PaymentStatus.Failed) {
        showLoader(false);
        successfulPaymentHandler({
          ...cardAuthorizeRequest,
          id: paymentResponse.id,
        });
      } else {
        handleFailedPayment();
      }
    } else{
      handleFailedPayment();
    }
  };



  const chargeProfile = async () => {
    setPaymentModalVisible(false);
    showLoader(true);
    const requestData = {
      firstName: cardAuthorizeRequest.firstName,
      lastName: cardAuthorizeRequest.lastName,
      preferredName: cardAuthorizeRequest.preferredName,
      appointmentId: cardAuthorizeRequest.appointmentId,
      appointmentDateTs: cardAuthorizeRequest.appointmentDateTs,
      providerId: cardAuthorizeRequest.providerId,
      patientId: cardAuthorizeRequest.patientId,
      providerName: cardAuthorizeRequest.providerName,
      requestedService:
        cardAuthorizeRequest.requestedService || RequestedServiceType.Therapy,
      amount: cardAuthorizeRequest.amount,
      currency: cardAuthorizeRequest.currency,
      location: cardAuthorizeRequest.location,
      sessionStart: cardAuthorizeRequest.sessionStart,
      paymentProvider: cardAuthorizeRequest.paymentProvider,
      paymentCaptureType: cardAuthorizeRequest.paymentCaptureType,
      patientEmail: cardAuthorizeRequest.patientEmail,
      providerEmail: cardAuthorizeRequest.providerEmail,
      appointmentType: cardAuthorizeRequest.appointmentType,
      patientDob: cardAuthorizeRequest.patientDob,
    };
    const paymentFunction = getAuthorizeAndChargeProfileFunction();
    paymentFunction(requestData)
      .then((response: any) => {
        processPaymentResponse(response);
      })
      .catch((err: unknown) => {
        // log error and display notification to customer if payment processing failed
        if (err instanceof Error) {
          logger.error(err.message);
        } 
        handleFailedPayment();
      });
  };

  const processPayment = async (cardDetail: EncryptedCardDetail) => {
    if (cardDetail != null) {
      if (cardDetail.messages.resultCode === "Ok") {
        setPaymentModalVisible(false);
        showLoader(true);
        const requestData = {
          dataDescriptor: cardDetail.opaqueData.dataDescriptor,
          dataValue: cardDetail.opaqueData.dataValue,
          billingInfo: {
            firstName,
            lastName,
            address,
            city,
            state,
            country,
            postalCode,
            email: cardAuthorizeRequest.patientEmail,
          },
          firstName: cardAuthorizeRequest.firstName,
          lastName: cardAuthorizeRequest.lastName,
          preferredName: cardAuthorizeRequest.preferredName,
          appointmentId: cardAuthorizeRequest.appointmentId,
          appointmentDateTs: cardAuthorizeRequest.appointmentDateTs,
          patientId: cardAuthorizeRequest.patientId,
          providerId: cardAuthorizeRequest.providerId,
          providerName: cardAuthorizeRequest.providerName,
          requestedService:
            cardAuthorizeRequest.requestedService ||
            RequestedServiceType.Therapy,
          amount: cardAuthorizeRequest.amount,
          currency: cardAuthorizeRequest.currency,
          location: cardAuthorizeRequest.location,
          sessionStart: cardAuthorizeRequest.sessionStart,
          paymentProvider: cardAuthorizeRequest.paymentProvider,
          paymentCaptureType: cardAuthorizeRequest.paymentCaptureType,
          patientEmail: cardAuthorizeRequest.patientEmail,
          providerEmail: cardAuthorizeRequest.providerEmail,
          appointmentType: cardAuthorizeRequest.appointmentType,
          patientDob: cardAuthorizeRequest.patientDob,
          lastFourDigits,
          canSaveDetails: canSave,
          cardToken,
        };
        const paymentFunction = getCallablePaymentFunction(
          cardAuthorizeRequest.paymentCaptureType
        );
        paymentFunction(requestData)
          .then((response: any) => {
            processPaymentResponse(response);
          })
          .catch((err: unknown) => {
            // log error and display notification to customer if payment processing failed
            if (err instanceof Error) {
              logger.error(err.message);
            } 
            handleFailedPayment();
          });
      } else {
        showCardTokenizerFailureMessage(cardDetail);
      }
    }
  };

  useEffect(() => {
    // process payment when token and card details are set
    if (rawData && cardToken) {
      Accept.dispatchData(rawData, processPayment);
    }
  }, [rawData, cardToken]);

  const getCardTokenForProfile = async (cardDetail: EncryptedCardDetail) => {
    if (cardDetail != null) {
      if (cardDetail.messages.resultCode === "Ok") {
        setCardToken({
          dataDescriptor: cardDetail.opaqueData.dataDescriptor,
          dataValue: cardDetail.opaqueData.dataValue,
        });
      }
    } else {
      showCardTokenizerFailureMessage(cardDetail);
    }
  };

  const showValidationError = (message: string) => {
    setValidationMessage(message);
    setInputValidationAlertVisible(true);
    showLoader(false);
  };

  const collectPaymentDetails = () => {
    showLoader(true);
    setInputValidationAlertVisible(false);
    if (!cardNumber) {
      const message = "Please provide a valid card number";
      showValidationError(message);
      return;
    }

    if (!expiryDate || !isDateInFuture(expiryDate)) {
      const message = "Card expiry date is not valid";
      showValidationError(message);
      return;
    }

    if (!cardCode) {
      const message = "Please provide a valid card cvv";
      showValidationError(message);
      return;
    }

    if (!firstName) {
      const message = "First name is required";
      showValidationError(message);
      return;
    }

    if (!lastName) {
      const message = "Last name is required";
      showValidationError(message);
      return;
    }

    if (!address) {
      const message = "Address is required";
      showValidationError(message);
      return;
    }

    if (!city) {
      const message = "City is required";
      showValidationError(message);
      return;
    }

    if (!state) {
      const message = "State/Province is required";
      showValidationError(message);
      return;
    }

    if (!country) {
      const message = "Country is required";
      showValidationError(message);
      return;
    }

    setPaymentModalVisible(false);

    const authData = {} as any;
    authData.clientKey = process.env.REACT_APP_AUTHORIZE_NET_PUBLIC_KEY;
    authData.apiLoginID = process.env.REACT_APP_AUTHORIZE_NET_LOGIN_ID;

    const cardData = {} as any;
    cardData.cardNumber = cardNumber;

    const [month, year] = getDateParts(expiryDate);
    cardData.month = month;
    cardData.year = year;
    cardData.cardCode = cardCode;
    cardData.zip = postalCode;
    cardData.fullName = `${firstName} ${lastName}`;

    const secureData = {} as any;
    secureData.authData = authData;
    secureData.cardData = cardData;

    setRawData(secureData);

    clearPaymentForm();

    if (canSave) {
      // get token needed to save customer profile
      Accept.dispatchData(secureData, getCardTokenForProfile);
    } else {
      Accept.dispatchData(secureData, processPayment);
    }
  };

  const validateInputValue = (e: any) => {
    const event = e || window.event;
    const charCode =
      typeof event.which === "undefined" ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);
    if (/\d/.test(charStr)) {
      return true;
    }
    e.preventDefault();
    return false;
  };

  function handleOnChange(providerId: string) {
    const providerInfo = providers.find((p) => p.id === providerId);
    setProvider(providerInfo);

    if (providerInfo) {
      const appt = selectAppointment(patient, providerInfo);
      if (!appt) {
        return;
      }
      setAppointment(appt);
      setCardAuthorizeRequest(
        buildCardRequestData(patient, appt, providerInfo)
      );
    }
  }

  return (
    <>
      <OnBoardingScaffold
        topItem={
          <OnBoardingTopText
            prefixText={provider && appointment ? "Confirm" : "Select"}
            logo={medconLogo}
            segment={provider && appointment ? "Payment" : "Therapist"}
            botMessage={
              provider && appointment
                ? "Kindly review and proceed. \n If your copay is incorrect please email us at billing@humantold.com, and proceed with your therapist on a different platform."
                : "We want to connect you to the right"
            }
            newLine={
              provider && appointment
                ? ""
                : "session, please select your therapist"
            }
          />
        }
        sideImage={sideImage4}
      >
        {alertVisible ? (
          <>
            <StyledAlert>
              <Alert
                message={`${formatWithCurrency(
                  appointment.copayAmount,
                  appointment.currency
                )} payment for ${
                  appointment.requestedService
                } was unsuccessful.`}
                type="error"
                showIcon
                closable
              />
            </StyledAlert>

            <StyledHighlightedMessage>
              We were unable to charge your card. This could have happened for a
              number of reasons:
              <div>
                <ul>
                  <li>
                    Your card details are not valid or your card has expired
                  </li>
                  <li>Lack of funds or funds on hold</li>
                  <li>
                    Your financial institution declined the transaction for a
                    reason unknown to us
                  </li>
                </ul>
              </div>
            </StyledHighlightedMessage>
          </>
        ) : (
          ""
        )}

        {providers ? (
          <>
            <StyledDropDown>
              <Form
                layout="vertical"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 25 }}
              >
                <Form.Item
                  label="SELECT THERAPIST"
                  style={{
                    width: "100%",
                    fontFamily: "Poppins",
                    fontSize: "11px",
                    letterSpacing: "0.3px",
                    fontWeight: "normal",
                    color: "#101625",
                  }}
                >
                  <Select
                    value={
                      providers.length === 1
                        ? providers[0].fullName
                        : provider?.fullName
                    }
                    onChange={(e: any) => handleOnChange(e)}
                    size="large"
                    placeholder="Select Therapist"
                  >
                    {Object.values(providers).map((data: Provider) => (
                      <Select.Option key={data.id} value={data.id}>
                        {`${data.fullName}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </StyledDropDown>
            <div style={{ clear: "both", marginBottom: "20px" }} />
          </>
        ) : (
          ""
        )}

        {provider && appointment ? (
          <>
            <BillPane>
              <StyledBillTitle >
                <img src={buttonIcon} alt="" width="20px" />
                <p>Your Therapist:</p>
              </StyledBillTitle>
              <StyledBillText  data-ybug-sensitive
                    className="fs-mask">{provider.fullName}</StyledBillText>
            </BillPane>
            <BillPane>
              <StyledBillTitle>
                <StyledFiActivity />
                <p> Your Session:</p>
              </StyledBillTitle>
              <StyledBillText>
                {appointment.requestedService || RequestedServiceType.Therapy}{" "}
                <span>
                  {" "}
                  -{" "}
                  {appointment.appointmentType === AppointmentType.TeleConsult
                    ? "Teleconsult"
                    : "Walk In"}
                </span>
              </StyledBillText>
            </BillPane>
            <BillPane>
              <StyledBillTitle>
                <img src={docIcon} alt="" />
                <p>Your Session Fee:</p>
              </StyledBillTitle>
              <StyledPrice>
                {formatWithCurrency(
                  appointment.copayAmount,
                  appointment.currency
                )}
              </StyledPrice>
            </BillPane>
            <BillPane>
              <StyledBillTitle>
                <StyledFiLayout />
                <p>Appointment Date:</p>
              </StyledBillTitle>
              <StyledBillText data-ybug-sensitive
                    className="fs-mask">
                {getFormattedDate(
                  appointment.appointmentDate,
                  "ddd. Do MMM, YYYY"
                )}{" "}
                <span>{getFormattedTime(appointment.time)}</span>
              </StyledBillText>
            </BillPane>
            {paymentProfile ? (
              <>
                <h3>Saved Payment Method</h3>
                <BillPane>
                  <StyledBillTitle>
                    <StyledMessage>
                      <FaCreditCard size={20} /> Card ending in{" "}
                      {`${paymentProfile.lastFourDigits}`}
                      <StyledUpdateButton onClick={showPaymentModal}>
                        Update Payment Info
                      </StyledUpdateButton>
                    </StyledMessage>
                  </StyledBillTitle>
                </BillPane>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {provider && appointment ? (
          <PrimaryButtonComponent
            onClick={() => {
              showLoader(true);
              const canCreate = appointment.copayAmount <= 0;
              const paymentStore = new PaymentStore();
              paymentStore
                .fetchPaymentByAppointmentId(appointment, provider, canCreate)
                .then((payment: Payment) => {
                  if (!payment || !payment.id) {
                    if (paymentProfile !== undefined) {
                      chargeProfile();
                    } else {
                      showLoader(false);
                      setPaymentModalVisible(true);
                    }
                  } else {
                    const sessionStore = new SessionStore(payment, payment.id);
                    sessionStore.getSession().then((session: any) => {
                      if (
                        session &&
                        session.sessionStatus === SessionStatus.ENDED
                      ) {
                        history.push(`/patient/failed`, {
                          message: [
                            "The session for this appointment has ended.",
                            "If you have an appointment at this time, Please contact our support desk.",
                          ].join(" "),
                          allowBack: false,
                        });
                      } else {
                        const requestData = {
                          id: payment.id,
                          providerName: provider.fullName,
                        } as CardAuthorizeRequest;
                        successfulPaymentHandler(requestData);
                      }
                    });
                  }
                }).catch(()=>{
                  showLoader(false);
                });
            }}
            buttonText="Proceed"
          />
        ) : (
          ""
        )}

        <BackButton />
        <Modal
          visible={isPaymentModalVisible}
          onCancel={handlePaymentModalCancel}
          footer={false}
          centered={false}
          style={{ top: "10px" }}
        >
          <StyledPaymentHeader>
            <img src={paymentHeaderImage} alt="" />

            <div>
              <h1>Payment</h1>
            </div>
          </StyledPaymentHeader>
          <Divider />
          <StyledPaymentForm>
            {inputValidationAlertVisible ? (
              <StyledAlert>
                <Alert
                  message={validationMessage}
                  type="error"
                  showIcon
                  closable={false}
                />
              </StyledAlert>
            ) : (
              ""
            )}

            <StyledCardCollection>
              <InputComponent
                input={
                  <InputDiv width="100%">
                    <FaCreditCard size={20} />
                    <StyledPaymentInput
                      bordered={false}
                      type="text"
                      placeholder="3726 3772 4662 4772"
                      data-ybug-sensitive
                      className="fs-mask"
                      value={cardNumber}
                      maxLength={25}
                      onKeyPress={(e: any) => {
                        if (e && e.length === 25) {
                          e.preventDefault();
                          return false;
                        }
                        const isValid = validateInputValue(e);
                        return isValid;
                      }}
                      onChange={(e: any) => {
                        const text = e.target.value?.trim();
                        setCardNumber(text);
                        setLastFourDigits(text.slice(-4));
                      }}
                      autoFocus
                    />
                  </InputDiv>
                }
                title="Card number *"
                small
              />
            </StyledCardCollection>
            <StyledCardInputCollection>
              <InputComponent
                input={
                  <DateInput
                    value={expiryDate}
                    data-ybug-sensitive
                    class="fs-mask"
                    onChange={(e: any) => {
                      setExpiryDate(e);
                    }}
                  />
                }
                title="Expiry Date *"
                small
              />
              <InputComponent
                input={
                  <InputDiv>
                    <FiLock size={30} />
                    <StyledPaymentInput
                      bordered={false}
                      placeholder="1234"
                      data-ybug-sensitive
                      className="fs-mask"
                      maxLength={10}
                      type="password"
                      value={cardCode}
                      onKeyPress={(e: any) => {
                        if (e && e.length === 10) {
                          e.preventDefault();
                          return false;
                        }
                        const isValid = validateInputValue(e);
                        return isValid;
                      }}
                      onChange={(e: any) => {
                        setCardCode(e.target.value?.trim());
                      }}
                    />
                  </InputDiv>
                }
                title="CVV *"
                small
              />
            </StyledCardInputCollection>

            <Divider style={{ marginTop: "0px" }} />
            <StyledBillingAddress>
              <h1>Cardholder&#39;s Name & Address</h1>
              <StyledInputCollection>
                <InputComponent
                  input={
                    <Input
                      value={firstName}
                      data-ybug-sensitive
                      className="fs-mask"
                      maxLength={32}
                      onChange={(e: any) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  }
                  title="First name *"
                  small
                />
                <InputComponent
                  input={
                    <Input
                      value={lastName}
                      data-ybug-sensitive
                      className="fs-mask"
                      maxLength={32}
                      onChange={(e: any) => {
                        setLastName(e.target.value);
                      }}
                    />
                  }
                  title="Last name *"
                  small
                />
              </StyledInputCollection>
              <InputComponent
                input={
                  <Input
                    value={address}
                    data-ybug-sensitive
                    className="fs-mask"
                    onChange={(e: any) => {
                      setAddress(e.target.value);
                    }}
                  />
                }
                title="Address *"
                small
              />
              <StyledInputCollection>
                <InputComponent
                  input={
                    <Input
                      value={city}
                      data-ybug-sensitive
                      className="fs-mask"
                      onChange={(e: any) => {
                        setCity(e.target.value);
                      }}
                    />
                  }
                  title="City *"
                  small
                />
                <InputComponent
                  input={
                    <Input
                      value={state}
                      data-ybug-sensitive
                      className="fs-mask"
                      onChange={(e: any) => {
                        setState(e.target.value);
                      }}
                    />
                  }
                  title="State/Province *"
                  small
                />
              </StyledInputCollection>
              <StyledInputCollection>
                <InputComponent
                  input={
                    <Select
                      value={country}
                      data-ybug-sensitive
                      className="fs-mask"
                      onChange={(e: any) => setCountry(e)}
                      style={{ width: "100%" }}
                    >
                      <Select.Option value="">Select Country</Select.Option>
                      {countryList.map((entry: any) => (
                        <Select.Option key={entry.code} value={entry.name}>
                          {entry.name}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                  title="Country *"
                  small
                />
                <InputComponent
                  input={
                    <Input
                      value={postalCode}
                      data-ybug-sensitive
                      className="fs-mask"
                      onChange={(e: any) => {
                        setPostalCode(e.target.value);
                      }}
                    />
                  }
                  title="Zip/Postal Code"
                  small
                />
              </StyledInputCollection>
              <p>
                {" "}
                <input
                  type="checkbox"
                  data-ybug-sensitive
                  className="fs-mask"
                  defaultChecked={canSave}
                  onChange={(e) => {
                    setCanSave(e.target.checked);
                  }}
                />{" "}
                Save card details for next time
              </p>
            </StyledBillingAddress>
            <br />
            {provider && appointment ? (
              <PrimaryButtonComponent
                onClick={collectPaymentDetails}
                width="100%"
                buttonText={`Pay ${formatWithCurrency(
                  appointment.copayAmount,
                  appointment.currency
                )}`}
              />
            ) : (
              ""
            )}
          </StyledPaymentForm>
        </Modal>
      </OnBoardingScaffold>
      <Loader visible={loader} text={loadingMessage} />
    </>
  );
};
