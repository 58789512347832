import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { Alert } from "antd";
import { FcCalendar, FcDeleteRow } from "react-icons/fc";
import PatientStore from "../../store/patient";
import { Patient } from "../../types/patient";
import { Role } from "../../types/enum";
import { useScreenNameContext } from "../context/screenNameContext";
import { PermissionContext } from "../context/permissionContext";
import { TableMini } from "./atoms/TableMini";
import { Loader } from "../elements/Loader";
import { generateMedconHash } from "../../utils/hash";

declare const window: any;

const title = ["Name", "email", "contact number", "date of birth", ""];
const csvHeaders = [
  { label: "Name", key: "text" },
  { label: "Email", key: "field1" },
  { label: "Contact Number", key: "field2" },
  { label: "Date of Birth", key: "field3" },
];

export const Clients = () => {
  const history = useHistory();
  const [loader, showLoader] = useState(true);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [clientsData, setClientsData] = useState([] as any);
  const { permission } = useContext(PermissionContext);
  const { setCurrentPageName } = useScreenNameContext() as any;

  window.navigateToSchedule = (patient: any) => {
    const clientName = `${patient.firstName} ${patient.lastName}`;
    const link = `/provider/schedule/?clientId=${patient.id}&clientName=${clientName}`;
    history.push(link);
  };

  const loadClients = () => {
    if (permission && permission.email) {
      const patientStore = new PatientStore();
      generateMedconHash(permission.email)
        .then((hashedProviderId: string) => {
          patientStore.getAllPatients().then((patients: Patient[]) => {
            const formattedPatients = [] as any[];
            Object.values(patients).forEach((patient: Patient) => {
              // check if logged in user can see client information
              let canSeeClientInfo = false;
              const userRole = permission.role.toString();
              if (userRole === Role.PROVIDER.toString()) {
                Object.values(patient.providerIds!).forEach(
                  (providerId: string) => {
                    if (providerId === hashedProviderId) {
                      canSeeClientInfo = true;
                    }
                  }
                );
              } else if (
                userRole === Role.SUPER_ADMIN.toString() ||
                userRole === Role.ADMIN.toString()
              ) {
                canSeeClientInfo = true;
              }

              if (canSeeClientInfo) {
                const dob = moment.utc(patient.dob);
                formattedPatients.push({
                  text: `${patient.firstName?.trim()} ${patient.lastName.trim()}`,
                  field1: patient.email,
                  field2: patient.contactNumber,
                  field3: dob.format("MMMM D yyyy"),
                  date: dob,
                  actions: [
                    {
                      text: "View Appointments",
                      handler: window.navigateToSchedule,
                      param: patient,
                      type: "button",
                      icon: <FcCalendar />,
                    },
                    {
                      text: "Delete",
                      handler: window.deletePatient,
                      param: patient,
                      type: "button",
                      icon: <FcDeleteRow />,
                    },
                  ],
                });
              }
            });

            setClientsData(formattedPatients);
            showLoader(false);
          });
        })
        .catch(() => {});
    }
  }

  

  window.deletePatient = (patient: any) => {
    confirmAlert({
      title: "Confirm action",
      message: `Are you sure you want to delete client - ${patient.firstName?.trim()} ${patient.lastName.trim()}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            showLoader(true);
            const patientStore = new PatientStore();
            patientStore.deletePatient(patient.id).then(() => {
              loadClients();
              setAlertMessage(`Client - ${patient.firstName?.trim()} ${patient.lastName.trim()} has been deleted`);
              setAlertVisible(true);
              showLoader(false);
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    setCurrentPageName("Clients");
    loadClients();
    // cancel subscription to useEffect
    return () => {
      setClientsData([]);
    };
  }, [permission]);

  return (
    <>
    {alertVisible ? (
          <>
            <Alert
              message={alertMessage}
              type="info"
              showIcon
              closable
              className="clientAlert"
              banner
              onClose={() => {
                setAlertVisible(false);
              }}
            />
            <br /> 
          </>
        ) : (
          ""
        )}
      <br /> 
      <TableMini
        sessionName="Clients information from intake system"
        tableTitle={title}
        tableName="All Clients"
        showLocationFilter={false}
        showDateFilter={false}
        data={clientsData}
        csvHeaders={csvHeaders}
        isActive
        showSearch
        showExportButton
      />
      <Loader visible={loader} text="Loading ... " />
    </>
  );
};
