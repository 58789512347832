import { Appointment } from "./appointment";

export interface Provider {
  id: string; // provider id
  email: string;
  fullName: string;
  contactNumber: string;
  appointments: Appointment;
}

export enum ProviderPath {
  ANALYTICS = "/provider/analytics",
  APP_INTEGRATION = "/provider/apps",
  APPOINTMENTS_BY_PROVIDER = "/provider/reports/appointments-by-provider",
  GROUP_MEETING = "/provider/meeting",
  IN_PERSON_SESSION_DETAILS_REPORTS = "/provider/reports/in-person-session-details",
  LOGIN = "/",
  MEETINGS = "/provider/meetings",
  MEETINGSFORM = "/provider/meetings/create-meeting",
  NOTEDETAILS = "/provider/note/notedetails",
  NOTES = "/provider/notes",
  OVERVIEW = "/provider/overview",
  PATIENT = "/provider/clients",
  PAYMENTS_COLLECTED = "/provider/reports/payments-collected",
  PERMISSIONS = "/provider/permissions",
  PROVIDER = "/provider/providers",
  REPORTS = "/provider/reports",
  REVENUE_BY_PROVIDER = "/provider/reports/revenue-by-provider",
  ROOT = "/provider",
  SCHEDULE = "/provider/schedule",
  SESSION = "/provider/session",
  SESSION_DETAILS_REPORTS = "/provider/reports/session-details",
  SETTINGS = "/provider/settings",
  TAKENOTE = "/provider/note/takenote",
  UPDATE_PERMISSION = "/provider/permissions/update",
}