// From https://connectcenter.changehealthcare.com/portal/assets/pages/Eligibility_Payer_Guide.pdf
const insuranceOptions: Array<{
    name: string;
    payerId: string;
    secondaryIdentifier: string;
}> = [{
    "name": "1199 National Benefit Fund",
    "payerId": "SNATB",
    "secondaryIdentifier": "1199NB"
}, {
    "name": "AETNA",
    "payerId": "AETNA",
    "secondaryIdentifier": "AETNA"
}, {
    "name": "Allied Benefit Systems",
    "payerId": "ALBENS",
    "secondaryIdentifier": "ALLIED"
}, {
    "name": "CIGNA",
    "payerId": "CIGNA",
    "secondaryIdentifier": "CIGNA"
}, {
    "name": "Emblem Health",
    "payerId": "EMBLEM",
    "secondaryIdentifier": "EMBLEM HEALTH"
}, {
    "name": "Government Employees Hospital Association",
    "payerId": "GEHA",
    "secondaryIdentifier": "GEHA"
}, {
    "name": "Emblem Health (GHI HMO)",
    "payerId": "EMBLM1",
    "secondaryIdentifier": "GHI"
}, {
    "name": "Harvard Pilgrim HealthCare",
    "payerId": "HRVPGM",
    "secondaryIdentifier": "HARVARD PILGRIM"
}, {
    "name": "Meritain Health",
    "payerId": "MERITN",
    "secondaryIdentifier": "MERITAIN"
}, {
    "name": "OptumCare",
    "payerId": "UNTDRN",
    "secondaryIdentifier": "OPTUM"
}, {
    "name": "Oxford Health Plans",
    "payerId": "OXFORD",
    "secondaryIdentifier": "OXFORD"
}, {
    "name": "United Medical Resources",
    "payerId": "UMRWAU",
    "secondaryIdentifier": "UMR"
}, {
    "name": "UnitedHealthcare StudentResources",
    "payerId": "STDNT",
    "secondaryIdentifier": "UNITED HEALTHCARE STUDENT RESOURCES"
}, {
    "name": "UnitedHealthCare",
    "payerId": "UHC",
    "secondaryIdentifier": "UNITED HEALTHCARE"
}, {
    "name": "Trustmark Health Benefits (CoreSource)",
    "payerId": "CSFMH",
    "secondaryIdentifier": "CORESOURCE"
}, {
    "name": "Beacon Health Strategies",
    "payerId": "BCNHL1",
    "secondaryIdentifier": "BEACON"
}];

export default insuranceOptions;