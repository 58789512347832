import React from "react";
import { StyledBlackTd, StyledSpecialFontTd } from "../styles/tableRowStyle";

export const AppointmentsByProviderReportsBody = (props: {
  data: {
    client: string;
    service: string;
    frequency: string;
    location: string;
    formattedDate: string;
    copay: string;
    appointmentType: string;
    timeStamp: string;
  };
}) => (
  <tr>
    <StyledBlackTd>{props.data.client}</StyledBlackTd>
    <StyledBlackTd>{props.data.service}</StyledBlackTd>
    <StyledBlackTd>{props.data.frequency}</StyledBlackTd>    
    <StyledBlackTd>{props.data.location}</StyledBlackTd>
    <StyledBlackTd>{props.data.appointmentType}</StyledBlackTd>
    <StyledBlackTd>{props.data.copay}</StyledBlackTd>
    <StyledSpecialFontTd className="specialFont">
      {props.data.formattedDate}
      {" | "} {props.data.timeStamp}
    </StyledSpecialFontTd>
  </tr>
);
