import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { FiPrinter, FiFileText } from "react-icons/fi";
import { CSVLink } from "react-csv";
import { useScreenNameContext } from "../../context/screenNameContext";
import {
  Option,
  StyledReportContainer,
  DateInput,
  StyledSelect,
  ReportBody,
  StyledToolBar,
  StyledSpan,
  StyledPrinterSpan,
} from "./ReportAssets";
import { PrimaryButtonComponent } from "../../elements/PrimaryButtonComponent";

export const ReportContainer = (props: {
  reportTitle: string;
  dateFormat: string;
  showStartDate: boolean;
  showEndDate: boolean;
  showGroupBy: boolean;
  showPrint: boolean;
  showExport: boolean;
  showApply: boolean;
  groupByList?: any;
  data: any;
  reportBody: any;
  reportFileName?: string;
  filterHandler?: any;
  dataHandler?: any;
  reportStartDate: any;
  reportEndDate: any;
}) => {
  const reportBodyRef = useRef({} as ReportBody);
  const [startDate, setStartDate] = useState(props.reportStartDate);
  const [endDate, setEndDate] = useState(props.reportEndDate);
  const startOfYear = moment().startOf("year").format(props.dateFormat);
  const endOfYear = moment().endOf("year").format(props.dateFormat);

  const { setCurrentPageName } = useScreenNameContext() as any;

  const printReport = useReactToPrint({
    content: () => reportBodyRef.current!,
  });

  useEffect(() => {
    setCurrentPageName(props.reportTitle);
  }, [props.reportTitle]);

  return (
    <>
      <StyledReportContainer>
        <StyledToolBar>
          <h2>Filters</h2>
          {props.showStartDate ? (
            <>
              <StyledSpan>Start Date</StyledSpan>
              <DateInput
                value={startDate}
                placeholder={startOfYear}
                format={props.dateFormat}
                onChange={(e: any) => {
                  setStartDate(e);
                }}
              />
            </>
          ) : (
            <></>
          )}

          {props.showEndDate ? (
            <>
              <StyledSpan>End Date</StyledSpan>
              <DateInput
                value={endDate}
                placeholder={endOfYear}
                format={props.dateFormat}
                onChange={(e: any) => {
                  setEndDate(e);
                }}
              />
            </>
          ) : (
            <></>
          )}

          {props.showGroupBy ? (
            <>
              <StyledSpan>Group By </StyledSpan>
              <StyledSelect defaultValue="All" bordered={false}>
                {Object.values(props.groupByList).map((key: any) => (
                  <Option value={key}>{key}</Option>
                ))}
              </StyledSelect>
            </>
          ) : (
            <></>
          )}

          {props.showApply ? (
            <>
              <PrimaryButtonComponent
                width="60px"
                height="45px"
                fontSize="12px"
                leftMargin="20px"
                buttonText="Apply"
                onClick={() => {
                  if (startDate && endDate) {
                    props.filterHandler(startDate, endDate, props.dataHandler);
                  }
                }}
              />
            </>
          ) : (
            <></>
          )}

          {props.showPrint ? (
            <>
              <StyledPrinterSpan onClick={printReport}>
                <FiPrinter /> Print
              </StyledPrinterSpan>
            </>
          ) : (
            <></>
          )}

          {props.showExport ? (
            <>
              <StyledSpan>
                <CSVLink data={props.data} filename={props.reportFileName}>
                  {" "}
                  <FiFileText /> Download
                </CSVLink>
              </StyledSpan>
            </>
          ) : (
            <></>
          )}
        </StyledToolBar>
        <ReportBody
          ref={reportBodyRef}
          reportTitle={props.reportTitle}
          reportStartDate={startDate}
          reportEndDate={endDate}
          component={props.reportBody}
          data={props.data}
        />
      </StyledReportContainer>
    </>
  );
};
