import React from "react";
import styled from "styled-components";
import { device } from "../../assets/breakbpoints";
import { fontFamily } from "../../assets/fontFamily";

const StyledInputComponent = styled.div<InputComponentProps>`
  font-family: ${fontFamily.manrope};
  font-weight: 600;
  width: 100%;
  margin-bottom: 30px;

  & p {
    font-size: 11px;
    font-style: normal;
    margin-bottom: ${(props) => (props.small ? "5px" : "15px")};
  }

  @media ${device.laptop} {
    font-size: 0.9vw;
  }
`;

interface InputComponentProps extends React.ComponentPropsWithoutRef<"div"> {
  small?: boolean;
  title?: string;
  input?: any;
  validation?: any;
}

export const InputComponent = (props: InputComponentProps) => (
  <StyledInputComponent small={props.small}>
    <p>{props.title}</p>
    {props.input}
    {props.validation}
  </StyledInputComponent>
);
