/* eslint-disable jsx-a11y/no-static-element-interactions */
import { DatePicker, Input, Pagination } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { FiChevronDown, FiSearch } from "react-icons/fi";
import { FcExport } from "react-icons/fc";
import styled from "styled-components";
import { TableRow } from "./TableRow";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";
import { theme } from "../../../assets/theme";
import { getUniqueId } from "../../../utils/hash";

const StyledContainer = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: inset 0px -1px 0px #edf2f7;
  border: 1px solid #edf2f7;
`;
const StyledTable = styled.table`
  width: 100%;
  background-color: #fff;
`;

const StyledTableHead = styled.thead`
  background-color: #fafafb;
  text-transform: uppercase;

  & tr {
    & th {
      padding-top: 24px;
      padding-bottom: 24px;
      font-family: ${fontFamily.heading};
      color: #8492a6;
      font-weight: 500;
      font-size: 10px;

      &:first-child {
        padding-left: 24px;
      }
    }
  }

  @media ${device.laptop} {
    & tr {
      & th {
        font-size: 0.7vw;
      }
    }
  }
`;
const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 20px 24px;

  & > div:first-child {
    display: flex;
    align-items: center;

    & > p {
      font-weight: 500;
      font-family: ${fontFamily.heading};
      font-size: 14px;
      color: #1f2d3d;
      margin: 0px;
    }
  }

  @media ${device.laptop} {
    & > div:first-child {
      & > p {
        font-size: 0.97vw;
      }
    }
  }
`;

const StyledTableBody = styled.tbody`
  & tr {
  }

  & td {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 14px;
    font-family: ${fontFamily.heading};
    border-bottom: 1px solid #edf2f7;

    &:first-child {
      padding-left: 24px;
    }
  }

  & .specialFont {
    font-family: ${fontFamily.body};
    font-weight: 400;
    color: #425466;
  }

  @media ${device.laptop} {
    & td {
      font-size: 0.97vw;
    }
  }
`;

const StyledTableFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  padding-top: 50px;

  & p {
    font-family: ${fontFamily.Inter};
    font-weight: 400;
    font-size: 14px;
    color: #7a7a9d;
    margin: 0px;
  }

  @media ${device.laptop} {
    & p {
      font-size: 0.97vw;
    }
  }
`;

const StyledPagination = styled(Pagination)`
  & .anticon svg {
    border: 1px solid ${theme.mutedColor};
    font-size: 25px;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
    margin-left: 10px;
  }

  & .ant-pagination-item a {
    color: ${theme.mutedColor};
    font-family: ${fontFamily.body};
  }

  & .ant-pagination-item-active a {
    color: ${theme.secondaryColor};
    font-weight: bolder;
  }

  &.ant-pagination-item-active {
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border-color: transparent;
    }
  }

  @media ${device.laptop} {
    & .anticon svg {
      font-size: 1.9vw;
    }
  }
`;

const StyledSearchInput = styled.div`
  border: 1px solid #e7e8e9;
  border-radius: 5px;
  min-width: 18vw;
`;
const StyledFiSearch = styled(FiSearch)`
  color: #92929d;
  font-size: 16px;

  @media ${device.laptop} {
    font-size: 1.1vw;
  }
`;

const StyledInput = styled(Input)`
  font-family: ${fontFamily.body};
  font-weight: 400;
  font-size: 10.76px;

  @media ${device.laptop} {
    font-size: 1.06vw;
  }
`;

const StyledTableMainHeader = styled.div<{ activeState: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  /*  & >div:first-child{
        display: flex;
        align-items: center;
        & h1{
            font-family: ${fontFamily.heading};
            font-weight: ${(props) => (props.activeState ? 700 : "normal")};
            font-size: 18px;
            color: ${(props) =>
    props.activeState ? "#27272E" : theme.mutedColor};
            margin: 0px;
            cursor: pointer;


            &:last-child{
                margin-left: 30px;
            }
        }
    }

    @media ${device.laptop}{
        & >div:first-child{
            & h1{
                font-size: 1.3vw;
            }
        }
    } */
`;

const StyledFilterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .dateDivider {
    color: #7a7a9d;
    font-size: 18px;
    margin-left: 10px;
  }
`;

const StyledFilterLocation = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  border: 0.725px solid #e2e2ea;
  cursor: pointer;

  background-color: #fff;
  border-radius: 5px;

  font-family: ${fontFamily.body};
  font-size: 11px;
  padding-left: 5px;

  & p {
    margin: 0px;
    color: #7a7a9d;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  font-family: ${fontFamily.body};
  padding: 7px 5px;
  cursor: pointer;

  & .ant-picker-input > input:placeholder-shown {
    font-size: 11px;
  }

  & .ant-picker-input > input {
    color: #030229;
    font-size: 11px;
  }
`;

const StyledExport = styled(StyledFilterLocation)`
  justify-content: center;
  font-family: ${fontFamily.body};
  font-weight: 600;
  color: #16192c;
  padding: 7px 10px;
`;

const StyledSessionNameDiv = styled.div<{ activeState: boolean }>`
  display: flex;
  align-items: center;
  & h1 {
    font-family: ${fontFamily.heading};
    font-weight: ${(props) => (props.activeState ? 700 : "normal")};
    font-size: 18px;
    color: ${(props) => (props.activeState ? "#27272E" : theme.mutedColor)};
    margin: 0px;
    cursor: pointer;

    &:last-child {
      margin-left: 30px;
    }
  }

  @media ${device.laptop} {
    & h1 {
      font-size: 1.3vw;
    }
  }
`;
/* const StyledTiArrowSortedDown = styled(TiArrowSortedDown)`
    border-left: 1px solid #7A7A9D;
    padding-left: 5px;
    font-size: 16px;
` */
const DateInput = (props: {
  value: string;
  placeholder: string;
  format: string;
  onChange: any;
}) => (
  <StyledFilterLocation>
    <StyledDatePicker
      placeholder={props.placeholder}
      suffixIcon={<FiChevronDown />}
      bordered={false}
      format={props.format}
      onChange={props.onChange}
    />
  </StyledFilterLocation>
);

export const TableMini = (props: {
  tableTitle: string[];
  handleSessionClicked?: (sessionName?: string) => void;
  isActive?: boolean;
  isActiveSecondary?: boolean;
  SecondSessionName?: string;
  sessionName?: string;
  showLocationFilter?: boolean;
  showDateFilter: boolean;
  showSearch?: boolean;
  showCalenderFilter?: boolean;
  showExportButton?: boolean;
  tableName: string;
  data: any[];
  csvHeaders?: any[];
}) => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [start, setStart] = useState(1);
  const [currentData, setCurrentData] = useState([] as any[]);
  const [tableRows, setTableRows] = useState([] as any[]);

  const dateFormat = "MM-DD-YYYY";
  const startOfYear = moment().startOf("year").format(dateFormat);
  const endOfYear = moment().endOf("year").format(dateFormat);

  const tableTitle = props.tableTitle.map((item, index) => {
    const key = `thkey-${index}-${getUniqueId()}`;
    return <th key={key}>{item}</th>;
  });

  const showFilteredRecords = (
    filteredRecords: any[],
    persistData: boolean = true
  ) => {
    // sort by date descending
    const sortedRecords = filteredRecords.sort((a: any, b: any) =>
      a.text.localeCompare(b.text)
    );
    if (persistData) {
      setCurrentData(sortedRecords);
    }
    // show only the first 10 records and paginate the rest
    const slicedRecords = sortedRecords.slice(0, 10);
    const rows = slicedRecords.map((item, index) => {
      const key = `curr-data-${index}-${getUniqueId()}`;
      return <TableRow key={key} data={item} />;
    });
    setTableRows(rows);
  };

  const getDataFilteredByText = (text: string, data: any[]) => {
    const filteredRecords =
      !text || text === ""
        ? data
        : data.filter(
            (record) =>
              record.text.toLowerCase().indexOf(text.toLowerCase()) !== -1
          );
    return filteredRecords;
  };

  const getDataFilteredByDate = (
    filterStartDate: string,
    filterEndDate: string,
    data: any[] = props.data
  ) => {
    if (!filterStartDate && !filterEndDate) {
      return data;
    }

    const parsedStartDate = filterStartDate ? new Date(filterStartDate) : null;
    const parsedEndDate = filterEndDate ? new Date(filterEndDate) : null;
    let filteredRecords = [];
    if (parsedStartDate && parsedEndDate) {
      filteredRecords = data.filter(
        (record) =>
          new Date(record.date) >= parsedStartDate &&
          new Date(record.date) <= parsedEndDate
      );
    } else if (parsedStartDate) {
      filteredRecords = data.filter(
        (record) => new Date(record.date) >= parsedStartDate
      );
    } else if (parsedEndDate) {
      filteredRecords = data.filter(
        (record) => new Date(record.date) <= parsedEndDate
      );
    }
    return filteredRecords;
  };

  const filterData = (
    text: string,
    filterStartDate: string,
    filterEndDate: string,
    data: any[] = props.data
  ) => {
    let filteredRecords = getDataFilteredByText(text, data);
    filteredRecords = getDataFilteredByDate(
      filterStartDate,
      filterEndDate,
      filteredRecords
    );
    showFilteredRecords(filteredRecords);
  };

  const filterByText = (
    text: string,
    filterStartDate: string,
    filterEndDate: string,
    data: any[] = props.data
  ) => {
    filterData(text, filterStartDate, filterEndDate, data);
  };

  const filterByDate = (
    filterStartDate: string,
    filterEndDate: string,
    data: any[] = props.data
  ) => {
    filterData(searchText, filterStartDate, filterEndDate, data);
  };

  useEffect(() => {
    showFilteredRecords(props.data);
    setCurrentPageSize(
      props.data && props.data.length > 10 ? 10 : props.data.length
    );
    setStart(props.data.length > 0 ? 1 : 0);
  }, [props]);

  const handlePaginationChange = (page: number, pageSizeNum: number = 20) => {
    const end = page * pageSizeNum;
    const pageStart = (page - 1) * pageSizeNum + 1;
    const currentView = currentData.slice(pageStart - 1, end);
    showFilteredRecords(currentView, false);
    if (currentData.length < end) {
      setCurrentPageSize(currentData.length);
    } else {
      setCurrentPageSize(pageSizeNum * page);
    }
    setPageSize(pageSizeNum);
    setCurrentPage(page);
    setStart(pageStart);
  };

  return (
    <>
      <StyledTableMainHeader activeState>
        <StyledSessionNameDiv activeState>
          <h1>
            <span
              onKeyDown={() =>
                props.handleSessionClicked &&
                props.handleSessionClicked(props.sessionName)
              }
              onClick={() =>
                props.handleSessionClicked &&
                props.handleSessionClicked(props.sessionName)
              }
              style={{
                fontWeight: props.isActive ? 700 : "normal",
                color: props.isActive ? "#27272E" : theme.mutedColor,
              }}
            >
              {props.sessionName}
            </span>
          </h1>

          <h1>
            <span
              onKeyDown={() =>
                props.handleSessionClicked &&
                props.handleSessionClicked(props.SecondSessionName)
              }
              onClick={() =>
                props.handleSessionClicked &&
                props.handleSessionClicked(props.SecondSessionName)
              }
              style={{
                fontWeight: props.isActiveSecondary ? 700 : "normal",
                color: props.isActiveSecondary ? "#27272E" : theme.mutedColor,
              }}
            >
              {props.SecondSessionName}
            </span>
          </h1>
        </StyledSessionNameDiv>

        <StyledFilterDiv>
          {props.showDateFilter && (
            <DateInput
              value={startDate}
              placeholder={startOfYear}
              format={dateFormat}
              onChange={(e: any) => {
                setStartDate(e);
                if (e) {
                  filterByDate(e, endDate);
                } else {
                  filterData(searchText, startDate, endDate, props.data);
                }
              }}
            />
          )}{" "}
          {props.showDateFilter && <span className="dateDivider">:</span>}
          {props.showDateFilter && (
            <DateInput
              value={endDate}
              placeholder={endOfYear}
              format={dateFormat}
              onChange={(e: any) => {
                setEndDate(e);
                if (e) {
                  filterByDate(startDate, e);
                } else {
                  filterData(searchText, startDate, endDate, props.data);
                }
              }}
            />
          )}
          {props.showExportButton && (
            <StyledExport>
              <CSVLink
                data={currentData}
                headers={props.csvHeaders!}
                filename="data.csv"
              >
                <FcExport size={15} />
                Export CSV
              </CSVLink>
            </StyledExport>
          )}{" "}
        </StyledFilterDiv>
      </StyledTableMainHeader>
      <StyledContainer>
        <StyledHeader>
          {props.showSearch && (
            <StyledSearchInput>
              <StyledInput
                placeholder="Search..."
                prefix={<StyledFiSearch />}
                bordered={false}
                onChange={(e: any) => {
                  const text = e.target.value;
                  setSearchText(text);
                  if (text) {
                    filterByText(text, startDate, endDate, props.data);
                  } else {
                    filterData("", startDate, endDate, props.data);
                  }
                }}
              />
            </StyledSearchInput>
          )}
        </StyledHeader>
        <StyledTable>
          <StyledTableHead>
            <tr>{tableTitle}</tr>
          </StyledTableHead>
          <StyledTableBody>{tableRows}</StyledTableBody>
        </StyledTable>
        <StyledTableFooter>
          <p>{`Showing ${start} - ${currentPageSize} items out  of ${currentData.length} result found`}</p>
          <StyledPagination
            onChange={handlePaginationChange}
            pageSize={pageSize}
            current={currentPage}
            defaultCurrent={1}
            total={currentData.length}
            showSizeChanger={false}
            size="small"
          />
        </StyledTableFooter>
      </StyledContainer>
    </>
  );
};
