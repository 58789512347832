import React from "react";
import styled from "styled-components";
import { device } from "../../../assets/breakbpoints";
import { fontFamily } from "../../../assets/fontFamily";


export const StyledStatContainer = styled.div`
width: 65%;
display: flex;
justify-content: space-between;
margin-bottom: 20px;
& > div {
  flex-basis: 48.5%;
}

& > div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
`;

export const StyledStatTablet = styled.div`
width: 100%;
margin: 0 auto;
display: flex;
cursor: pointer

& > div:first-child {
  display: grid;
  place-items: center;
  height: 60px;
  width: 20px;
  border-radius: 8.4762px;

  & > div {
    width: 40%;

    & img {
      width: 100%;
      height: auto;
    }
  }
}

& > div:nth-child(2) {
  font-family: ${fontFamily.body};
  margin-left: 30px;
  & p {
    font-size: 11px;
    color: #062942;

    & a {
        color: #0075DD;
        cursor: pointer;
        font-weight: 20;
      }
  }

  & h1 {
    font-weight: 20;
    font-size: 15px;
    color: #062942
  }

}

@media ${device.laptop} {
    width: 100%;
  & > div:first-child {
    height: 6vw;
    width: 2vw;
  }

  & > div:nth-child(2) {      

    & p {
      font-size: 12px;
      color: #062942;
    }

    & h1 {
      font-weight: 200;
      font-size: 16px;
      color: #062942
    }
  }
}
`;

export const StyledCard = styled.div`
box-shadow: 0px 0px 1.73853px rgba(12, 26, 75, 0.24),
  0px 5.2156px 13.9083px -1.73853px rgba(50, 50, 71, 0.05);
border-radius: 5px;
background: #ffffff;
width: 100%;
padding-top: 20px;
margin-bottom: 20px;
cursor: pointer;
`;

export const StyledTileCard = (props: {
title: string;
icon: any;
description: string;
tabIndex: number;
onClick: any;
}) => (
<StyledStatTablet
>
  <div>
    <div>{props.icon}</div>
  </div>
  <div>
    <h1>{props.title}</h1>
    <p>{props.description} {" "}<a role="button"
  tabIndex={props.tabIndex}
  onClick={props.onClick}
  onKeyDown={props.onClick}> Connect</a></p>
    
  </div>
  
</StyledStatTablet>
);