import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { BenefitsInformation } from "medcon_types/ChangeHealthCare";
import React, { useState } from "react";
import Highlighter from "react-highlight-words";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

interface BenefitInformationColumn extends ColumnType<BenefitsInformation> {
  dataIndex: keyof BenefitsInformation | Array<keyof BenefitsInformation>;
}

enum InOutPlanNetworkOptions {
  N = "No (Out of Plan Network)",
  U = "",
  W = "Not Applicable",
  Y = "Yes (In Plan Network)",
}

type AdditionalInformation = BenefitsInformation["additionalInformation"];
type ServiceTypes = BenefitsInformation["serviceTypes"];
type BenefitAmount = BenefitsInformation["benefitAmount"];

function BenefitsInformationTable({
  benefits,
}: {
  benefits: BenefitsInformation[];
}) {
  let searchInput;
  const [tableSearchOptions, setTableSearchOptions] = useState<{
    searchText?: string;
    searchedColumn?: string;
  }>({
    searchText: "",
    searchedColumn: "",
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setTableSearchOptions({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setTableSearchOptions({ searchText: "" });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      tableSearchOptions.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[tableSearchOptions.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: BenefitInformationColumn[] = [
    {
      title: "Service types",
      dataIndex: "serviceTypes",
      key: "serviceTypes",
      render: (value: ServiceTypes, record, idx) => <p key={`svc-type-${record.name}-${idx}`}>{(value || []).join(", ")}</p>,
      ...getColumnSearchProps("serviceTypes"),
      onFilter: (value: string, record) =>
        record.serviceTypes.some((serviceType) =>
          serviceType.toLowerCase().includes(value)
        ),
    },
    {
      title: "Benefit Amount",
      dataIndex: "benefitAmount",
      key: "benefitAmount",
      render: (value: BenefitAmount, record, idx) => (
        <p key={`bens-${record.name}-${idx}`}>{value ? formatter.format(parseFloat(value)) : ''}</p>
      ),
    },
    {
      title: "In or Out of plan network",
      dataIndex: "inPlanNetworkIndicatorCode",
      key: "inPlanNetworkIndicatorCode",
      render: (value, record, idx) => (
        <p key={`plan-ntwrk-${record.name}-${idx}`}>{InOutPlanNetworkOptions[value] || record.inPlanNetworkIndicator}</p>
      ),
    },
    {
      title: "Coverage Level",
      dataIndex: "coverageLevel",
      key: "coverageLevel",
    },
    {
      title: "Additional Information",
      dataIndex: "additionalInformation",
      key: "additionalInformation",
      render: (value: AdditionalInformation, record, idx) => (
        <p key={`add-info-${record.name}-${idx}`}>
          {(value || []).reduce(
            (prev, curr) => `${prev}, ${curr.description || ""}`,
            ""
          )}
        </p>
      ),
    },
  ];
  return <Table dataSource={benefits} columns={columns} />;
}

export default BenefitsInformationTable;
