import React from "react";
import styled from "styled-components";
import { device } from "../../assets/breakbpoints";
import bgImg from "../../assets/images/bgImg.png";
import { Logo } from "../../assets/svgs/Logo";

const StyledOnBoardingScaffold = styled.main`
  display: flex;
  background-color: #fff;
  width: 100vw;
`;

const StyledLeftContainer = styled.section`
  width: 100%;
  min-height: 100vh;
  padding-bottom: 30px;

  @media ${device.laptop} {
    min-width: 62.5%;
  }
`;

const StyledRightContainer = styled.section`
  display: none;
  background-image: url(${bgImg});
  background-size: cover;
  min-height: 100vh;

  @media ${device.laptop} {
    display: block;
    min-width: 37.5%;
  }
`;

const StyledCategoryButtonSection = styled.section`
  max-height: 70%;
  padding-top: 30px;
  width: 90%;
  margin: 0 auto;

  @media ${device.laptop} {
    width: 85%;
    margin: 0;
    margin-left: auto;
  }

  @media ${device.desktopL} {
    padding-top: 100px;
  }
`;

const StyledTopDiv = styled.section`
  min-height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  width: 85%;
  margin: 0 auto;

  @media ${device.laptop} {
    margin: 0;
    margin-left: auto;
  }
`;

const StyledImageDiv = styled.section`
  & img {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

interface OnBoardingScaffoldProps {
  topItem?: JSX.Element;
  sideImage?: string;
  children?: any;
}

export const OnBoardingScaffold = (props: OnBoardingScaffoldProps) => (
  <StyledOnBoardingScaffold>
    <StyledLeftContainer>
      <StyledTopDiv>
        <Logo />
        {props.topItem}
      </StyledTopDiv>

      <StyledCategoryButtonSection>
        {props.children}
      </StyledCategoryButtonSection>
    </StyledLeftContainer>
    <StyledRightContainer>
      <StyledTopDiv />
      <StyledImageDiv>
        <img src={props.sideImage} alt="" />
      </StyledImageDiv>
    </StyledRightContainer>
  </StyledOnBoardingScaffold>
);
